import gsap from "gsap";
import { useEffect, useRef, useState } from "react";
import { Audio, Intro } from "../";
import iconReset from "../../assets/icon-reset.svg";
import iconYuan from "../../assets/icon-yuan.svg";
import { useEvents } from "../../context/Events";
import { useSound } from "../../context/Sound";
import { useTimeline } from "../../context/Timeline";
import { useTree } from "../../context/Tree";
import sound from "../audio/data/bgm";
import { INITIAL_STEP } from "../treeScene/data/constants";

const Ui = () => {
  // const [complete, setComplete] = useState<boolean>(false);
  const { step } = useEvents();
  const { skip, isHaloComplete } = useTimeline();
  const { muted } = useSound();
  const { updateShouldResetCamera } = useTree();
  const bottomRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isHaloComplete || skip) {
      if (bottomRef.current) {
        gsap.to(bottomRef.current, {
          opacity: step > INITIAL_STEP ? 0 : 1,
          duration: 0.8,
          delay: 0.8,
        });
      }
    }
  }, [isHaloComplete, skip, step, bottomRef.current]);

  return (
    <>
      <Intro />
      {(isHaloComplete || skip) && step === INITIAL_STEP && (
        <div
          className="absolute w-full h-0 bottom-0 left-0 z-[101] text-white overflow-visible opacity-0"
          ref={bottomRef}
        >
          <img
            src={iconYuan}
            className="absolute block w-[85px] left-[16.667vw] bottom-[6.254vh]"
          />
          <img
            src={iconReset}
            onClick={() => {
              !muted && sound.play("sfxclick");
              updateShouldResetCamera(true);
            }}
            className="absolute block w-[18px] right-[6.667vw] bottom-[6.254vh]"
          />
        </div>
      )}
      <Audio
        fadeOut={isHaloComplete}
        skip={skip}
      />
      <div className="orientation-hint absolute top-0 left-0 h-full w-full flex items-center justify-center z-[9999999] bg-black text-white">
        请竖屏体验
      </div>
    </>
  );
};

export default Ui;
