import gsap from "gsap";
import { CustomEase } from "gsap/CustomEase";

gsap.registerPlugin(CustomEase);

export const checkpoints = [
  // 0
  {
    position: [1.0404080859880827, -0.23829011179847548, -1.1770449805081342],
    target: [-0.077829776259243, -1.2625036349535999, 0.05207962479117825],
    duration: 0,
  },
  //3 旋转停留～7.6秒
  {
    position: [0.9585104413554464, -0.05324974182877085, -1.059377349449141],
    target: [0.1310497893120136, 0.6351578170464072, -0.16856261503315623],
    duration: 7.6,
    ease: CustomEase.create(
      "custom",
      "M0,0 C0.173,0 0.213,0.052 0.262,0.121 0.297,0.171 0.371,0.393 0.422,0.534 0.463,0.65 0.513,0.777 0.622,0.89 0.733,1.005 0.869,1 1,1 ",
    ),
  },
  // 稍微缩小 9.5～10
  {
    isEnd: true,
    position: [1.0512047450682525, -0.6652315584567703, -5.573596303648056],
    target: [0.0014206674376863204, 0.20022677742432468, -0.025254592473886475],
    duration: 2.42,
    ease: CustomEase.create(
      "custom",
      "M0,0 C0.059,0.023 0.042,-0.005 0.117,0.072 0.173,0.132 0.175,0.297 0.236,0.485 0.298,0.679 0.328,0.746 0.385,0.819 0.501,0.969 0.734,1 1,1 ",
    ),
  },
  // 稍微放大 跟随光晕 10～10.5
  {
    position: [0.7809764900449061, -0.6691550000000004, -5.238910078887061],
    target: [0.0014206674376863204, 0.20022677742432468, -0.025254592473886475],
    duration: 1.3,
    ease: "power3.out",
  },
  // 旋转 idle ~16
  {
    position: [0.297501144800101, -0.6691550000000003, -5.28854737601514],
    target: [0.0014206674376863204, 0.20022677742432468, -0.025254592473886475],
    duration: 3,
    ease: "none",
  },
];

// export const checkpoints = [
//   // 0
//   {
//     position: [0.5369677880822644, 0.3745595925812848, -0.8202478683762502],
//     target: [0.4271397011878465, -0.3775931355167339, -0.6197353319713658],
//     duration: 0,
//   },
//   //1 后退旋转一点～2秒
//   {
//     position: [0.36011745644752297, 0.06446153941050575, -0.82796486166268],
//     target: [0.245816515289851, -0.30316568059304916, -0.5669968827381994],
//     duration: 2.5,
//     ease: "power1.inOut",
//   },
//   //2 仰视瞄准树冠～?秒
//   {
//     position: [0.47792024607037037, -0.06750302313240109, -1.0758581847868673],
//     target: [0.03804998434825573, 0.379912126007824, -0.09918633218265263],
//     duration: 2.7,
//     ease: "power3.inOut",
//   },
//   //3 旋转停留～7.5秒
//   {
//     position: [0.6152816119355362, -0.05187169072046838, -1.0094371065220606],
//     target: [0.039115090933667405, 0.39737290942780595, -0.1073479226548444],
//     duration: 2.5,
//     ease: "power1.out",
//   },
//   //4 缩小 ~9 //End
//   {
//     position: [1.3051351619293512, -0.6998999999999995, -5.38462805490389],
//     target: [0.0014206674376863204, 0.20022677742432468, -0.025254592473886475],
//     duration: 0.7,
//     ease: "power3.inOut",
//   },
//   // 稍微缩小 9.5～10
//   {
//     isEnd: true,
//     position: [1.0512047450682525, -0.6652315584567703, -5.573596303648056],
//     target: [0.0014206674376863204, 0.20022677742432468, -0.025254592473886475],
//     duration: 1.5,
//     ease: "power3.in",
//   },
//   // 稍微放大 跟随光晕 10～10.5
//   {
//     position: [0.7809764900449061, -0.6691550000000004, -5.238910078887061],
//     target: [0.0014206674376863204, 0.20022677742432468, -0.025254592473886475],
//     duration: 1.3,
//     ease: "power1.out",
//   },
//   // 旋转 idle ~16
//   {
//     position: [0.297501144800101, -0.6691550000000003, -5.28854737601514],
//     target: [0.0014206674376863204, 0.20022677742432468, -0.025254592473886475],
//     duration: 5,
//     ease: "power3.Out",
//   },
// ];
