import * as THREE from "three";
import { type RawEvent } from "../../../types/event";
import { positionMockups } from "../data/highlights";
import gsap from "gsap";

export const sigmoid = (
  progress: number,
  steepness: number = 3,
  midpoint: number = 0.5,
): number => {
  return 1 / (1 + Math.exp(-steepness * (progress - midpoint)));
};

export const Y_MAX = 3;
const Y_MIN = 0;

export const interpolateY = (progress: number): number => {
  return Y_MIN + (Y_MAX - Y_MIN) * progress;
};

export const hexToVec3 = (hex: number): THREE.Vector3 => {
  const color = new THREE.Color(hex);
  return new THREE.Vector3(color.r, color.g, color.b);
};

export const hexToNumAry = (hex: number): number[] => {
  const color = new THREE.Color(hex);
  return [color.r, color.g, color.b];
};

export const easeInCubic = (t: number) => {
  return t * t * t;
};

export const easeOutCubic = (t: number) => {
  return 1 - Math.pow(1 - t, 3);
};

export const easeInOutCubic = (t: number): number => {
  return t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2;
};

export const easeOutExpo = (t: number) => {
  return t === 1 ? 1 : 1 - Math.pow(2, -10 * t);
};

export const easeOutQuart = (t: number) => {
  return 1 - Math.pow(1 - t, 4);
};

// export type TargetHighlight = {
//   id: string;
//   position: THREE.Vector3;
//   size: number;
//   color: string;
//   // mesh: THREE.InstancedMesh;
// };

export type TargetMesh = {
  id: string;
  mesh: THREE.Mesh;
};

export const getCameraDistance = (camera: THREE.PerspectiveCamera): number => {
  const cameraPosition = new THREE.Vector3(
    camera.position.x,
    0,
    camera.position.z,
  );
  const yAxis = new THREE.Vector3(0, 1, 0);
  const dotProduct = cameraPosition.dot(yAxis);
  return Math.sqrt(cameraPosition.x ** 2 + cameraPosition.z ** 2);
};

export const calculateSquarePosition = (
  vp: THREE.Vector3,
  centerP: THREE.Vector3,
  distance: number,
) => {
  const vx = vp.x;
  const vy = vp.y;
  const vz = vp.z;

  const cx = centerP.x;
  const cy = centerP.y;
  const cz = centerP.z;

  // Step 1: Calculate direction vector
  const direction = [vx - cx, vy - cy, vz - cz];

  // Step 2: Normalize the direction vector
  const magnitude: number = Math.sqrt(
    direction[0] ** 2 + direction[1] ** 2 + direction[2] ** 2,
  );
  const unitDirection = direction.map((d) => d / magnitude);

  // Step 3: Calculate new vertex
  const newVertex = [
    vx + distance * unitDirection[0],
    vy + distance * unitDirection[1],
    vz + distance * unitDirection[2],
  ];

  return newVertex;
};

export const getRandomItems = (
  array: any[],
  num: number,
  targetArray: any[],
) => {
  if (num > array.length) {
    throw new Error("Number of items requested exceeds array size.");
  }

  let result: any[] = [];
  let taken = new Set();
  while (result.length < num) {
    const randomIndex = Math.floor(Math.random() * array.length);
    if (!taken.has(randomIndex)) {
      result.push(array[randomIndex]);
      taken.add(randomIndex);
    }
  }

  return targetArray.map((updatedItem: any, index: number) => {
    const selectedItem = result[index];
    return {
      ...updatedItem, // Keep all properties from updatedItem (x, y, z, size, etc.)
      id: selectedItem?.id, // Replace 'id' from selectedItem
      title: selectedItem?.title, // Replace 'title' from selectedItem
    };
  });
};

export const insertRandomPosition = (targetArray: RawEvent[][]) => {
  return positionMockups.map((updatedLevels: any, index: number) => {
    return updatedLevels.map((updatedItem: any, i: number) => {
      return {
        ...updatedItem,
        ...targetArray?.[index]?.[i],
        position: {
          x: updatedItem.x,
          y: updatedItem.y,
          z: updatedItem.z,
        },
      };
    });
  });
};

export const matrixToScreen = (matrix: THREE.Matrix4, camera: THREE.Camera) => {
  // Extract the camera's rotation matrix
  const cameraRotationMatrix = new THREE.Matrix4().extractRotation(
    camera.matrixWorld,
  );

  // Use only the camera's rotation around the Z-axis to keep particles parallel to the screen
  const rotationMatrix = new THREE.Matrix4().makeRotationFromQuaternion(
    camera.quaternion,
  );

  /*
  | 0x + 1y + 2z + 3w
  | 4x + 5y + 6z + 7w
  | 8x + 9y + 10z + lw
  | mx + ny + oz + pw
  */

  let indexes = [0, 4, 1, 5];

  // Y
  rotationMatrix.elements[indexes[0]] = cameraRotationMatrix.elements[0];
  rotationMatrix.elements[indexes[1]] = cameraRotationMatrix.elements[4];

  // Z
  rotationMatrix.elements[indexes[2]] = cameraRotationMatrix.elements[1];
  rotationMatrix.elements[indexes[3]] = cameraRotationMatrix.elements[5];

  matrix.multiply(rotationMatrix);
  return matrix;
};

const random = (min: number, max: number) => {
  const delta = max - min;
  return (direction = 1) => (min + delta * Math.random()) * direction;
};

const randomX = random(1, 15);
const randomY = random(1, 15);
//const randomDelay = random(0, 1);
const randomTime = random(3, 5);

export const randomMovement = (
  target: HTMLElement,
  directionX: number,
  directionY: number,
) => {
  gsap.to(target, randomTime(), {
    x: randomX(directionX),
    y: randomY(directionY),
    ease: "sine.inOut",
    onComplete: randomMovement,
    //delay: randomDelay(),
    onCompleteParams: [target, directionX * -1, directionY * -1],
  });
};
