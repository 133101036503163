import { useFrame, useThree } from "@react-three/fiber";
import { useEffect, useMemo, useState } from "react";
import * as THREE from "three";
import { useEvents } from "../../context/Events";
import { useSound } from "../../context/Sound";
import { useTimeline } from "../../context/Timeline";
import { useTree } from "../../context/Tree";
import { type RawEvent } from "../../types/event";
// import sound from "./../audio/data/bgm";
import CameraCtrl from "./CameraCtrl";
import {
  CAMERA_DISTANCE_LEVELS,
  HIGHLIGHT_GROUP_SCALE,
  HIGHLIGHT_Y_OFFSET,
  INITIAL_STEP,
} from "./data/constants";
import { highlightData } from "./data/highlights";
import { EventDetails } from "./EventDetails";
import { getCameraDistance, insertRandomPosition } from "./helpers/utils";
import Highlights from "./Highlights";

export const Interaction = ({ eventList }: { eventList: RawEvent[][] }) => {
  const { muted } = useSound();
  const { step, targetEvent } = useEvents();
  const { introEnded } = useTimeline();
  const { toggleDetail, isDetailShow } = useTree();

  const [activeLevel, setActiveLevel] = useState<number>(0);

  const hls = insertRandomPosition(eventList);
  const highlights = useMemo(() => highlightData(hls), [eventList]);

  const camera = useThree().camera as THREE.PerspectiveCamera;

  useEffect(() => {
    if (step > INITIAL_STEP) {
      toggleDetail(true);
    }
  }, [step]);

  useFrame(() => {
    if (introEnded) {
      const cameraDistance = getCameraDistance(camera);
      let testLevel = 0;
      for (let i = 0; i < CAMERA_DISTANCE_LEVELS.length; i++) {
        if (cameraDistance < CAMERA_DISTANCE_LEVELS[i]) {
          testLevel = i;
        }
      }
      if (testLevel !== activeLevel && !isDetailShow) {
        setActiveLevel(testLevel);
      }
    }
  });

  return (
    <>
      <CameraCtrl />
      <group
        scale={[
          HIGHLIGHT_GROUP_SCALE,
          HIGHLIGHT_GROUP_SCALE,
          HIGHLIGHT_GROUP_SCALE,
        ]}
        position={[0, HIGHLIGHT_Y_OFFSET, 0]}
      >
        <Highlights
          highlights={highlights}
          activeLevel={activeLevel}
        />
      </group>
      {isDetailShow && targetEvent && (
        <EventDetails activeLevel={activeLevel} />
      )}
    </>
  );
};
