const easters = {
  // "c2ebecd2-520b-42ce-939c-31aebfe12b03": { //N.阿佩尔成功研究出可以长期贮存的玻璃瓶装食品
  //   animation: "apple",
  //   duration: 1.68,
  // },
  // "17a73f7f-915e-4ed6-921a-35212ac2ade5": { //詹森父子发明第一台显微镜
  //   animation: "airplane",
  //   duration: 2.6,
  // },
  // "6f4acf52-16c8-4463-9448-8d54be12efe5": { //威廉·库克和查尔斯·惠斯通发明电报
  //   animation: "crown",
  //   duration: 2.24,
  // },
  // "a39c6309-f994-49cf-af69-6cd1b592b42e": { //化学家合成对氨基苯磺酰胺
  //   animation: "kite",
  //   duration: 2.8,
  // },
  "bd7a4ac8-e151-40eb-a4cc-d251216466aa": {
    animation: "airplane",
    duration: 2,
  },
  "e0f20ecc-cd67-4424-8e34-ad642c79d3b3": {
    animation: "airplane",
    duration: 2,
  },
  "f93b417f-d01a-46a3-b9ed-0c1e41db05fb": {
    animation: "airplane",
    duration: 2,
  },
  "e66f8a15-0c81-41bb-a585-cdb9848dde22": {
    animation: "apple",
    duration: 1.68,
  },
  "9d3126a4-567e-4e25-af4c-006c8fc9e36e": {
    animation: "apple",
    duration: 1.68,
  },
  "98746896-46fd-40da-9b07-7dbc1c6e4187": {
    animation: "apple",
    duration: 1.68,
  },
  "f299923c-c6d1-4909-9156-d5d5d65b91f4": {
    animation: "apple",
    duration: 1.68,
  },
  "2218adac-e21c-4fa0-bbcb-234738ad868d": {
    animation: "crown",
    duration: 2.26,
  },
  "fadc6b82-5909-44a3-b449-bba2ce0286e7": {
    animation: "crown",
    duration: 2.26,
  },
  "8c5f6cc5-6e7c-4287-b306-3bc5eefd8abf": {
    animation: "crown",
    duration: 2.26,
  },
  "5c41408b-fc9c-4946-b46e-38ccdfb98158": {
    animation: "kite",
    duration: 2.82,
  },
  "5f1bb2a4-f3d5-4c4d-905a-0bb1dd8f06c8": {
    animation: "kite",
    duration: 2.82,
  },
  "e1e1468d-b886-468f-b465-edd322fe1966": {
    animation: "kite",
    duration: 2.82,
  },
};

export default easters;
