import gsap from "gsap";
import { ReactNode, useEffect, useRef, useState } from "react";
import ScrambleText from "scramble-text";
import { cn, en } from "./data/char";

const Text = ({
  scramb,
  staggerTime = 0.05,
  startDelay = 0,
  fade = true,
  start = true,
  children,
}: {
  scramb?: boolean;
  staggerTime?: number;
  startDelay?: number;
  fade?: boolean;
  start?: boolean;
  children: ReactNode;
}) => {
  const wrapper = useRef<HTMLDivElement>(null);
  const [texts, setTexts] = useState<Array<string>>(
    (children as string)?.split("") || [],
  );
  let duration = 0.3;
  if (!fade) {
    duration = 0.01;
  }

  useEffect(() => {
    if (children && (children as string).length > 0) {
      setTexts((children as string)?.split(""));
    }
  }, [children]);

  useEffect(() => {
    const tl = gsap.timeline({
      delay: startDelay,
      paused: !start ? true : false,
    });
    if (wrapper.current) {
      const targets = gsap.utils.toArray(
        wrapper.current.querySelectorAll("span"),
      );
      tl.fromTo(
        targets,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: duration,
          delay: startDelay,
          stagger: (index, target, list) => {
            const targetText = texts[index];
            if (scramb) {
              const scrambleText = new ScrambleText(target, {
                timeOffset: duration * 1000,
                chars: /[a-zA-Z0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(targetText)
                  ? en.split("")
                  : cn.split(""),
                callback: () => {
                  target.innerHTML = targetText;
                },
              }).play();
              setTimeout(
                () => {
                  scrambleText.start();
                },
                staggerTime * 1000 * index,
              );
            }
            return index * staggerTime;
          },
          onComplete: () => {
            gsap.set(targets, { clearProps: "all" });
          },
        },
      );
      if (start) {
        tl.play();
      }
    }
    return () => {
      tl.kill();
    };
  }, [wrapper.current, startDelay, start]);

  return texts.length > 0 ? (
    <span ref={wrapper}>
      {texts.map((s, i) => (
        <span
          key={i}
          style={{ opacity: 0 }}
        >
          {s}
        </span>
      ))}
    </span>
  ) : null;
};

export default Text;
