import { useEffect, useState } from "react";
import dissolve from "../assets/dissolve.webp";
import dotBlue from "../assets/dot-blue.png";
import dotGreen from "../assets/dot-green.png";
import dotPurple from "../assets/dot-purple.png";
import dotYellow from "../assets/dot-yellow.png";
import lockGray from "../assets/lock-gray.gif";
import lock from "../assets/lock.gif";
// import music from "../assets/music.mp3";
import posterBg4 from "../assets/poster-bg-4.jpg";
import posterBg5 from "../assets/poster-bg-5.jpg";
import posterBg6 from "../assets/poster-bg-6.jpg";
import qr from "../assets/qr.png";
import tutorial1 from "../assets/tutorial-1.webp";
import tutorial2 from "../assets/tutorial-2.webp";
import tutorial3 from "../assets/tutorial-3.webp";
import airplane from "../assets/airplane.png";
import apple from "../assets/apple.png";
import crown from "../assets/crown.png";
import kite from "../assets/kite.png";

export const imageFiles = [
  dotBlue,
  dotGreen,
  dotPurple,
  dotYellow,
  dissolve,
  tutorial1,
  tutorial2,
  tutorial3,
  posterBg4,
  posterBg5,
  posterBg6,
  lock,
  lockGray,
  qr,
  airplane,
  apple,
  crown,
  kite,
];

export const glbFiles = [
  "/gltf/leaves-inner.glb",
  "/gltf/leaves-outer-m-1.glb",
  "/gltf/leaves-outer-m-2.glb",
  "/gltf/leaves-outer-s-1.glb",
  "/gltf/leaves-outer-s-2.glb",
  "/gltf/leaves-outer.glb",
  "/gltf/trunk-1.glb",
  "/gltf/trunk-2.glb",
  "/gltf/trunk-3.glb",
  "/textures/highlight-bloom-blue.png",
  "/textures/highlight-bloom-green.png",
  "/textures/highlight-bloom-purple.png",
  "/textures/highlight-bloom-yellow.png",
];

// export const soundFiles = [music];

const allFiles = [...glbFiles, ...imageFiles];

export const usePreload = () => {
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const totalFiles = allFiles.length;
    let loadedFiles = 0;

    const preloadFile = (file: string) => {
      return new Promise((resolve) => {
        if (file.endsWith(".glb")) {
          // Preload GLB files
          fetch(file)
            .then(() => {
              loadedFiles++;
              setProgress(Math.round((loadedFiles / totalFiles) * 100));
              resolve(undefined);
            })
            .catch(() => {
              loadedFiles++;
              setProgress(Math.round((loadedFiles / totalFiles) * 100));
              resolve(undefined);
            });
        } else if (file.endsWith(".mp3")) {
          // Preload MP3 files
          const audio = new Audio(file);
          audio.oncanplaythrough = () => {
            loadedFiles++;
            setProgress(Math.round((loadedFiles / totalFiles) * 100));
            resolve(undefined);
          };
          audio.onerror = () => {
            loadedFiles++;
            setProgress(Math.round((loadedFiles / totalFiles) * 100));
            resolve(undefined);
          };
          audio.load();
        } else {
          // Preload Image files
          const img = new Image();
          img.src = file;

          img.onload = () => {
            loadedFiles++;
            setProgress(Math.round((loadedFiles / totalFiles) * 100));
            resolve(undefined);
          };

          img.onerror = () => {
            loadedFiles++;
            setProgress(Math.round((loadedFiles / totalFiles) * 100));
            resolve(undefined);
          };
        }
      });
    };

    const preloadAllFiles = async () => {
      await Promise.all(allFiles.map(preloadFile));
      setLoading(false);
    };

    preloadAllFiles();
  }, []);

  return { progress, loading };
};
