import gsap from "gsap";
import { useEffect, useRef, useState } from "react";
import { Ripple } from "..";
import { randomMovement } from "../treeScene/helpers/utils";

type Percentage = {
  val: number;
};

const Loading = ({ progress }: { progress: number }) => {
  // const Loading = () => {
  const [percentage, setPercentage] = useState<number>(0);
  const loadingRef = useRef<HTMLDivElement>(null);

  //const timeline = gsap.timeline()

  useEffect(() => {
    if (loadingRef.current) {
      // const per: Percentage = { val: percentage };
      const per: Percentage = { val: progress };
      const floatings = gsap.utils.toArray(
        loadingRef.current?.querySelectorAll(".floating"),
      );
      gsap.from(floatings, { opacity: 0, duration: 0.6 });
      floatings.forEach((floating) => {
        randomMovement(
          floating as HTMLElement,
          Math.round(Math.random()) ? 1 : -1,
          Math.round(Math.random()) ? 1 : -1,
        );
      });
      //to be removed
      const tmptl = gsap.timeline({
        onUpdate: () => {
          // setPercentage(+Math.round(per.val));
          setPercentage(progress);
        },
      });
      tmptl.to(per, {
        // val: 100,
        val: progress,
        duration: 3,
        ease: "none",
      });
    }
    return () => {
      if (loadingRef.current) {
        const floatings = gsap.utils.toArray(
          loadingRef.current?.querySelectorAll(".floating"),
        );
        floatings.forEach((floating) => {
          gsap.killTweensOf(floating as HTMLElement, "opacity,x,y");
        });
      }
    };
  }, [progress]);
  // }, []);

  return (
    <div
      className="relative w-full h-full bg-[#140D16]"
      ref={loadingRef}
    >
      <div className="floating w-[8px] h-[8px] left-[23.07vw] top-[41.23%] opacity-80 bg-[#5F558A] rounded-full absolute -translate-x-1/2 -translate-y-1/2"></div>
      <div className="floating w-[14px] h-[14px] right-[16.667vw] top-[48.99%] opacity-70 bg-[#5F558A] rounded-full absolute translate-x-1/2 -translate-y-1/2"></div>
      <div className="floating w-[8px] h-[8px] right-[30.51vw] top-[28.51%] opacity-80 bg-[#5F558A] rounded-full absolute translate-x-1/2 -translate-y-1/2"></div>
      <div className="floating w-[14px] h-[14px] right-[38.2vw] bottom-[33.6%] opacity-80 bg-[#3E305D] rounded-full absolute translate-x-1/2 translate-y-1/2"></div>
      <div className="floating w-[14px] h-[14px] left-[33.333vw] top-[23.43%] opacity-80 bg-[#3E305D] rounded-full absolute -translate-x-1/2 -translate-y-1/2"></div>
      <div className="floating w-[8px] h-[8px] left-[9.743vw] bottom-[46.85%] opacity-70 bg-[#3E305D] rounded-full absolute -translate-x-1/2 translate-y-1/2"></div>
      <div className="absolute size-[106px] left-1/2 top-[43.5%] -translate-x-1/2 -translate-y-1/2">
        <div className="absolute size-[108px] rounded-full left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 blur-[1px] border-[2px] border-[rgba(255,255,255,0.3)]" />
        <Ripple>
          <svg
            className="absolute w-[120px] h-[120px] -rotate-90 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
            viewBox="0 0 36 36"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="18"
              cy="18"
              r="16"
              fill="none"
              className="stroke-current text-white"
              strokeWidth="0.8"
              strokeDasharray="100"
              strokeDashoffset={100 - percentage}
              strokeLinecap="round"
            ></circle>
          </svg>
        </Ripple>
        <div className="absolute w-full text-center top-[calc(43.5%+4px)] start-1/2 transform -translate-y-1/2 -translate-x-1/2">
          <span className="text-[13.5px] font-bold text-white">
            {percentage >= 100 ? "100%" : percentage + "%"}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Loading;
