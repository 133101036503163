import * as THREE from "three";

export const particleMaterial = new THREE.ShaderMaterial({
  uniforms: {
    // opacity: { value: 1.0 },
    // time: { value: 0 },
    runnerY: { value: 0 },
    brightness: { value: 1 },
    uTime: { value: 0 },
  },
  vertexShader: `
    uniform float runnerY;
    uniform float brightness;
    uniform float uTime;
    attribute vec3 instanceColor;
    attribute float instanceY;
    attribute float instanceOpacity;
    varying vec3 vColor;
    varying float vOpacity;

    void main() {
      vColor = instanceColor;
      float delta = runnerY - instanceY;
      if (delta > 0.2) {
        vOpacity = instanceOpacity; // Fully visible
      } else if (delta > 0.0) {
        // Calculate opacity based on delta
        vOpacity = clamp(delta / 0.2, 0.0, 1.0) * clamp(delta / 0.2, 0.0, 1.0) * instanceOpacity; // Quadratic function
      } else {
        vOpacity = 0.0; // Fully transparent
      }
      // float timeFactor = clamp(uTime / 1.0, 0.0, 1.0); // Gradually increase over 1 second
      // vOpacity *= mix(1.0, brightness, timeFactor);
      vOpacity *= brightness;

      gl_Position = projectionMatrix * modelViewMatrix * instanceMatrix * vec4(position, 1.0);
    }
  `,
  fragmentShader: `
    varying vec3 vColor;
    varying float vOpacity;
    void main() {
      gl_FragColor = vec4(vColor, vOpacity);
    }
  `,
  transparent: true,
  depthWrite: false,
  depthTest: true,
});

export const highlightSphereMaterial = (hex: string, level: number) =>
  new THREE.ShaderMaterial({
    uniforms: {
      uColor: { value: new THREE.Color(hex) },
      uPlaneNormal: { value: new THREE.Vector3() }, // Add plane normal uniform
      uCameraPosition: { value: new THREE.Vector3() }, // Add camera position uniform
      uMaxOpacity: { value: 1 },
      uLevel: { value: level },
    },
    vertexShader: `
      varying vec3 vPosition;
      varying float vOpacity;
      varying vec3 vWorldPosition;
      uniform vec3 uCameraPosition; // camera pos uniform
      uniform vec3 uPlaneNormal; // plane normal uniform
      uniform float uMaxOpacity;

      void main() {
        vPosition = position;
        vOpacity = uMaxOpacity;
        gl_Position = projectionMatrix * modelViewMatrix * instanceMatrix * vec4(position, 1.0);

        vec4 worldPosition = modelMatrix * instanceMatrix * vec4(position, 1.0);
        vWorldPosition = worldPosition.xyz;
      }
    `,
    fragmentShader: `
      uniform vec3 uColor;
      uniform vec3 uPlaneNormal;
      uniform vec3 uCameraPosition;
      uniform float uMaxOpacity;
      varying vec3 vPosition;
      varying float vOpacity;
      varying vec3 vWorldPosition;


      void main() {
        vec3 planeNormal = normalize(uCameraPosition);
        float distance = dot(vWorldPosition, planeNormal);
        float opacityFactor = smoothstep(0.0, .2, distance);
        gl_FragColor = vec4(uColor, uMaxOpacity * opacityFactor);
      }
    `,
    transparent: true,
    toneMapped: false,
    fog: false,
  });

export const highlightGlowMaterial = (texture: THREE.Texture, level: number) =>
  new THREE.ShaderMaterial({
    uniforms: {
      uTexture: { value: texture },
      uPlaneNormal: { value: new THREE.Vector3() }, // Add plane normal uniform
      uCameraPosition: { value: new THREE.Vector3() }, // Add camera position uniform
      uMaxOpacity: { value: 1 },
      uLevel: { value: level },
    },
    vertexShader: `
      varying vec3 vPosition;
      varying float vOpacity;
      varying vec3 vWorldPosition;
      uniform vec3 uCameraPosition; 
      uniform vec3 uPlaneNormal;
      uniform float uMaxOpacity;
      uniform int uLevel;
      varying vec2 vUv;

      void main() {
        vPosition = position;
        vOpacity = uMaxOpacity;
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * instanceMatrix * vec4(position, 1.0);

        vec4 worldPosition = modelMatrix * instanceMatrix * vec4(position, 1.0);
        vWorldPosition = worldPosition.xyz;
      }
    `,
    fragmentShader: `
      uniform sampler2D uTexture;
      varying vec2 vUv;
      uniform vec3 uPlaneNormal; // plane normal uniform
      uniform vec3 uCameraPosition; // camera pos uniform
      uniform float uMaxOpacity;
      varying vec3 vPosition;
      varying float vOpacity;
      varying vec3 vWorldPosition;

      void main() {
        vec3 planeNormal = normalize(uCameraPosition);
        float distance = dot(vWorldPosition, planeNormal);
        float opacityFactor = smoothstep(0.0, .2, distance);

        vec4 texColor = texture2D(uTexture, vUv);
        gl_FragColor = vec4(texColor.rgb, texColor.a * opacityFactor * vOpacity); 

      }
    `,
    transparent: true,
    toneMapped: false,
    fog: false,
    opacity: 1,
    side: THREE.DoubleSide,
    depthWrite: false,
  });

export const gradientLineMaterial2 = new THREE.ShaderMaterial({
  uniforms: {
    color: { value: new THREE.Color("white") },
    origin: { value: new THREE.Vector3(0, 0, 0) },
  },
  vertexShader: `
    varying vec3 vPos;
    void main() 
    {
      vPos = position;
      vec4 modelViewPosition = modelViewMatrix * vec4(position, 1.0);
      gl_Position = projectionMatrix * modelViewPosition;
    }
  `,
  fragmentShader: `
    uniform vec3 origin;
    uniform vec3 color;
  	varying vec3 vPos;
    float limitDistance = 2.0;
    void main() {
    	float distance = clamp(length(vPos - origin), 0., limitDistance);
      float opacity = 1.0 - distance / limitDistance;
      gl_FragColor = vec4(color, opacity * 0.1);
    }
  `,
  transparent: true,
});

export const animatedGradientTubeMaterial = new THREE.ShaderMaterial({
  uniforms: {
    color: { value: new THREE.Color("white") },
    uTime: { value: 0 },
  },
  vertexShader: `
    varying vec2 vUv;

    void main() {
      vUv = uv; 
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
  `,
  fragmentShader: `
    varying vec2 vUv;
    uniform float uTime;

    float random(float x) {
      return fract(sin(x * 43758.5453) * 10000.0);
    }

    float smoothRandom(float x) {
      float a = random(x);
      float b = random(x + 0.1);
      return mix(a, b, 0.5); // Interpolate between two random values
    }

    void main() {
      // Generate a smooth wave for segment sizes
      float segmentCount = 5.0; // Total number of segments
      float segmentSize = 1.0 / segmentCount; // Base size of each segment

      // Use sine for smoother variations
      float wave = sin((vUv.x * segmentCount * 3.14) - uTime * 5.0) * 0.5 + 0.5;
      float sizeVariation = 0.5 + wave * 0.5; // Random size variation controlled by sine wave

      // Adjust segment size based on sine wave
      float adjustedSegmentSize = segmentSize * sizeVariation;

      // Calculate position within the segment
      float positionInSegment = mod(vUv.x, adjustedSegmentSize) / adjustedSegmentSize;

      // Use smoothstep for transitions between white and transparent
      float alpha = smoothstep(0.2, 0.6, positionInSegment) * (1.0 - smoothstep(0.1, 0.7, positionInSegment));
      vec4 color = vec4(1.0, 1.0, 1.0, alpha + 0.1); // White with varying alpha
      gl_FragColor = color;
    }
  `,
  transparent: true,
  toneMapped: false,
  fog: false,
  opacity: 1,
  side: THREE.DoubleSide,
  depthWrite: false,
});

export const squareMaterial = (texture: THREE.Texture) =>
  new THREE.ShaderMaterial({
    uniforms: {
      uTexture: { value: texture },
    },
    vertexShader: `
      attribute float opacity;
      varying float vOpacity;
      varying vec2 vUv;


      void main() {
        vUv = uv;
        vOpacity = opacity;
        vec4 modelViewPosition = modelViewMatrix * vec4(position, 1.0);
        gl_Position = projectionMatrix * modelViewMatrix * instanceMatrix * vec4(position, 1.0);
      }
    `,
    fragmentShader: `
      varying float vOpacity;
      varying vec2 vUv;
      uniform sampler2D uTexture;

      void main() {
        vec4 texColor = texture2D(uTexture, vUv);
        gl_FragColor = vec4(texColor.rgb, texColor.a * vOpacity);
      }
    `,
    transparent: true,
    toneMapped: false,
    fog: false,
    opacity: 1,
    side: THREE.DoubleSide,
    depthWrite: false,
  });
