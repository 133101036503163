export const dotPositions = {
  "4": [
    { x: 638, y: 281, left: 280, top: 105, width: 340 },
    { x: 365, y: 515 },
    { x: 554, y: 812 },
    { x: 344, y: 1196, left: 375, top: 1005, width: 370 },
  ],
  "5": [
    { x: 723, y: 275, left: 361, top: 108, width: 340 },
    { x: 623, y: 581 },
    { x: 371, y: 521 },
    { x: 401, y: 776 },
    { x: 251, y: 1028, left: 285, top: 1044, width: 370 },
  ],
  "6": [
    { x: 410, y: 155, left: 54, top: 183, width: 340 },
    { x: 591, y: 367 },
    { x: 755, y: 554 },
    { x: 494, y: 689 },
    { x: 551, y: 911 },
    { x: 314, y: 1124, left: 336, top: 1149, width: 370 },
  ],
};

export const hookTexts = [
  {
    hook1: "“大自然的每一个领域",
    hook2: "都是美妙绝伦的。”",
    author: "- 亚里士多德",
  },
  {
    hook1: "“时间是世界上",
    hook2: "一切成就的土壤。”",
    author: "- 麦金西",
  },
  {
    hook1: "“历史是一堆灰烬，",
    hook2: "但灰烬深处有余温。”",
    author: "- 黑格尔",
  },
  {
    hook1: "“未来属于那些",
    hook2: "相信美梦之美的人。”",
    author: "- 埃莉诺·罗斯福",
  },
  {
    hook1: "“黑夜无论怎样悠长，",
    hook2: "白昼总会到来。”",
    author: "- 莎士比亚",
  },
  {
    hook1: "“地球上一切美丽的东西都来源",
    hook2: "于太阳，而一切美好的东西都来源于人。”",
    author: "- 普利什文",
    fontSize: 39,
    lineHeight: 60,
    subFontSize: 25.5
  },
]