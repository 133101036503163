import { useEffect, useRef, useState } from "react";
import { type RawEvent } from "../../types/event";
import { ENLARGE_TRANSITION } from "../layer/data/transitions";
import PointDetail from "../point-detail";
import Ripple from "../ripple";
import gsap from "gsap";
import { MappedHighlightColors } from "../treeScene/data/highlights";

const SMALL_SCALE = 0.27;

const Dot = ({
  color,
  small,
  content,
}: {
  color: string;
  small?: boolean;
  content: RawEvent;
}) => {
  const dotRef = useRef<HTMLDivElement>(null);
  const [scale, setScale] = useState<number>(SMALL_SCALE);

  useEffect(() => {
    if (dotRef.current) {
      gsap.to(
        { value: scale },
        {
          value: small ? SMALL_SCALE : 1,
          duration: 0.4,
          ease: "power4.out",
          onUpdate: function () {
            setScale(this.targets()[0].value);
          },
        },
      );
    }
  }, [small]);

  return (
    <div className="absolute top-0 left-0">
      <div
        ref={dotRef}
        className={`dot absolute w-[66px] h-[66px] left-[-33px] top-[-33px] rounded-full ${ENLARGE_TRANSITION}`}
        // className="absolute w-[18.4px] h-[18.4px] rounded-full"
        style={{
          // left: `${position.x}px`,
          // top: `${position.y}px`,
          boxShadow: `0 0 40px 5px ${color}C0`,
          backgroundColor: color,
          transform: `scale3d(${scale}, ${scale}, ${scale})`,
        }}
        // onClick={() => small && handleClick()}
      >
        {small && (
          <Ripple
            color={color}
            scaled={small}
          />
        )}
        <div className="absolute bg-white rounded-full w-[8.71px] h-[8.71px] left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"></div>
      </div>
      <PointDetail
        small={small}
        content={content}
      />
    </div>
  );
};

export default Dot;
