import { createContext, ReactNode, useContext, useState } from 'react';

type scene = "intro" | "home" | "detail" | "result";

type SoundContext = {
  scene: scene;
  setScene: Function;
  muted: boolean;
  toggleMuted: Function;
}

export const SoundContext = createContext<SoundContext>({
  scene: "intro",
  setScene: () => { },
  muted: true,
  toggleMuted: () => { }
});

export const SoundProvider = ({
  children
}: {
  children: ReactNode
}) => {
  const [muted, setMuted] = useState(true);
  const [scene, setScene] = useState<scene>("intro");

  const toggleMuted = (muted: boolean) => {
    setMuted(muted)
  };

  return (
    <SoundContext.Provider value={{ scene, muted, toggleMuted, setScene }}>
      {children}
    </SoundContext.Provider>
  )
}

export const useSound = () => {
  return useContext(SoundContext);
};