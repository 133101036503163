import * as THREE from "three";
import { GLTF } from "three-stdlib";

export type NodeNames =
  | `trunk-${1 | 2 | 3}`
  | `leaves-${"inner" | "outer"}`
  | `leaves-outer-${"m" | "s"}-${"1" | "2"}`
  | "detail-background";

export type GLTFResult = GLTF & {
  nodes: {
    [key in NodeNames]: THREE.Mesh;
  };
  materials: {};
};

const hardcodedSizeRange: [number, number] = [3, 10];

export const treeModels: {
  modelPath: string;
  nodeName: NodeNames;
  particleCount?: number;
  sizeRange?: [number, number];
}[] = [
  {
    modelPath: "/gltf/leaves-outer.glb",
    nodeName: "leaves-outer",
  },
  {
    modelPath: "/gltf/leaves-outer-s-1.glb",
    nodeName: "leaves-outer-s-1",
    sizeRange: hardcodedSizeRange,
  },
  {
    modelPath: "/gltf/leaves-outer-s-2.glb",
    nodeName: "leaves-outer-s-2",
    sizeRange: hardcodedSizeRange,
  },
  {
    modelPath: "/gltf/leaves-outer-m-1.glb",
    nodeName: "leaves-outer-m-1",
    sizeRange: hardcodedSizeRange,
  },
  {
    modelPath: "/gltf/leaves-outer-m-2.glb",
    nodeName: "leaves-outer-m-2",
    sizeRange: hardcodedSizeRange,
  },
  {
    modelPath: "/gltf/leaves-inner.glb",
    nodeName: "leaves-inner",
  },
  {
    modelPath: "/gltf/trunk-1.glb",
    nodeName: "trunk-1",
  },
  {
    modelPath: "/gltf/trunk-2.glb",
    nodeName: "trunk-2",
  },
  {
    modelPath: "/gltf/trunk-3.glb",
    nodeName: "trunk-3",
  },
  {
    modelPath: "/gltf/leaves-outer.glb",
    nodeName: "leaves-outer",
  },
];

export const defaultCounts: Record<NodeNames, number> = {
  "leaves-outer": 2800,
  "leaves-outer-s-1": 30,
  "leaves-outer-s-2": 30,
  "leaves-outer-m-1": 30,
  "leaves-outer-m-2": 30,
  "leaves-inner": 500,
  "trunk-1": 1000,
  "trunk-2": 1000,
  "trunk-3": 1000,
  "detail-background": 8000,
};

export const sizeDistribution = () => {
  const checkCachedValue = (key: string, defaultValue: number) => {
    const cachedValue = localStorage.getItem(key);
    return cachedValue ? Number(cachedValue) : defaultValue;
  };
  return {
    scaleXs: {
      value: checkCachedValue("scaleXs", 2),
      min: 1,
      max: 10,
      step: 1,
      transient: false,
      onChange: (val: number) => {
        localStorage.setItem("scaleXs", val.toString());
      },
    },
    fractionXs: {
      value: checkCachedValue("fractionXs", 0.25),
      min: 0,
      max: 1,
      step: 0.01,
      transient: false,
      onChange: (val: number) => {
        localStorage.setItem("fractionXs", val.toString());
      },
    },
    scaleS: {
      value: checkCachedValue("scaleS", 5),
      min: 1,
      max: 10,
      step: 1,
      transient: false,
      onChange: (val: number) => {
        localStorage.setItem("scaleS", val.toString());
      },
    },
    fractionS: {
      value: checkCachedValue("fractionS", 0.3),
      min: 0,
      max: 1,
      step: 0.01,
      transient: false,
      onChange: (val: number) => {
        localStorage.setItem("fractionS", val.toString());
      },
    },
    scaleM: {
      value: checkCachedValue("scaleM", 10),
      min: 1,
      max: 20,
      step: 1,
      transient: false,
      onChange: (val: number) => {
        localStorage.setItem("scaleM", val.toString());
      },
    },
    fractionM: {
      value: checkCachedValue("fractionM", 0.4),
      min: 0,
      max: 1,
      step: 0.01,
      transient: false,
      onChange: (val: number) => {
        localStorage.setItem("fractionM", val.toString());
      },
    },
    scaleL: {
      value: checkCachedValue("scaleL", 17),
      min: 10,
      max: 40,
      step: 1,
      transient: false,
      onChange: (val: number) => {
        localStorage.setItem("scaleL", val.toString());
      },
    },
    fractionL: {
      value: checkCachedValue("fractionL", 0.05),
      min: 0,
      max: 1,
      step: 0.01,
      transient: false,
      onChange: (val: number) => {
        localStorage.setItem("fractionL", val.toString());
      },
    },
  };
};
