import { chunk } from "lodash";
import { DisplayEvent } from "../../../types/event";

export enum HighlightColors {
  Blue = "#5EC2DC",
  Green = "#8EDAC8",
  Purple = "#C7A7E7",
  Yellow = "#F9F7CA",
}

export enum MappedHighlightColors {
  Blue = "#1E8AB7",
  Green = "#45B393",
  Purple = "#9263CC",
  Yellow = "#F2ED98",
}

const highlightColors = [
  HighlightColors.Blue,
  HighlightColors.Green,
  HighlightColors.Purple,
  HighlightColors.Yellow,
];

export const mappedHighlightColors = [
  MappedHighlightColors.Blue,
  MappedHighlightColors.Green,
  MappedHighlightColors.Purple,
  MappedHighlightColors.Yellow,
];

const convertBlenderToThreeJS = (blenderCoords: {
  x: number;
  y: number;
  z: number;
}): { x: number; y: number; z: number } => {
  const threejsCoords = {
    x: blenderCoords.x,
    y: blenderCoords.z, // Swap Y and Z
    z: -blenderCoords.y, // Invert the Y coordinate
  };
  return {
    x: -threejsCoords.x,
    y: threejsCoords.y,
    z: -threejsCoords.z,
  };
};

export const positionMockups = [
  [
    // Front left
    { x: -95.7983, y: -127.697, z: 151.379, size: 14, id: "0-1" },
    { x: -152.677, y: -24.0347, z: 220.405, size: 14.3, id: "0-2" },
    { x: 52.4217, y: 128.538, z: 112.908, size: 14.5, id: "0-3" },
    // Front right
    { x: 10.0055, y: -134.824, z: 135.251, size: 14, id: "0-4" },
    { x: 129.62, y: 0.115934, z: 228.782, size: 15.1, id: "0-5" },
    { x: -38.6498, y: 149.255, z: 154.692, size: 15, id: "0-6" },
    { x: -33.5431, y: 73.5461, z: 277.252, size: 14.7, id: "0-7" },
    { x: 116.109, y: -109.379, z: 122.787, size: 14.2, id: "0-8" },
    // TOP
    { x: -0.484979, y: -80.8665, z: 260.112, size: 14.9, id: "0-9" },
    { x: 94.6441, y: 104.981, z: 170.281, size: 14, id: "0-10" },
    { x: -108.608, y: 104.087, z: 124.078, size: 14, id: "0-11" },
    { x: 111.26, y: -86.9837, z: 189.998, size: 14, id: "0-12" },
  ],
  [
    { id: "1-1", x: -55.2398, y: 52.5077, z: 199.017, size: 8 },
    { id: "1-2", x: 46.9811, y: -70.3516, z: 111.3, size: 8 },
    { id: "1-3", x: 90.986, y: -102.377, z: 152.393, size: 8 },
    { id: "1-4", x: 0.203252, y: -115.888, z: 153.787, size: 8 },
    { id: "1-5", x: 33.0141, y: -94.464, z: 233.448, size: 8 },
    { id: "1-6", x: -42.6714, y: -60.9089, z: 263.373, size: 8 },
    { id: "1-7", x: -5.52813, y: 96.2294, z: 159.508, size: 8 },
    { id: "1-8", x: -130.679, y: -18.4666, z: 179.506, size: 8 },
    { id: "1-9", x: 34.3666, y: 93.2845, z: 112.903, size: 8 },
    { id: "1-10", x: -62.6743, y: 96.3886, z: 169.771, size: 8 },
    { id: "1-11", x: -25.7008, y: 95.3634, z: 252.007, size: 8 },
    { id: "1-12", x: -66.7902, y: -56.1028, z: 265.51, size: 8 },
    { id: "1-13", x: 109.657, y: -51.1934, z: 138.21, size: 8 },
    { id: "1-14", x: 100.23, y: 22.6131, z: 198.553, size: 8 },
    { id: "1-15", x: 49.9308, y: -112.671, z: 181.78, size: 8 },
    { id: "1-16", x: -108.689, y: -8.13893, z: 199.129, size: 8 },
    { id: "1-17", x: 91.6662, y: 37.9927, z: 138.263, size: 8 },
    { id: "1-18", x: -97.4833, y: -32.3184, z: 134.393, size: 8 },
    { id: "1-19", x: -32.9943, y: -83.0559, z: 245.06, size: 8 },
    { id: "1-20", x: -114.599, y: 50.3024, z: 115.156, size: 8 },
    { id: "1-21", x: 91.0913, y: 54.2193, z: 162.592, size: 8 },
    { id: "1-22", x: 70.2195, y: -28.45, z: 137.036, size: 8 },
    { id: "1-23", x: -56.0084, y: -83.2426, z: 117.776, size: 8 },
    { id: "1-24", x: 28.7337, y: 42.6333, z: 143.398, size: 8 },
    { id: "1-25", x: -20.153, y: 120.007, z: 134.952, size: 8 },
    { id: "1-26", x: -115.57, y: 21.6116, z: 202.785, size: 8 },
  ],
  [
    { id: "1-1", x: -81.0485, y: 28.9893, z: 139.728, size: 8 },
    { id: "1-2", x: 32.0829, y: 35.7736, z: 220.375, size: 8 },
    { id: "1-3", x: 61.5327, y: -0.679995, z: 195.961, size: 8 },
    { id: "1-4", x: -65.3639, y: -109.749, z: 140.104, size: 8 },
    { id: "1-5", x: 47.0581, y: 44.7414, z: 175.285, size: 8 },
    { id: "1-6", x: -73.3447, y: 71.1645, z: 168.459, size: 8 },
    { id: "1-7", x: 81.053, y: -90.5102, z: 164.208, size: 8 },
    { id: "1-8", x: 59.6077, y: 39.0842, z: 127.541, size: 8 },
    { id: "1-9", x: -62.1751, y: 6.6818, z: 220.853, size: 8 },
    { id: "1-10", x: 4.58105, y: -55.2096, z: 231.444, size: 8 },
    { id: "1-11", x: 91.5649, y: -0.628282, z: 102.946, size: 8 },
    { id: "1-12", x: -80.9424, y: 15.9777, z: 152.932, size: 8 },
    { id: "1-13", x: -57.7483, y: -88.4923, z: 211.248, size: 8 },
    { id: "1-14", x: 31.9751, y: -9.94505, z: 226.603, size: 8 },
    { id: "1-15", x: 2.19039, y: 77.4782, z: 116.807, size: 8 },
    { id: "1-16", x: 29.9709, y: -3.11359, z: 256.848, size: 8 },
    { id: "1-17", x: 61.007, y: 32.8879, z: 182.301, size: 8 },
    { id: "1-18", x: -96.3244, y: -64.353, z: 177.978, size: 8 },
    { id: "1-19", x: -84.6997, y: -49.2942, z: 157.898, size: 8 },
    { id: "1-20", x: -20.8822, y: 94.5371, z: 136.287, size: 8 },
    { id: "1-21", x: -70.915, y: 53.8941, z: 176.224, size: 8 },
    { id: "1-22", x: 38.1292, y: -76.6115, z: 166.596, size: 8 },
    { id: "1-23", x: -33.6689, y: -16.1768, z: 109.981, size: 8 },
    { id: "1-24", x: 64.4085, y: -36.8606, z: 178.754, size: 8 },
    { id: "1-25", x: -92.1933, y: -1.98555, z: 179.123, size: 8 },
    { id: "1-26", x: -52.7187, y: -22.493, z: 205.031, size: 8 },
    { id: "1-27", x: -41.7684, y: -37.0846, z: 108.793, size: 8 },
    { id: "1-28", x: 97.1192, y: -1.00753, z: 197.894, size: 8 },
    { id: "1-29", x: 20.8644, y: -68.4128, z: 241.792, size: 8 },
    { id: "1-30", x: 67.8699, y: -62.4897, z: 150.435, size: 8 },
    { id: "1-31", x: 96.694, y: -18.0164, z: 160.683, size: 8 },
    { id: "1-32", x: 54.085, y: -26.2054, z: 194.222, size: 8 },
    { id: "1-33", x: 82.52, y: -41.666, z: 181.653, size: 8 },
    { id: "1-34", x: 22.6662, y: 82.2098, z: 139.136, size: 8 },
    { id: "1-35", x: -22.8653, y: -91.9368, z: 142.071, size: 8 },
    // Undefined data
    // { id: "1-36", x: -38.5609, y: -60.2066, z: 183.248, size: 8 },
    // { id: "1-37", x: -24.3721, y: 50.4168, z: 217.131, size: 8 },
  ],
  [
    { id: "1-1", x: -42.0441, y: 70.5421, z: 138.283, size: 5 },
    { id: "1-2", x: 10.954, y: -1.97575, z: 123.704, size: 5 },
    { id: "1-3", x: 23.3511, y: 39.7245, z: 148.041, size: 5 },
    { id: "1-4", x: 31.549, y: -8.06033, z: 157.197, size: 5 },
    { id: "1-5", x: -38.2369, y: 4.21933, z: 145.645, size: 5 },
    { id: "1-6", x: -10.3615, y: -72.6355, z: 172.946, size: 5 },
    { id: "1-7", x: 2.61608, y: -50.4951, z: 197.159, size: 5 },
    { id: "1-8", x: 48.5615, y: 22.0993, z: 196.536, size: 5 },
    { id: "1-9", x: 1.50984, y: 13.4518, z: 139.909, size: 5 },
    { id: "1-10", x: -34.459, y: -56.253, z: 149.517, size: 5 },
    { id: "1-11", x: -72.0926, y: -11.0136, z: 178.115, size: 5 },
    { id: "1-12", x: 4.52379, y: 18.0964, z: 184.239, size: 5 },
    { id: "1-13", x: 64.8421, y: -8.00737, z: 147.675, size: 5 },
    { id: "1-14", x: -17.1749, y: 26.3677, z: 125.63, size: 5 },
    { id: "1-15", x: -18.5189, y: 2.66528, z: 85.8733, size: 5 },
    { id: "1-16", x: 9.7313, y: 17.6608, z: 109.292, size: 5 },
    { id: "1-17", x: -54.6716, y: 54.5599, z: 168.334, size: 5 },
    { id: "1-18", x: -57.4026, y: -25.0445, z: 150.448, size: 5 },
    { id: "1-19", x: 10.3743, y: 24.3133, z: 214.477, size: 5 },
    { id: "1-20", x: 57.8462, y: -35.0721, z: 134.053, size: 5 },
    { id: "1-21", x: -9.10744, y: 41.7146, z: 185.492, size: 5 },
    { id: "1-22", x: -17.6275, y: -28.7699, z: 123.114, size: 5 },
    { id: "1-23", x: -7.27426, y: -14.9065, z: 74.3855, size: 5 },
    { id: "1-24", x: 20.7279, y: -12.4888, z: 103.678, size: 5 },
    { id: "1-25", x: -63.949, y: 31.1584, z: 178.144, size: 5 },
    { id: "1-26", x: -24.496, y: -78.585, z: 155.139, size: 5 },
    { id: "1-27", x: 0.459746, y: -1.44702, z: 89.311, size: 5 },
    { id: "1-28", x: 27.7566, y: -69.7412, z: 189.466, size: 5 },
    { id: "1-29", x: -22.6434, y: -2.54747, z: 159.306, size: 5 },
    { id: "1-30", x: -50.1864, y: -7.54685, z: 215.785, size: 5 },
    { id: "1-31", x: 31.8056, y: 15.9384, z: 114.254, size: 5 },
    { id: "1-32", x: 55.5163, y: 16.0702, z: 139.975, size: 5 },
    { id: "1-33", x: 60.7693, y: 1.29889, z: 108.934, size: 5 },
    { id: "1-34", x: 12.3099, y: 47.7087, z: 172.644, size: 5 },
    { id: "1-35", x: 17.9706, y: 73.0617, z: 167.399, size: 5 },
    { id: "1-36", x: -2.46995, y: -18.2908, z: 197.751, size: 5 },
    { id: "1-37", x: -45.7241, y: 9.14605, z: 120.858, size: 5 },
    { id: "1-38", x: 58.5692, y: -45.6797, z: 119.39, size: 5 },
    { id: "1-39", x: 38.993, y: -51.8625, z: 163.333, size: 5 },
    { id: "1-40", x: -22.6718, y: -39.8266, z: 154.981, size: 5 },
    { id: "1-41", x: 42.664, y: 69.9662, z: 158.205, size: 5 },
    { id: "1-42", x: -46.6506, y: -29.1483, z: 156.783, size: 5 },
    { id: "1-43", x: -37.5269, y: 70.5967, z: 193.781, size: 5 },
    { id: "1-44", x: -40.5223, y: -31.1846, z: 180.58, size: 5 },
    { id: "1-45", x: -19.0516, y: -58.5902, z: 180.519, size: 5 },
    { id: "1-46", x: 33.3372, y: -38.6407, z: 201.626, size: 5 },
    { id: "1-47", x: -6.55084, y: 65.4278, z: 190.014, size: 5 },
    { id: "1-48", x: -41.9696, y: 33.1955, z: 113.6, size: 5 },
    { id: "1-49", x: -22.4341, y: 21.2453, z: 153.727, size: 5 },
    { id: "1-50", x: 4.46843, y: -25.8735, z: 186.774, size: 5 },
    { id: "1-51", x: -1.08389, y: -8.19831, z: 155.578, size: 5 },
    { id: "1-52", x: 1.0744, y: 10.0302, z: 81.4223, size: 5 },
    { id: "1-53", x: -22.6031, y: -61.0384, z: 112.502, size: 5 },
    { id: "1-54", x: -7.02226, y: -22.2376, z: 101.926, size: 5 },
    { id: "1-55", x: 9.60561, y: -16.9578, z: 86.5136, size: 5 },
  ],
].map((level) =>
  level.map((item) => ({ ...item, ...convertBlenderToThreeJS(item) })),
);

export type HighlightLevels = 0 | 1 | 2 | 3;

// export type HighlightShape = {
//   id: string;
//   size: number;
//   x: number;
//   y: number;
//   z: number;
//   title?: string;
//   color: string;
// };

export type HighlightGroupShape = {
  color?: HighlightColors;
  items: DisplayEvent[];
};

export type HighlightLevelShape = {
  level: HighlightLevels;
  groups: HighlightGroupShape[];
};

export const highlightData = (
  highlights: DisplayEvent[][],
): HighlightLevelShape[] => {
  // @ts-ignore
  return highlights.map((items, level) => {
    // const shuffledItems = shuffle(items);
    // const chunked = chunk(shuffledItems, Math.ceil(shuffledItems.length / 4));
    const chunked = chunk(items, Math.ceil(items.length / 4));
    const groups = chunked.map((items, groupIndex) => {
      const replacedItems = items.map((item) => {
        return {
          ...item,
          color: mappedHighlightColors[groupIndex],
        };
      }, []);
      return {
        color: highlightColors[groupIndex],
        items: replacedItems,
      };
    });
    return {
      level: level as HighlightLevels,
      groups,
    };
  });
};
