import buzz from "buzz";
import { Howl, SoundSpriteDefinitions } from "howler";
import soundsrc from "../../../assets/music.mp3";

const sprite: SoundSpriteDefinitions = {
  intro: [0, 14880],
  homeloop: [16000, 22040, true],
  detailloop: [40000, 29720, true],
  resultloop: [71000, 23360, true],
  sfxclick: [96000, 480],
  sfxdash: [98000, 1400],
  sfxexplore: [101000, 2880],
  sfxlastclick: [105000, 2480],
  sfxlock: [109000, 1560],
  airplane: [112000, 2600],
  apple: [116000, 1680],
  crown: [119000, 2240],
  kite: [123000, 2800],
};

export class NativeAudioPlayer {
  src: string;
  sprite: any;
  sounds: Map<string, any>;
  playIdCounter: number;
  isPlayingMap: Map<number, boolean>;
  currentTimeUpdateHandler: { [key: number]: any }; // To store the timeupdate listeners for each playId
  playIdToSpriteKey: Map<number, string>; // Map playId to spriteKey

  constructor(src: string, sprite: SoundSpriteDefinitions) {
    this.src = src;
    this.sprite = sprite;
    this.sounds = new Map();
    this.playIdCounter = 0; // Start from 0, but will increment to 1 on first play
    this.isPlayingMap = new Map();
    this.currentTimeUpdateHandler = {};
    this.playIdToSpriteKey = new Map();

    // Create sounds for each sprite
    Object.keys(this.sprite).forEach((key) => {
      this.sounds.set(key, new buzz.sound(this.src)); // Reuse src for buzz
    });
  }

  // Method to handle timeupdate event and loop behavior
  handleTimeUpdate = (
    audioElement: HTMLAudioElement,
    start: number,
    duration: number,
    playId: number,
    loop: boolean,
  ) => {
    const currentTime = audioElement.currentTime * 1000; // Convert to milliseconds
    const timeElapsed = currentTime - start;

    if (timeElapsed >= duration) {
      if (loop) {
        // If loop is true, reset the audio back to the start of the sprite
        audioElement.currentTime = start / 1000;
      } else {
        // Otherwise, stop the sound when it reaches the end
        this.stop(playId);
      }
    }
  };

  // A method to attach the timeupdate listener to the audio element
  attachTimeUpdateListener(
    audioElement: HTMLAudioElement,
    start: number,
    duration: number,
    playId: number,
    loop: boolean,
  ) {
    const timeUpdateHandler = () =>
      this.handleTimeUpdate(audioElement, start, duration, playId, loop);
    this.currentTimeUpdateHandler[playId] = timeUpdateHandler;
    audioElement.addEventListener("timeupdate", timeUpdateHandler);
  }

  // Play a sprite by key
  play(spriteKey: string): number {
    const sprite = this.sprite[spriteKey];
    if (!sprite) {
      console.error(`Sprite ${spriteKey} not found`);
      return -1;
    }

    const [start, duration, loop] = sprite; // Destructure the sprite array
    this.playIdCounter += 1; // Increment playIdCounter first
    const playId: number = this.playIdCounter; // Now get the playId from the incremented counter
    this.playIdToSpriteKey.set(playId, spriteKey); // Map the playId to spriteKey
    let sound: any = this.sounds.get(spriteKey);

    if (!sound) {
      console.error("Sound not found for sprite:", spriteKey);
      return -1;
    }

    // Access the audio element
    const audioElement = sound.sound;

    if (audioElement) {
      // Set the start time for the sprite
      audioElement.currentTime = start / 1000; // Convert start time to seconds

      // Attach the timeupdate listener to handle playback, including looping
      this.attachTimeUpdateListener(
        audioElement,
        start,
        duration,
        playId,
        loop,
      );

      audioElement.play();
      this.isPlayingMap.set(playId, true);

      // audioElement.addEventListener("play", () =>
      //   console.log(`Audio for sprite '${spriteKey}' started playing`),
      // );
      // audioElement.addEventListener("pause", () =>
      //   console.log(`Audio for sprite '${spriteKey}' paused`),
      // );
      audioElement.addEventListener("error", (e: any) =>
        console.error(`Audio error for sprite '${spriteKey}':`, e),
      );
    } else {
      console.error("No audio element found for sound:", spriteKey);
    }

    return playId; // Return the new playId starting from 1
  }

  pause(playId: number) {
    const spriteKey = this.playIdToSpriteKey.get(playId); // Get spriteKey from playId
    if (!spriteKey) {
      console.error(`No spriteKey found for playId: ${playId}`);
      return;
    }

    const sound = this.sounds.get(spriteKey);
    if (!sound) {
      console.error(`No sound found for sprite: ${spriteKey}`);
      return;
    }

    if (this.isPlayingMap.get(playId)) {
      sound.pause(); // Pause the sound
      this.isPlayingMap.set(playId, false); // Update the playing status
      // console.log(`Audio for sprite '${spriteKey}' paused`);
    }
  }

  // Stop a specific sound by playId
  stop(playId?: number) {
    if (playId) {
      const spriteKey = this.playIdToSpriteKey.get(playId); // Get spriteKey from playId
      if (!spriteKey) {
        console.error(`No spriteKey found for playId: ${playId}`);
        return;
      }

      const sound = this.sounds.get(spriteKey);
      if (!sound) return;

      if (this.isPlayingMap.get(playId)) {
        sound.pause();
        this.isPlayingMap.set(playId, false);

        // Remove the timeupdate listener for the stopped sound
        const audioElement = sound.sound;
        if (audioElement) {
          const timeUpdateHandler = this.currentTimeUpdateHandler[playId];
          if (timeUpdateHandler) {
            audioElement.removeEventListener("timeupdate", timeUpdateHandler);
            delete this.currentTimeUpdateHandler[playId]; // Clean up the handler
          }

          // Log the stop event with sprite name
          //console.log(`Audio for sprite '${spriteKey}' stopped`);
        }
      }
    } else {
      buzz.all().stop();
    }
  }

  mute(muted: boolean): void {
    this.sounds.forEach((sound) => {
      if (sound) {
        muted ? sound.mute() : sound.unmute();
      }
    });
  }

  // Check if any sound is playing
  playing(): boolean {
    return Array.from(this.isPlayingMap.values()).some(
      (isPlaying) => isPlaying === true,
    );
  }

  // Seek to a specific time in the sprite
  seek(position: number, playId: number) {
    const spriteKey = this.playIdToSpriteKey.get(playId); // Get spriteKey from playId
    if (!spriteKey) {
      console.error(`No spriteKey found for playId: ${playId}`);
      return;
    }

    const sound = this.sounds.get(spriteKey as string);
    if (sound) {
      sound.setTime(position);
    }
  }

  // Get the duration of a specific sprite by playId
  duration(playId: number): number {
    const spriteKey = this.playIdToSpriteKey.get(playId); // Get spriteKey from playId
    if (!spriteKey) {
      console.error(`No spriteKey found for playId: ${playId}`);
      return 0;
    }

    const sprite = this.sprite[spriteKey as string];
    if (!sprite) {
      console.error(`Sprite ${spriteKey} not found`);
      return 0;
    }
    const [, duration] = sprite;
    return duration;
  }

  unload() {
    buzz.all().stop();

    this.playIdToSpriteKey.clear();
    this.isPlayingMap.clear();
    this.currentTimeUpdateHandler = {};
  }
}

const sound = isIOS
  ? new NativeAudioPlayer(soundsrc, sprite)
  : new Howl({
      src: [soundsrc],
      sprite,
      volume: 1,
      html5: isIOS ? true : false,
      preload: true,
      onplayerror: function () {
        console.log("error");
      },
    });

// if (isIOS) {
//   Howler.autoUnlock = true;
//   Howler.html5PoolSize = 100;
//   // sound.once("load", () => {
//   //   idSoundPlaying = sound.play("intro");
//   // });
//   sound.once("loaderror", () => {
//     Howler.unload();
//   });

//   sound.once("playerror", () => {
//     Howler.unload();
//   });
//   sound.once("stop", () => {});

//   sound.once("unlock", () => {});

//   // sound.once("end", () => {
//   //   sound.stop(idSoundPlaying);
//   // });
// }

//@ts-ignore
//sound.usingWebAudio = true;

export default sound;
