import "./App.scss";
import { Route, Routes } from "react-router-dom";
import { EventsProvider } from "./context/Events";
// import VConsole from "vconsole";
import { SoundProvider } from "./context/Sound";
import { TimelineProvider } from "./context/Timeline";
import { TreeProvider } from "./context/Tree";
import { Index } from "./pages/Index";

// new VConsole();

function App() {
  return (
    <TimelineProvider>
      <SoundProvider>
        <TreeProvider>
          <EventsProvider>
            <div className="w-full h-full overflow-hidden bg-[#140c17]">
              <Routes>
                <Route
                  path="/"
                  element={<Index />}
                />
              </Routes>
            </div>
          </EventsProvider>
        </TreeProvider>
      </SoundProvider>
    </TimelineProvider>
  );
}

export default App;
