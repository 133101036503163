import { forwardRef, ReactNode } from "react";

const Ripple = forwardRef<
  HTMLDivElement,
  {
    size?: number;
    position?: { x: number | string; y: number | string };
    color?: string;
    small?: boolean;
    scaled?: boolean;
    children?: ReactNode;
  }
>(({ size, position, color, children, small, scaled }, ref) => {
  let style: any = {};
  if (position) {
    style.left = position.x;
    style.top = position.y;
  }
  if (size) {
    style.width = size;
    style.height = size;
  }
  if (color) {
    style.borderColor = `${color} + 80`;
  }
  return (
    <div
      ref={ref}
      className={`absolute w-full h-full top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 border-[rgba(255,255,255,0.5)] before:content-[''] before:absolute before:top-0 before:right-0 before:bottom-0 before:left-0 before:rounded-full before:border-inherit before:filter after:content-[''] after:absolute after:top-0 after:right-0 after:bottom-0 after:left-0 after:rounded-full after:border-inherit after:filter ${small ? "before:animate-[ripple_2s_0s_linear_infinite] after:animate-[ripple_2s_1s_linear_infinite] before:blur-[0.5px] after:blur-[0.5px] before:border-[0.25px] after:border-[0.25px]" : scaled ? "before:animate-[ripple-scaled_2s_0s_linear_infinite] after:animate-[ripple-scaled_2s_1s_linear_infinite] before:blur-[1px] after:blur-[1px] before:border-[3px] after:border-[3px]" : "before:animate-[ripple_2s_0s_linear_infinite] after:animate-[ripple_2s_1s_linear_infinite] before:blur-[1px] after:blur-[1px] before:border-[0.5px] after:border-[0.5px]"}`}
      style={style}
    >
      {children}
    </div>
  );
});

export default Ripple;
