import gsap from "gsap";
import { Howl } from "howler";
import { useEffect, useRef, useState } from "react";
import wx from "weixin-js-sdk";
import { useSound } from "../../context/Sound";
import sound, { NativeAudioPlayer } from "./data/bgm";

const Audio = ({ skip, fadeOut }: { skip: boolean; fadeOut: boolean }) => {
  const { muted, scene, toggleMuted } = useSound();
  const bgmRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLDivElement>(null);
  const startTime = useRef(Date.now());
  const intro = useRef<number>(0);
  const loop = useRef<number>(0);
  let seek = 0;

  const wechatAudioPlay = (
    obj: Howl | NativeAudioPlayer,
    id: string,
    id2?: string,
  ) => {
    //obj.play()
    return new Promise((resolve: any) => {
      if (
        typeof window.WeixinJSBridge == "object" &&
        typeof window.WeixinJSBridge.invoke == "function"
      ) {
        window.WeixinJSBridge.invoke(
          "getNetworkType",
          {},
          () => {
            intro.current = obj.play(id);
            if (isIOS && id2) {
              const playId = obj.play(id2);
              setTimeout(() => {
                obj.pause(playId);
              }, 1000);
            }
            resolve();
          },
          false,
        );
        window.WeixinJSBridge.on("onPageStateChange", (res: any) => {
          if (eval(res.active)) {
            toggleMuted(false);
          } else {
            toggleMuted(true);
          }
        });
        if (isIOS) {
          window.addEventListener("activitychange", (res: any) => {
            console.log(res);
            console.log("pagehide");
          });
        }
      } else {
        if (document.addEventListener) {
          document.addEventListener(
            "WeixinJSBridgeReady",
            () => {
              intro.current = obj.play(id);
              if (isIOS && id2) {
                const playId = obj.play(id2);
                setTimeout(() => {
                  playId && obj.pause(playId);
                }, 1000);
              }
              resolve();
            },
            false,
          );
        }
      }
      // window.addEventListener(
      //   "pagehide",
      //   (event) => {
      //     if (event.persisted) {
      //       console.log("persisted");
      //       sound.stop();
      //     }
      //   },
      //   false,
      // );
    });
  };

  useEffect(() => {
    startTime.current = Date.now();
    wechatAudioPlay(sound, "intro", "homeloop");
    setTimeout(() => {
      if (sound.playing() === true) {
        toggleMuted(false);
      } else {
        toggleMuted(true);
      }
    }, 200);
    return () => {
      // console.log("unmount");
      sound.unload();
    };
  }, []);

  const handleAudioButton = () => {
    if (muted) {
      const now = Date.now();
      seek = (now - startTime.current) / 1000;
      if (skip || fadeOut) {
        intro.current && sound.stop(intro.current);
        if (!loop.current) {
          loop.current = sound.play(scene + "loop");
        }
      } else {
        //intro
        try {
          intro.current = sound.play("intro");
          if (sound.duration(intro.current) >= seek) {
            sound.seek(seek, intro.current);
          }
        } catch (error) {
          console.error("Error:", error);
        }
      }
      toggleMuted(false);
    } else {
      toggleMuted(true);
    }
  };

  useEffect(() => {
    if (loop.current) {
      sound.stop(loop.current);
    }
    if (intro.current) {
      try {
        sound.stop(intro.current);
      } catch (error) {
        console.error("Error:", error);
      }
    }
    if (scene !== "intro") {
      loop.current = sound.play(scene + "loop");
    }
  }, [scene]);

  useEffect(() => {
    sound.mute(muted);
  }, [muted]);

  useEffect(() => {
    if (textRef.current && (fadeOut || skip)) {
      gsap.to(textRef.current, {
        autoAlpha: 0,
        duration: 0.8,
        onComplete: () => {
          if (!muted) {
            if (loop.current) return;
            loop.current = sound.play("loop");
          }
        },
      });
    }
  }, [skip, fadeOut, textRef.current]);

  useEffect(() => {
    if (bgmRef.current) {
      if (!muted) {
        bgmRef.current.classList.add("playing");
      } else {
        bgmRef.current.classList.remove("playing");
      }
    }
  }, [muted, bgmRef.current]);

  return (
    <div
      className="group absolute flex items-center gap-[6.5px] font-bold text-[12px] leading-[19px] tracking-[0.24px] bottom-[6.024vh] left-[6.15vw] z-[105] text-white"
      onClick={handleAudioButton}
      ref={bgmRef}
    >
      <div className="h-[9px] w-[18px] flex justify-between items-end">
        <div className="spin spin-1 w-[1.5px] rounded-[1.5px] h-[7px] bg-white transition-height duration-300 group-[.playing]:animate-spinf"></div>
        <div className="spin spin-2 w-[1.5px] rounded-[1.5px] h-[7px] bg-white transition-height duration-300 group-[.playing]:animate-spinb"></div>
        <div className="spin spin-3 w-[1.5px] rounded-[1.5px] h-[7px] bg-white transition-height duration-300 group-[.playing]:animate-spinf"></div>
        <div className="spin spin-4 w-[1.5px] rounded-[1.5px] h-[7px] bg-white transition-height duration-300 group-[.playing]:animate-spinb"></div>
        <div className="spin spin-5 w-[1.5px] rounded-[1.5px] h-[7px] bg-white transition-height duration-300 group-[.playing]:animate-spinf"></div>
      </div>
      <span ref={textRef}>声音：{muted ? "关" : "开"}</span>
    </div>
  );
};

export default Audio;
