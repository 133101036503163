import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useRef,
  useState,
} from "react";
import easters from "../components/easter/data/ids";
import { getEventDetail } from "../data/api";
import { DisplayEvent, type RawEvent } from "../types/event";
import { useTree } from "./Tree";
import { useSound } from "./Sound";
import sound from "../components/audio/data/bgm";

export enum Direction {
  FORWARD = "FORWARD",
  BACKWARD = "BACKWARD",
}

type EventContext = {
  step: number;
  setStep: Dispatch<SetStateAction<number>>;
  ids: string;
  routes: RawEvent[];
  easterMatched: {
    animation: string;
    duration: number;
  } | null;
  targetEvent: DisplayEvent | null;
  updateTargetEvent: (target: DisplayEvent | null) => void;
  fetchEventDetail: (
    ids: string,
    size?: number,
    color?: string,
  ) => Promise<{ data: RawEvent[] }>;
  back: () => void;
  resetData: () => void;
  onShowEaster: (event: DisplayEvent) => void;
  onEventClick: (event: DisplayEvent) => void;
  shouldShowNextEvents: boolean;
  updateShouldShowNextEvent: (value: boolean) => void;
  isLast: boolean;
  updateIsLast: (value: boolean) => void;
  clickHistory: DisplayEvent[];
  direction: Direction;
};

export const EventsContext = createContext<EventContext>({
  step: -1,
  setStep: () => -1,
  ids: "",
  easterMatched: null,
  routes: [],
  targetEvent: null,
  updateTargetEvent: () => {},
  fetchEventDetail: async () => ({ data: [] }),
  back: () => {},
  resetData: () => {},
  onShowEaster: () => {},
  onEventClick: () => {},
  shouldShowNextEvents: false,
  updateShouldShowNextEvent: () => {},
  isLast: false,
  updateIsLast: () => {},
  clickHistory: [],
  direction: Direction.FORWARD,
});

export const EventsProvider = ({ children }: { children: ReactNode }) => {
  const { muted } = useSound();
  const [step, setStep] = useState(-1);
  const [ids, setIds] = useState("");
  const [routes, setRoutes] = useState<RawEvent[]>([]);
  const [targetEvent, setTargetEvent] = useState<DisplayEvent | null>(null);
  const [shouldShowNextEvents, setShouldShowNextEvent] = useState(false);
  const [isLast, setIsLast] = useState(false);
  const [clickHistory, setClickHistory] = useState<DisplayEvent[]>([]);
  const [direction, setDirection] = useState(Direction.FORWARD);
  const [easterMatched, setEasterMatched] = useState<any>(null);
  const easterTimeout = useRef<NodeJS.Timeout | null>(null);

  const { updateBoundingBox } = useTree();

  const fetchEventDetail = async (
    input_id: string,
    size?: number,
    color?: string,
  ): Promise<{ data: RawEvent[] }> => {
    const id = ids ? ids + "," + input_id : input_id;
    const { data } = await getEventDetail(id); // No more recursion

    if (data.data) {
      setStep(data.data.step);
      setIds(id);
      const currentRoutes = routes; // []
      const currentRoute = data.data;
      currentRoute.color = color;
      if (size) {
        currentRoute.size = size;
      }

      currentRoutes.push(currentRoute);
      setRoutes(currentRoutes);

      setClickHistory((prev) => {
        const next = prev;
        const matchedHistoryIndex = next.findIndex(
          (event) => event.id === currentRoute.id,
        );
        if (matchedHistoryIndex === -1 || !data.data.child) {
          return next;
        }
        next[matchedHistoryIndex].child = data.data.child;
        return next;
      });
    }
    return data.data;
  };

  const back = () => {
    if (step > 0) {
      const lastEvent = clickHistory[clickHistory.length - 2];
      easterTimeout.current = setTimeout(() => {
        onShowEaster(lastEvent);
      }, 2000);
      const currentIds = ids.split(",");
      const currentRoutes = routes;
      currentRoutes.pop();
      currentIds.pop();
      setIds(currentIds.join(","));
      setRoutes(currentRoutes);
      setStep(step - 1 < 1 ? 1 : step - 1);
      setClickHistory((prev) => prev.slice(0, prev.length - 1));
      setDirection(Direction.BACKWARD);
      setIsLast(false);
    }
  };

  const resetData = () => {
    setStep(-1);
    setIds("");
    setRoutes([]);
    setTargetEvent(null);
    setIsLast(false);
    setDirection(Direction.FORWARD);
    setClickHistory([]);
    updateBoundingBox(null);
    setShouldShowNextEvent(false);
  };

  const onShowEaster = (event: DisplayEvent) => {
    easterTimeout.current && clearTimeout(easterTimeout.current);
    const { id } = event;
    if (Object.keys(easters).includes(id)) {
      //@ts-ignore
      const matchedEaster = easters[id];
      if (matchedEaster) {
        !muted && sound.play(matchedEaster.animation);
        setEasterMatched(matchedEaster);
        const timeout = setTimeout(() => {
          setEasterMatched(null);
          clearTimeout(timeout);
        }, matchedEaster.duration * 1000);
      }
    }
  };

  const onEventClick = (event: DisplayEvent) => {
    const { id, size, color, position } = event;

    fetchEventDetail(id, size, color);

    setClickHistory((prev) => [...prev, event]);
    setDirection(Direction.FORWARD);

    const { x, y, z } = position;
    setTargetEvent({
      ...event,
      position: { x, y, z },
    });

    if(isIOS) {
      if (Object.keys(easters).includes(id)) {
        //@ts-ignore
        const matchedEaster = easters[id];
        if (matchedEaster) {
          const playId = !muted && sound.play(matchedEaster.animation);
          !muted && sound.pause(playId as number);
        }
      }
    }

    easterTimeout.current = setTimeout(() => {
      onShowEaster(event);
    }, 2000);
  };

  return (
    <EventsContext.Provider
      value={{
        step,
        setStep,
        ids,
        easterMatched,
        routes,
        targetEvent,
        updateTargetEvent: setTargetEvent,
        fetchEventDetail,
        back,
        resetData,
        onShowEaster,
        onEventClick,
        shouldShowNextEvents,
        updateShouldShowNextEvent: setShouldShowNextEvent,
        isLast,
        updateIsLast: setIsLast,
        clickHistory,
        direction,
      }}
    >
      {children}
    </EventsContext.Provider>
  );
};

export const useEvents = () => {
  return useContext(EventsContext);
};
