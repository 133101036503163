import gsap from "gsap";
import { useEffect, useRef, useState } from "react";
// import totorial from "../../assets/tutorial.gif";
import { useTimeline } from "../../context/Timeline";

const texts = [
  {
    line1: "从石器时代到现代",
    line2: "人类科技生生不息",
  },
  {
    line1: "数以万计的科技成果",
    line2: "灿若繁星",
  },
  {
    line1: "一起探索",
    line2: "妙趣连环的科技因果",
  },
];

const tutorialTextDuration = 0.3;
const tutorialTexts = ["360°旋转", "放大缩小", "双指移动"];
const tutorialDuration = [4.48, 4.48, 4.56];

const Intro = () => {
  const { skip, updateSkip, isHaloComplete } = useTimeline();
  const localtutorial = localStorage.getItem("tutorial") || "false";
  if (!localtutorial) {
    localStorage.setItem("tutorial", "false");
  }
  const introRef = useRef<HTMLDivElement>(null);
  const tutorialRef = useRef<HTMLDivElement>(null);
  const tutorialAnimationRef = useRef<HTMLDivElement>(null);
  const tutorialTextRef = useRef<HTMLDivElement>(null);
  const [completed, setCompleted] = useState<boolean>(skip);
  const [tutorialEnded, setTutorialEnded] = useState<boolean>(
    eval(localtutorial) as boolean,
  );
  const mainTimeline = useRef<GSAPTimeline>(
    gsap.timeline({
      repeat: 0,
      paused: true,
    }),
  );
  const tutorTimeline = useRef<GSAPTimeline>(
    gsap.timeline({
      paused: true,
      onComplete: () => {
        onTutorialEnd();
      }
    }),
  );

  // const tl = gsap.timeline({
  //   repeat: 0,
  //   paused: true,
  // });

  const skipIntro = () => {
    updateSkip(true);
    // onSkip();
    onEnd();
  };

  const onEnd = () => {
    if (introRef.current) {
      gsap.to(introRef.current, {
        autoAlpha: 0,
        duration: 0.8,
        delay: 1.2,
        onComplete: () => {
          mainTimeline.current.totalProgress(1);
          mainTimeline.current.kill();
          setCompleted(true);
        },
      });
    }
  };

  const onTutorialEnd = () => {
    if (tutorialRef.current) {
      gsap.to(tutorialRef.current, {
        opacity: 0,
        duration: 0.3,
        onComplete: () => {
          tutorTimeline.current.totalProgress(1);
          tutorTimeline.current.kill();
          setTutorialEnded(true);
          localStorage.setItem("tutorial", "true");
        },
      });
    }
  };

  useEffect(() => {
    if (introRef.current && mainTimeline.current) {
      const tl = mainTimeline.current;
      tl.set(".screen0", { autoAlpha: 1, scale: 1 })
        .fromTo(
          ".screen0 .line1",
          { autoAlpha: 0, scale: 0.95 },
          { autoAlpha: 1, scale: 1, duration: 1 },
        ) //1
        .fromTo(
          ".screen0 .line2",
          { autoAlpha: 0, scale: 0.95 },
          { autoAlpha: 1, scale: 1, duration: 1 },
        ) //2
        .to(".screen0", { autoAlpha: 0, scale: 1.03, duration: 0.36 }, "+=0.94") //3
        .set(".screen1", { autoAlpha: 1, scale: 1 }, "+=1") // 4sec
        .fromTo(
          ".screen1 .line1",
          { autoAlpha: 0, scale: 0.95 },
          { autoAlpha: 1, scale: 1, duration: 1 },
        ) //5
        .fromTo(
          ".screen1 .line2",
          { autoAlpha: 0, scale: 0.95 },
          { autoAlpha: 1, scale: 1, duration: 1 },
        ) //6
        .to(".screen1", { autoAlpha: 0, scale: 1.03, duration: 0.36 }, "+=0.94") //7
        .set(".screen2", { autoAlpha: 1, scale: 1 }, "+=0.6") // 7.6
        .fromTo(
          ".screen2 .line1",
          { autoAlpha: 0, scale: 0.95 },
          { autoAlpha: 1, scale: 1, duration: 1 },
        )
        .fromTo(
          ".screen2 .line2",
          { autoAlpha: 0, scale: 0.95 },
          { autoAlpha: 1, scale: 1, duration: 1 },
        ) //9.6sec
        .to(
          ".screen2",
          { autoAlpha: 0, scale: 1.03, duration: 0.36 },
          "+=0.44",
        ); //10.3sec
      tl.play();
    }
    return () => {
      mainTimeline.current && mainTimeline.current.kill();
    };
  }, []);

  useEffect(() => {
    if (isHaloComplete || skip) {
      onEnd();
    }
  }, [skip, isHaloComplete]);

  useEffect(() => {
    if (completed && tutorialAnimationRef.current) {
      tutorTimeline.current
        .add(() => {
          tutorialTextRef.current!.innerHTML = tutorialTexts[0];
          gsap.set(tutorialTextRef.current, { autoAlpha: 0 });
        })
        .to(tutorialTextRef.current, {
          autoAlpha: 1,
          duration: tutorialTextDuration,
        })
        .add(() => {
          tutorialAnimationRef.current!.classList.add(
            "bg-tutor1",
            "animate-tutor1",
          );
          tutorialAnimationRef.current!.style.animationPlayState = "paused";
        }, "-=0.1")
        .add(() => {
          tutorialAnimationRef.current!.removeAttribute("style");
        })
        .to(
          tutorialTextRef.current,
          {
            autoAlpha: 0,
            duration: tutorialTextDuration,
          },
          `+=${tutorialDuration[0] - tutorialTextDuration - (tutorialTextDuration - 0.1)}`,
        )
        .add(() => {
          tutorialAnimationRef.current!.classList.remove(
            "bg-tutor1",
            "animate-tutor1",
          );
        }, `+=${tutorialTextDuration}`)
        .add(() => {
          tutorialAnimationRef.current!.classList.add(
            "bg-tutor2",
            "animate-tutor2",
          );
          tutorialTextRef.current!.innerHTML = tutorialTexts[1];
        })
        .to(tutorialTextRef.current, {
          autoAlpha: 1,
          duration: tutorialTextDuration,
        })
        .to(
          tutorialTextRef.current,
          {
            autoAlpha: 0,
            duration: tutorialTextDuration,
          },
          `+=${tutorialDuration[1] - tutorialTextDuration * 2}`,
        )
        .add(() => {
          tutorialAnimationRef.current!.classList.remove(
            "bg-tutor2",
            "animate-tutor2",
          );
        })
        .add(() => {
          tutorialAnimationRef.current!.classList.add(
            "bg-tutor3",
            "animate-tutor3",
          );
          tutorialTextRef.current!.innerHTML = tutorialTexts[2];
        })
        .to(tutorialTextRef.current, {
          autoAlpha: 1,
          duration: tutorialTextDuration,
        })
        .to(
          tutorialTextRef.current,
          {
            autoAlpha: 0,
            duration: tutorialTextDuration,
          },
          `+=${tutorialDuration[2] - tutorialTextDuration * 2}`,
        )
        .add(() => {
          tutorialAnimationRef.current!.classList.remove(
            "bg-tutor3",
            "animate-tutor3",
          );
        });
      gsap.to(tutorialRef.current, {
        autoAlpha: 1,
        duration: 0.3,
        onComplete: () => {
          tutorTimeline.current.play();
        },
      });
      tutorTimeline.current.play();
    }
    return () => {
      tutorTimeline.current && tutorTimeline.current.kill();
    };
  }, [completed]);

  return !completed ? (
    <div
      className="absolute w-full h-full top-0 left-0 z-[100] text-white"
      ref={introRef}
    >
      {texts &&
        texts.map((text, index) => (
          <div
            key={`text${index}`}
            className={`screen${index} absolute w-full h-full flex flex-col items-center justify-center text-[18px] leading-[27px] font-bold text-white drop-shadow-3xl opacity-0`}
          >
            <p className="line1">{text.line1}</p>
            <p className="line2 mb-[13vh]">{text.line2}</p>
          </div>
        ))}
      <div
        className="absolute font-bold text-[12px] leading-[19px] tracking-[0.24px] bottom-[6.024vh] right-[4.87vw]"
        onClick={skipIntro}
      >
        跳过
      </div>
    </div>
  ) : !tutorialEnded ? (
    <div
      className="absolute w-full h-full top-0 left-0 z-[9000] bg-[rgba(20,13,22,0.68)] opacity-0"
      ref={tutorialRef}
      onClick={onTutorialEnd}
    >
      <div
        className="absolute w-[72.31vw] h-[72.31vw] top-[44.44%] left-1/2 bg-[#18111A] -translate-x-1/2 -translate-y-1/2 rounded-full font-bold text-white text-[13.5px] leading-[18px] tracking-[0.945px] overflow-hidden border-[1.2px] flex flex-col items-center justify-center gap-[15px] border-[rgba(255,255,255,0.2)]"
      >
        <div
          className="w-[44vw] h-[44.1vw] bg-left-top [animation-fill-mode:forwards]"
          ref={tutorialAnimationRef}
        />
        <p
          className="text-center"
          ref={tutorialTextRef}
        ></p>
      </div>
    </div>
  ) : null;
};

export default Intro;
