import gsap from "gsap";
import { useEffect, useRef, useState } from "react";
import { Easter, ExpandableDot, Ripple, Steps } from "../";
import backIcon from "../../assets/icon-back.svg";
import closeIcon from "../../assets/icon-close.svg";
import handIcon from "../../assets/icon-hand.svg";
import { useEvents } from "../../context/Events";
import { useSound } from "../../context/Sound";
import { useTree } from "../../context/Tree";
import { trackJumpCount, trackJumpOnce } from "../../utils/analysis";
import sound from "../audio/data/bgm";
import {
  DOTBOUNCE_DUR,
  EXPAND_DUR,
  LAST_DOT_SHOWN_DELAY,
  LAYER_DUR,
  RAY_DUR,
} from "./data/durations";

const Layer = ({
  closeCallback,
  backCallback,
}: {
  closeCallback?: Function;
  backCallback?: Function;
}) => {
  const { muted } = useSound();
  const {
    step,
    clickHistory,
    routes,
    setStep,
    back,
    targetEvent,
    isLast,
    resetData,
  } = useEvents();
  const { isDetailShow, toggleDetail } = useTree();
  // const otherColors = [...mappedHighlightColors].filter(
  //   (color) => color !== routes[step - 1]?.color,
  // );
  const [displayLastDot, setDisplayLastDot] = useState<boolean>(false);
  const defaultColor = routes[step - 1]?.color as string;
  // const [isLast, setIsLast] = useState<boolean>(false);
  const [color, setColor] = useState<string>(defaultColor);
  const [enlarge, setEnlarge] = useState<boolean>(false);
  // const [delayContent, setDelayContent] = useState<any>(routes[step - 1]);
  // const [nextContent, setNextContent] = useState<any>(routes[step]);
  const [lockIdle, setLockIdle] = useState<boolean>(false);
  // const alphaContainer = useRef<HTMLDivElement>(null);
  const layerContainer = useRef<HTMLDivElement>(null);
  const rippleRef = useRef<HTMLDivElement>(null);
  const rayRef = useRef<HTMLDivElement>(null);
  const dotWrapperRef = useRef<HTMLDivElement>(null);
  const mainTimeline = useRef<GSAPTimeline>(gsap.timeline());

  const onBack = () => {
    !muted && sound.play("sfxclick");
    !muted && sound.play("sfxexplore");
    back();
  };

  const onClose = () => {
    if (!isLast) {
      if (clickHistory.length > 1) {
        trackJumpCount(clickHistory.length - 1);
      }
      //只跳了一跳
      if (routes.length == 1) {
        trackJumpOnce();
      }
    }
    !muted && sound.play("sfxclick");
    gsap.to(layerContainer.current, {
      duration: 0.3,
      opacity: 0,
      onComplete: () => {
        resetData();
        toggleDetail(false);
      },
    });
  };

  const enlargeCallback = (bool: boolean) => {
    setEnlarge(bool);
  };

  useEffect(() => {
    if (layerContainer.current) {
      mainTimeline.current.to(layerContainer.current, {
        duration: 1,
        opacity: 1,
        delay: LAYER_DUR,
      });
    }
    return () => {
      mainTimeline.current.kill();
    };
  }, [layerContainer.current]);

  useEffect(() => {
    const tl = gsap.timeline();
    if (rayRef.current) {
      tl.fromTo(
        rayRef.current,
        {
          width: 0,
        },
        {
          duration: RAY_DUR,
          width: "100%",
          ease: "power2.inOut",
        },
      );
    }
    return () => {
      tl.kill();
    };
  }, [rayRef.current, step]);

  useEffect(() => {
    if (isLast && !displayLastDot) {
      setTimeout(() => {
        setDisplayLastDot(true);
      }, LAST_DOT_SHOWN_DELAY * 1000);
    }
    if (!isLast) {
      setDisplayLastDot(false);
    }
    const tl = gsap.timeline();
    if (isLast && rippleRef.current && dotWrapperRef.current) {
      tl.fromTo(
        dotWrapperRef.current,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 0.3,
        },
      ).to(rippleRef.current, {
        duration: DOTBOUNCE_DUR,
        width: "66px",
        height: "66px",
      });
      if (enlarge) {
        gsap.to(rippleRef.current, {
          duration: EXPAND_DUR,
          width: "2000px",
          height: "2000px",
        });
      } else {
        gsap.set(rippleRef.current, {
          width: "66px",
          height: "66px",
        });
      }
    }
  }, [
    isLast,
    displayLastDot,
    enlarge,
    dotWrapperRef.current,
    rippleRef.current,
  ]);

  useEffect(() => {
    if (targetEvent) {
      setColor(targetEvent.color);
    }
  }, [targetEvent]);

  return (
    <div
      className="absolute w-full h-0 top-0 left-0 z-[102] overflow-visible opacity-1"
      ref={layerContainer}
    >
      {targetEvent && <Easter />}
      {!enlarge && (
        <a
          className="absolute z-[1001] left-[5.64vw] top-[5.64vw] w-[22px] h-[22px]"
          onClick={onClose}
        >
          <img src={closeIcon} />
        </a>
      )}
      {isLast && displayLastDot && (
        <div
          className="absolute w-full h-screen z-[100] text-white top-0 left-0"
          style={{ filter: `drop-shadow(${color}C0 0 0 7px)` }}
          ref={dotWrapperRef}
        >
          {!enlarge && lockIdle && (
            <img
              className="absolute w-[30px] left-[27vw] top-[36.37%] rotate-[10deg] animate-hand z-[33] [animation-fill-mode:both] pointer-events-none"
              src={handIcon}
            />
          )}
          {!enlarge && (
            <Ripple
              ref={rippleRef}
              size={0}
              color={color}
              position={{ x: "18.6%", y: "34%" }}
            />
          )}
          <ExpandableDot
            color={color}
            isLast={isLast}
            // onReset={lastCallback}
            lockIdleState={lockIdle}
            setLockIdle={setLockIdle}
            content={routes[step - 1]}
            closeCallback={onClose}
            expandCallback={(bool: boolean) => enlargeCallback(bool)}
          />
        </div>
      )}
      <div className="absolute w-full h-0 top-screen left-0 z-[102] overflow-visible">
        {step !== 1 && (
          <>
            {!enlarge && (
              <a
                className="absolute z-[1001] flex flex-col items-center text-white left-1/2 bottom-[5vh] w-[50px] h-[40px] -translate-x-1/2"
                onClick={onBack}
              >
                <p className="font-bold text-[11px] leading-[19px] tracking-[0.77px] mb-[11px]">
                  上一级
                </p>
                <img src={backIcon} />
              </a>
            )}

            {/* <div className="absolute right-[calc(81.4%+2px)] top-[34%] origin-top-right rotate-[-75deg] z-[20] h-[0] w-[100vh]">
              <div
                className="absolute top-0 left-0 w-[0] h-full bg-white border-white shadow-[0px_0px_6px_0px_#FFF,0px_0px_10px_0px_rgba(255,255,255,0.7)] border-[1.5px]"
                ref={rayRef}
              ></div>
            </div> */}
          </>
        )}
        {!enlarge && <Steps step={step} />}
      </div>
    </div>
  );
};

export default Layer;
