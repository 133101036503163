import gsap from "gsap";
import { useEffect, useRef } from "react";
import { Text } from "../";
import { type RawEvent } from "../../types/event";
import { ENLARGE_TRANSITION } from "../layer/data/transitions";

const PointDetail = ({
  small,
  content,
  fade,
  delay = 0.1,
}: {
  small?: boolean;
  content: RawEvent;
  fade?: boolean;
  delay?: number;
}) => {
  const allText = useRef<HTMLDivElement>(null);
  const timeRef = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    if (fade && allText.current) {
      gsap.to(allText.current, { autoAlpha: 0, duration: 0.2, delay: delay });
    } else {
      gsap.set(allText.current, { autoAlpha: 1, delay: delay });
    }
  }, [fade, allText.current]);

  useEffect(() => {
    if (timeRef.current) {
      if (!small) {
        gsap.set(timeRef.current, {clearProps: "all"});
        gsap.from(timeRef.current, { height: 0, marginBottom: 0, duration: 1 });
      } else {
        gsap.to(timeRef.current, { height: 0, marginBottom: 0, duration: 1 });
      }
    }
  }, [small]);

  return (
    <div
      style={{ transform: small ? "scale(0.55)" : "scale(1)" }}
      className={`${ENLARGE_TRANSITION} ${!small ? "top-[43px] left-[10px]" : "top-[19.5px] left-[17.5px]"} origin-top-left z-[999] absolute text-white [leading-trim:both] [text-edge:cap] w-[57.69vw]`}
      ref={allText}
    >
      <p
        className="font-bold text-[12px] leading-[19px] tracking-[0.24px] mb-[17px]"
        ref={timeRef}
      >
        {content.time && !small && (
          <Text
            scramb
            startDelay={delay}
          >
            {content.time}
          </Text>
        )}
      </p>
      <h2
        // style={{ transform: small ? "translateY(-60%)" : "translateY(0%)" }}
        className={`${ENLARGE_TRANSITION} origin-top-left font-bold text-[23px] leading-[30px] tracking-[0.46px] mb-[11px]`}
      >
        {content.title && (
          <Text
            scramb
            startDelay={delay}
          >
            {content.title}
          </Text>
        )}
      </h2>
      {content.location && !small && (
        <p className="font-light text-[12px] leading-[19px] tracking-[0.24px] mb-[1px] [-webkit-text-stroke:0.1px_#FFFFFF]">
          <Text startDelay={delay}>{`[地点]：${content.location}`}</Text>
        </p>
      )}
      {content.field && !small && (
        <p className="font-light text-[12px] leading-[19px] tracking-[0.24px] mb-[12px] [-webkit-text-stroke:0.1px_#FFFFFF]">
          <Text startDelay={delay + 0.1}>{`[学科]：${content.field}`}</Text>
        </p>
      )}
      {content.description && !small && (
        <p className="font-light text-[12px] leading-[19px] tracking-[0.24px] [-webkit-text-stroke:0.1px_#FFFFFF]">
          <Text
            startDelay={delay + 0.2}
            staggerTime={0.006}
          >
            {content.description}
          </Text>
        </p>
      )}
    </div>
  );
};

export default PointDetail;
