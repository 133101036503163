import gsap from "gsap";
import {
  Application,
  Assets,
  BitmapText,
  Text as CText,
  Graphics,
  Sprite,
  TextStyle,
} from "pixi.js";
import { MouseEvent, useEffect, useRef, useState } from "react";
import SmoothScrollbar from "smooth-scrollbar";
import SwiperCore from "swiper";
import { EffectFlip } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Progress, Review, Text } from "..";
import dot0 from "../../assets/dot-blue.png";
import dot1 from "../../assets/dot-green.png";
import dot2 from "../../assets/dot-purple.png";
import dot3 from "../../assets/dot-yellow.png";
import iconDownload from "../../assets/icon-download.svg";
import iconSwipe from "../../assets/icon-focus.svg";
import leftIcon from "../../assets/icon-left.svg";
import iconRotate from "../../assets/icon-rotate.svg";
import xIcon from "../../assets/icon-x.svg";
import lockGrayIdle from "../../assets/lock-gray.gif";
import lockGray from "../../assets/lock-gray.svg";
import lockIdle from "../../assets/lock.gif";
import lock from "../../assets/lock.svg";
import mask from "../../assets/poster-mask.svg";
import qr from "../../assets/qr.png";
import { useEvents } from "../../context/Events";
import { useSound } from "../../context/Sound";
import { type RawEvent } from "../../types/event";
import sound from "../audio/data/bgm";
import {
  DESSOLVE_DUR,
  DOTBOUNCE_DUR,
  EXPAND_DUR,
  FADEIN_DUR,
  LAYER_DUR,
  LOCK_DUR,
  RAY_DUR,
  TEXT_DUR,
  WAIT_DUR,
} from "../layer/data/durations";
import {
  MappedHighlightColors,
  mappedHighlightColors,
} from "../treeScene/data/highlights";
import { randomMovement } from "../treeScene/helpers/utils";
import { dotPositions, hookTexts } from "./data/picGen";
import "swiper/css";
import "swiper/css/effect-flip";
import { sample } from "lodash";
import {
  trackExploreVein,
  trackJumpCount,
  trackShareImg,
  trackViewVein,
} from "../../utils/analysis";

const ExpandableDot = ({
  color,
  isLast = false,
  onReset,
  expandCallback,
  closeCallback,
  lockIdleState,
  setLockIdle,
  content,
}: {
  color: string;
  isLast?: boolean;
  onReset?: Function;
  expandCallback?: Function;
  closeCallback: Function;
  lockIdleState: boolean;
  setLockIdle: Function;
  content: RawEvent;
}) => {
  const { muted, setScene } = useSound();
  const { routes, clickHistory } = useEvents();
  const swiperRef = useRef<SwiperCore | null>(null);
  const [dataUrl, setDataUrl] = useState<string>("");
  const [aiProcess, setAiProcess] = useState<boolean>(false);
  const [aiCompleted, setAiCompleted] = useState<boolean>(false);
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [expaned, setExpanded] = useState<boolean>(false);
  const [review, setReview] = useState<boolean>(false);
  const [reviewCompleted, setReviewCompleted] = useState<boolean>(false);

  const hookRef = useRef(sample(hookTexts));
  const reviewPointsRef = useRef<HTMLDivElement[]>([]);
  const dot = useRef<HTMLDivElement>(null);
  const eventDot = useRef<HTMLDivElement>(null);
  const canvas = useRef<HTMLDivElement>(null);
  const scrollContainer = useRef<HTMLDivElement>(null);
  const fadeOutBg = useRef<HTMLDivElement>(null);
  const fadeOutIcon = useRef<HTMLDivElement>(null);
  const lockRef = useRef<HTMLDivElement>(null);
  const bgHaloRef = useRef<HTMLDivElement>(null);
  const dissolveRef = useRef<HTMLDivElement>(null);
  const maskRef = useRef<HTMLImageElement>(null);
  const qrRef = useRef<HTMLImageElement>(null);
  const xRef = useRef<HTMLImageElement>(null);
  const aiRef = useRef<HTMLDivElement>(null);
  const swipeRef = useRef<HTMLDivElement>(null);
  const downloadRef = useRef<HTMLDivElement>(null);

  const mainTimeline = useRef<GSAPTimeline>(gsap.timeline());
  const expandTimeline = useRef<GSAPTimeline>(gsap.timeline());

  const lockSoundRef = useRef<number>(0);

  const exportCanvas = async () => {
    const revertedRoutes = [...routes].reverse();
    const formatTitle = (title: string, cut: number) => {
      let result = "";
      let count = 0;

      for (let i = 0; i < title.length; i++) {
        const char = title[i];
        if (/[a-zA-Z0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(char)) {
          count += 0.6;
        } else {
          count += 1;
        }
        result += char;
        if (count >= cut && i !== title.length - 1) {
          result += "\n";
          count = 0;
        }
      }

      return result;
    };
    const getImage = async (index: number) => {
      switch (index) {
        case 5:
          return (await import("../../assets/poster-bg-5.jpg")).default;
        case 6:
          return (await import("../../assets/poster-bg-6.jpg")).default;
        case 4:
        default:
          return (await import("../../assets/poster-bg-4.jpg")).default;
      }
    };

    const dots = [dot0, dot1, dot2, dot3];

    const colorLookup = (color: string) => {
      //@ts-ignore
      const index = mappedHighlightColors.indexOf(color);
      if (index !== -1) {
        return index; // Returns the position if the color is found
      }
      return null;
    };
    const largeTitleStyle = new TextStyle({
      fontFamily: "TencentSans",
      fontWeight: "bold",
      letterSpacing: 0.87,
      fontSize:
        !content.hook1 && hookRef.current?.fontSize
          ? hookRef.current?.fontSize
          : 43.5,
      fill: "#000000",
      lineHeight:
        !content.hook1 && hookRef.current?.lineHeight
          ? hookRef.current?.lineHeight
          : 63,
    });

    const smallTitleStyle = new TextStyle({
      fontFamily: "TencentSans",
      fontWeight: "bold",
      letterSpacing: 0.87,
      fontSize:
        !content.hook1 && hookRef.current?.subFontSize
          ? hookRef.current?.subFontSize
          : 30,
      fill: "#000000",
      lineHeight: !content.hook1 && hookRef.current?.subFontSize ? 45 : 36,
    });

    if (canvas.current) {
      const app = new Application();
      await app.init({
        width: 939,
        height: 1665,
        backgroundColor: 0xffffff,
        view: document.createElement("canvas"),
      });

      const imageIndex =
        revertedRoutes.length < 4
          ? 4
          : revertedRoutes.length > 6
            ? 6
            : revertedRoutes.length;
      //@ts-ignore
      const currentDotPosition = dotPositions[imageIndex];

      const loadImages = async () => {
        const bg = await getImage(imageIndex);
        const bgTexture = await Assets.load(bg as string);
        const bgSprite = new Sprite(bgTexture);
        bgSprite.x = 0;
        bgSprite.y = 0;
        bgSprite.width = app.renderer.width;
        bgSprite.height = app.renderer.height;
        app.stage.addChild(bgSprite);

        for (const [index, pos] of currentDotPosition.entries()) {
          const currentRoute =
            !revertedRoutes[index] ||
            (revertedRoutes.length > currentDotPosition.length &&
              index === currentDotPosition.length - 1)
              ? revertedRoutes[revertedRoutes.length - 1]
              : revertedRoutes[index];
          const c = colorLookup(currentRoute?.color!);

          //@ts-ignore
          const dotTexture = await Assets.load(dots[c] as string);
          const dotSprite = new Sprite(dotTexture);
          dotSprite.anchor.set(0.5);
          dotSprite.x = pos.x;
          dotSprite.y = pos.y;
          dotSprite.width = 187;
          dotSprite.height = 187;
          app.stage.addChild(dotSprite);

          let text1Height = 0;
          let text2Height = 0;
          let text1 = null;
          let text2 = null;
          if (pos.left && pos.top) {
            if (currentRoute.time) {
              text1 = new CText({
                text: formatTitle(currentRoute.time, index === 0 ? 10 : 11),
                style: {
                  fontFamily: "TencentSans",
                  fontWeight: "bold",
                  letterSpacing: 1.785,
                  fontSize: 25.5,
                  fill: "#FFFFFF",
                  align: "left",
                  lineHeight: 42,
                  // wordWrap: true,
                  // wordWrapWidth: index === 0 ? 280 : 300,
                },
              });
              text1.x = pos.left + 27;
              text1.y = pos.top + 20;
              text1Height = text1.height;
            }
            if (currentRoute.title) {
              text2 = new CText({
                text: formatTitle(currentRoute.title, index === 0 ? 10 : 11),
                style: {
                  fontFamily: "TencentSans",
                  fontWeight: "bold",
                  fontSize: 25.5,
                  fill: "#FFFFFF",
                  align: "left",
                  lineHeight: 39,
                  letterSpacing: 1.785,
                  // wordWrap: true,
                  // wordWrapWidth: index === 0 ? 280 : 300,
                },
              });
              text2.x = pos.left + 27;
              text2.y = currentRoute.time ? pos.top + 60 : pos.top + 20;
              text2Height = text2.height;
            }

            const textBox = new Graphics()
              .roundRect(
                pos.left,
                pos.top,
                index === 0 ? 330 : 360,
                text1Height + text2Height + 38,
                8,
              )
              .fill("#242424");
            app.stage.addChild(textBox);
            text1 && app.stage.addChild(text1);
            text2 && app.stage.addChild(text2);
          }
        }

        const title1 = new CText({
          text: content.hook1 || hookRef.current?.hook1,
          style: largeTitleStyle,
        });
        title1.x = 39;
        title1.y = 1451;
        const text2text =
          content.hook1 && !content.hook2
            ? ""
            : content.hook2 || hookRef.current?.hook2;
        app.stage.addChild(title1);
        let title2 = { width: 0 } as any;
        if (text2text) {
          title2 = new CText({
            text:
              content.hook1 && !content.hook2
                ? ""
                : content.hook2 || hookRef.current?.hook2,
            style: largeTitleStyle,
          });
          title2.x = 39;
          title2.y = 1514;
          app.stage.addChild(title2);
        }
        if (!content.hook1 && !content.hook2 && hookRef.current?.author) {
          const author = new CText({
            text: hookRef.current?.author,
            style: smallTitleStyle,
          });
          author.x = 39 + title2.width + 16;
          author.y = hookRef.current?.subFontSize ? 1536 : 1536;
          app.stage.addChild(author);
        }

        app.renderer.render(app.stage);
        setDataUrl(app.canvas.toDataURL("image/jpeg", 0.9));
      };

      // Load and display images
      return loadImages();
    }
  };

  const handleSlideBackward = () => {
    !muted && sound.play("sfxclick");
    window.getSelection()?.empty();
    window.getSelection()?.removeAllRanges();
    swiperRef.current?.slideTo(0);
  };

  const handleSlideForward = () => {
    if (aiCompleted) {
      !muted && sound.play("sfxclick");
      window.getSelection()?.empty();
      window.getSelection()?.removeAllRanges();
      swiperRef.current?.slideTo(1);
    }
  };

  const onExpend = () => {
    addAnalyze();
    !muted && sound.play("sfxlastclick");
    lockSoundRef.current && sound.stop(lockSoundRef.current);
    expandCallback && expandCallback(true);
    const firstPointPos = reviewPointsRef?.current[0]
      ?.querySelector(".point")
      ?.getBoundingClientRect();
    const x = `${firstPointPos?.x! + firstPointPos?.width! / 2}px` || "41.28%";
    const y = `${firstPointPos?.y! + firstPointPos?.height! / 2}px` || "14.45%";
    expandTimeline.current
      .to(dot.current, {
        duration: EXPAND_DUR,
        clipPath: "circle(1000px at 18.6% 34%)",
      })
      .add(() => {
        onReset && onReset(true);
      }, "-=0.3")
      .to(
        lockRef.current,
        {
          autoAlpha: 0,
          duration: 0.1,
        },
        `-=${EXPAND_DUR}`,
      )
      .to(eventDot.current, {
        duration: 0.24,
        clipPath: `circle(6.5px at ${x} ${y})`,
      })
      .add(() => {
        setReview(true);
        setScene("result");
      })
      .add(() => {
        dissolveRef?.current?.classList.add("animate-dissolve");
        setTimeout(() => {
          dissolveRef?.current?.classList.add("bg-share-end");
          dissolveRef?.current?.classList.remove("animate-dissolve");
          gsap.to(eventDot.current, {
            duration: 0.1,
            scale: 0,
          });
        }, DESSOLVE_DUR * 1000);
      })
      .from(
        bgHaloRef.current,
        {
          autoAlpha: 0,
          duration: 1.56,
        },
        `-=${DESSOLVE_DUR}`,
      )
      .add(() => {
        if (dot.current) {
          const floatings = gsap.utils.toArray(
            dot.current?.querySelectorAll(".floating"),
          );
          gsap.from(floatings, { opacity: 0, duration: 0.6 });
          floatings.forEach((floating) => {
            randomMovement(
              floating as HTMLElement,
              Math.round(Math.random()) ? 1 : -1,
              Math.round(Math.random()) ? 1 : -1,
            );
          });
        }
      }, `-=${DESSOLVE_DUR}`);
    setExpanded(true);
  };

  const addAnalyze = () => {
    trackShareImg();
    trackExploreVein(routes.map((item) => item.title).join(","));
    // let titles = clickHistory.map((item) => item.title).join(",");
    trackJumpCount(clickHistory.length - 1);
  };

  const onAIcomplete = () => {
    gsap.to(aiRef.current, {
      opacity: 0,
      duration: 0.3,
      // deley: WAIT_DUR,
      onComplete: () => {
        swiperRef.current?.slideTo(1);
        setAiCompleted(true);
      },
    });
  };

  // const onShrink = () => {
  //   tl.to(dot.current, {
  //     duration: 0.4,
  //     clipPath: "circle(33px at 18.6% 34%)",
  //   })
  //     .to(
  //       fadeOutBg.current,
  //       {
  //         autoAlpha: 1,
  //         duration: 0.2,
  //       },
  //       "-=0.2",
  //     )
  //     .to(
  //       fadeOutIcon.current,
  //       {
  //         autoAlpha: 1,
  //         duration: 0.2,
  //       },
  //       "-=0.2",
  //     )
  //     .add(() => {
  //       onReset && onReset(false);
  //     }, "-=0.1");
  //   tl.play();
  // };

  const onBack = (event: MouseEvent) => {
    !muted && sound.play("sfxclick");
    event.stopPropagation();
    closeCallback();
  };

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.on("slideChange", (swiper: SwiperCore) => {
        setCurrentSlide(swiper.realIndex);
      });
    }
  }, [swiperRef.current, aiCompleted]);

  useEffect(() => {
    let scrollbar: SmoothScrollbar | null = null;
    if (scrollContainer.current) {
      scrollbar = SmoothScrollbar.init(scrollContainer?.current, {
        alwaysShowTracks: true,
        continuousScrolling: false,
        damping: 0.2,
        delegateTo: scrollContainer?.current,
        renderByPixels: false,
      });
    }
    return () => {
      scrollbar && scrollbar.destroy();
    };
  }, [scrollContainer]);

  useEffect(() => {
    const lastTimeline = gsap.timeline({ delay: 1.5 });
    if (isLast) {
      lastTimeline
        .to(
          fadeOutIcon.current,
          {
            scale: 0,
            duration: DOTBOUNCE_DUR,
          },
          `-=${DOTBOUNCE_DUR}`,
        )
        .to(
          fadeOutIcon.current,
          {
            scale: 0,
            duration: DOTBOUNCE_DUR,
          },
          `-=${DOTBOUNCE_DUR}`,
        )
        .fromTo(
          lockRef.current,
          {
            scale: 0,
          },
          {
            scale: 1.2,
            duration: 0.3,
          },
        )
        .to(lockRef.current, {
          scale: 1,
          duration: LOCK_DUR - 0.3,
        })
        .from(
          lockRef.current,
          {
            rotate: -15,
            x: -3.5,
            y: -3.5,
            duration: LOCK_DUR,
          },
          `-=${LOCK_DUR}`,
        )
        .add(() => {
          lockRef.current?.classList.add("idle");
          setLockIdle(true);
          if (!muted) {
            lockSoundRef.current = sound.play("sfxlock");
          }
        });
    }
    return () => {
      if (dot.current && isLast) {
        const floatings = gsap.utils.toArray(
          dot.current?.querySelectorAll(".floating"),
        );
        floatings.forEach((floating) => {
          gsap.killTweensOf(floating as HTMLElement, "opacity,x,y");
        });
      }
      lastTimeline.kill();
      if (!muted) {
        lockSoundRef.current && sound.stop(lockSoundRef.current);
      }
    };
  }, [isLast]);

  useEffect(() => {
    const tl = gsap.timeline();
    if (reviewCompleted && maskRef.current) {
      tl.to(maskRef.current, {
        duration: TEXT_DUR,
        autoAlpha: 1,
      })
        .fromTo(
          qrRef.current,
          {
            y: "15px",
            opacity: 0,
          },
          {
            y: 0,
            opacity: 1,
          },
          `-=${TEXT_DUR}`,
        )
        .from(
          xRef.current,
          {
            opacity: 0,
            delay: 0.55,
          },
          `-=${TEXT_DUR}`,
        )
        .add(() => {
          setAiProcess(true);
        });
    }
    return () => {
      tl.kill();
    };
  }, [reviewCompleted, maskRef.current]);

  useEffect(() => {
    let exportTimeout: NodeJS.Timeout;
    if (aiProcess) {
      exportTimeout = setTimeout(() => {
        exportCanvas();
      }, 500);
    }
    return () => {
      clearTimeout(exportTimeout);
    };
  }, [aiProcess, routes]);

  useEffect(() => {
    if (aiCompleted) {
      if (currentSlide === 0) {
        gsap.fromTo(
          downloadRef.current,
          {
            autoAlpha: 0,
          },
          {
            autoAlpha: 1,
            duration: 0.3,
          },
        );
        gsap.fromTo(
          swipeRef.current,
          {
            autoAlpha: 1,
          },
          {
            autoAlpha: 0,
            duration: 0.3,
            delay: 0.2,
          },
        );
      } else {
        gsap.fromTo(
          swipeRef.current,
          {
            autoAlpha: 0,
          },
          {
            autoAlpha: 1,
            duration: 0.3,
          },
        );
        gsap.fromTo(
          downloadRef.current,
          {
            autoAlpha: 1,
          },
          {
            autoAlpha: 0,
            duration: 0.3,
            delay: 0.2,
          },
        );
      }
    }
  }, [currentSlide, aiCompleted]);

  useEffect(() => {
    //console.log("re-render");
    mainTimeline.current
      .fromTo(
        dot.current,
        { opacity: 0 },
        {
          opacity: 1,
          duration: 0.2,
        },
      )
      .to(dot.current, {
        duration: DOTBOUNCE_DUR,
        delay: LAYER_DUR + RAY_DUR,
        clipPath: "circle(33px at 18.6% 34%)",
      });
    return () => {
      // gsap.set(dot.current, { opacity: 0 });
      // gsap.set(eventDot.current, { clipPath: "circle(15px at 18.6% 34%)" });
      //expandCallback && expandCallback(false);
      mainTimeline.current.kill();
      setExpanded(false);
    };
  }, []);

  return (
    <div
      className="relative w-full h-full"
      style={{
        backgroundColor: isLast ? "#140D16" : color,
        clipPath: "circle(15px at 18.6% 34%)",
      }}
      onClick={isLast && !expaned && lockIdleState ? onExpend : () => null}
      ref={dot}
    >
      {isLast ? (
        <>
          <a
            className="absolute left-[20.37px] top-[20.37px] flex gap-1.5 font-bold"
            onClick={(event: MouseEvent<HTMLElement>) => onBack(event)}
          >
            <img
              className="pt-1"
              src={leftIcon}
            />
            <span className="text-[13.5px] leading-[18px] tracking-[0.945px] height-[18px]">
              继续探索
            </span>
          </a>
          <div
            className="absolute pointer-events-none w-[279vw] h-[279vw] top-0 right-0 opacity-50 -translate-y-1/2 translate-x-1/2 z-[1] bg-[radial-gradient(50%_50%_at_50%_50%,rgba(80,55,88,1)_0%,rgba(18,13,24,0.00)_100%)] bg-[length:279vw_279vw] bg-[position:center_center] mix-blend-lighten"
            ref={bgHaloRef}
          />
          <div className="floating w-[8px] h-[8px] left-[76.41vw] top-[3.22%] opacity-80 bg-[#5F558A] rounded-full blur-[1px] absolute -translate-x-1/2 -translate-y-1/2"></div>
          <div className="floating w-[16px] h-[16px] left-[0.5vw] bottom-[41.23%] opacity-70 bg-[#5F558A] rounded-full blur-[1px] absolute -translate-x-1/2 translate-y-1/2"></div>
          <div className="floating w-[8px] h-[8px] left-[5.512vw] top-[32.39%] opacity-80 bg-[#5F558A] rounded-full blur-[1px] absolute -translate-x-1/2 -translate-y-1/2"></div>
          <div className="floating w-[16px] h-[16px] right-[1.5vw] top-[40.4%] opacity-80 bg-[#3E305D] rounded-full blur-[1px] absolute translate-x-1/2 translate-y-1/2"></div>
          <div className="floating w-[16px] h-[16px] left-[16.41vw] top-[8.567%] opacity-80 bg-[#3E305D] rounded-full blur-[1px] absolute -translate-x-1/2 -translate-y-1/2"></div>
          <div className="floating w-[8px] h-[8px] right-[17.56vw] bottom-[11.245%] opacity-70 bg-[#3E305D] rounded-full blur-[1px] absolute translate-x-1/2 translate-y-1/2"></div>
          <Swiper
            onSwiper={(swiper: SwiperCore) => {
              swiperRef.current = swiper;
            }}
            effect={"flip"}
            loop={true}
            modules={[EffectFlip]}
            // onSlideChangeTransitionStart={handleSlideChangeTransitionStart}
            // onSlideChangeTransitionEnd={handleSlideChangeTransitionEnd}
            className="w-[313px] h-[555px] top-[47.45%] z-[2000] -translate-y-1/2"
          >
            <img
              className="absolute right-0 bottom-[13.7px] translate-x-[6px] mix-blend-difference z-[55]"
              src={iconRotate}
            />
            <SwiperSlide
              className="w-full h-full"
              onClick={handleSlideForward}
            >
              <img
                src={dataUrl}
                className="absolute opacity-0 top-0 left-0 z-[100]"
              />
              <div className="relative top-0 left-0 w-[939px] h-[1665px] border-[2px] border-white origin-top-left scale-[calc(311/939)]">
                <div
                  className="relative w-full h-full"
                  ref={canvas}
                >
                  <div
                    className="relative top-0 left-0 w-full h-full bg-share bg-left-top bg-[length:1500%_100%] [animation-fill-mode:forwards]"
                    ref={dissolveRef}
                  >
                    <Review
                      start={review}
                      ref={reviewPointsRef}
                      setCompleted={setReviewCompleted}
                    />
                  </div>
                  <div className="absolute bottom-0 w-full h-[156px] z-[3]">
                    <img
                      className="absolute w-full bottom-0 opacity-0"
                      src={mask}
                      ref={maskRef}
                    ></img>
                    <img
                      className="absolute bottom-[226px] left-[33px] w-[138px] h-[138px] opacity-0"
                      src={qr}
                      ref={qrRef}
                    ></img>
                    {reviewCompleted && (
                      <>
                        <p
                          className="absolute bottom-[152px] left-0 text-black font-bold pl-[39px]"
                          style={{
                            fontSize:
                              !content.hook1 && hookRef.current?.fontSize
                                ? hookRef.current?.fontSize + "px"
                                : "43.65px",
                            lineHeight:
                              !content.hook1 && hookRef.current?.lineHeight
                                ? hookRef.current?.lineHeight + "px"
                                : "63px",
                          }}
                        >
                          <Text
                            scramb
                            startDelay={0.15}
                          >
                            {content.hook1 || hookRef.current?.hook1}
                          </Text>
                        </p>
                        <p
                          className="absolute flex items-end justify-start gap-[16px] bottom-[89.7px] left-0 text-black font-bold pl-[39px]"
                          style={{
                            fontSize:
                              !content.hook2 && hookRef.current?.fontSize
                                ? hookRef.current?.fontSize + "px"
                                : "43.65px",
                            lineHeight:
                              !content.hook2 && hookRef.current?.lineHeight
                                ? hookRef.current?.lineHeight + "px"
                                : "63px",
                          }}
                        >
                          <Text
                            scramb
                            startDelay={0.2}
                          >
                            {content.hook1 && !content.hook2
                              ? ""
                              : content.hook2 || hookRef.current?.hook2}
                          </Text>
                          {!content.hook1 &&
                            !content.hook2 &&
                            hookRef.current?.author && (
                              <span
                                className="text-black font-bold"
                                style={{
                                  fontSize:
                                    (hookRef.current?.subFontSize || 30) + "px",
                                  lineHeight:
                                    hookRef.current?.subFontSize + "px"
                                      ? "45px"
                                      : "45px",
                                }}
                              >
                                <Text
                                  scramb
                                  startDelay={0.4}
                                >
                                  {hookRef.current?.author}
                                </Text>
                              </span>
                            )}
                        </p>
                        <div className="absolute bottom-[30px] w-[228px] h-[47px] flex gap-[13px] ml-[39px] text-[25.5px] items-center font-light stroke-[#434343] stroke-[0.2px] text-[#434343]">
                          <Text
                            scramb
                            startDelay={0.5}
                          >
                            科技树
                          </Text>
                          <img
                            src={xIcon}
                            className="w-[20px] h-[20px] mt-[4px]"
                            ref={xRef}
                          />
                          <Text
                            scramb
                            startDelay={0.6}
                          >
                            混元
                          </Text>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide onClick={handleSlideBackward}>
              <div className="relative w-[313px] h-full pt-[50px] pl-[34px] pr-[14px] text-black border-white border-solid border-[1px] bg-white [leading-trim:both] [text-edge:cap]">
                <h2 className="text-[20px] font-bold text-center pb-[40px] leading-[32px] tracking-[0.24px]">
                  AI解读科技因果
                </h2>
                <div
                  className="h-[369px] text-[12px] leading-[19px] overflow-y-scroll [&_.scroll-content]:pr-[19px] [&_.scrollbar-track]:bg-[transparent] [&_.scrollbar-track-y]:w-[3px] [&_.scrollbar-track-y_.scrollbar-thumb]:bg-[#CBCBCB] [&_.scrollbar-thumb-y]:w-[3px] [&_.scrollbar-thumb-y]:rounded-[1.5px]"
                  ref={scrollContainer}
                >
                  <div>
                    <p className="whitespace-pre-line">
                      {content.pathDesc && (
                        <Text
                          fade={false}
                          staggerTime={0.01}
                          start={aiCompleted}
                        >
                          {content.pathDesc.replace(/(^|[^\n])\n([^\n]|$)/g, "$1\n\n$2")}
                        </Text>
                      )}
                    </p>
                  </div>
                </div>
                {aiCompleted && (
                  <div className="absolute left-[0] bottom-[15.58px] w-[228px] h-[10px] flex gap-[4.92px] ml-[15px] text-[9px] items-center font-light stroke-[#434343] stroke-[0.2px] text-[#434343]">
                    <Text scramb>科技树</Text>
                    <img
                      src={xIcon}
                      className="w-[4.92px] h-[4.92px] mt-[2px]"
                    />
                    <Text
                      scramb
                      startDelay={0.2}
                    >
                      混元
                    </Text>
                  </div>
                )}
              </div>
            </SwiperSlide>
          </Swiper>
          {aiProcess && (
            <>
              <div
                className="absolute w-full top-[50%] mt-[265px]"
                ref={aiRef}
              >
                <div className="relative  pt-[20px] text-[13.5px] leading-[130%]">
                  <p className="pb-[22px] text-center">
                    <Text scramb>AI解读中...</Text>
                  </p>
                  <Progress
                    duration={WAIT_DUR}
                    onComplete={onAIcomplete}
                  />
                </div>
              </div>
              <div
                className="absolute w-full top-[50%] mt-[265px] text-[11px] leading-[18px] flex gap-[7px] pt-[20px] pb-[50px] items-center justify-center opacity-0"
                ref={swipeRef}
                onClick={handleSlideBackward}
              >
                <img src={iconSwipe} />
                点击翻面
              </div>
              <div
                className="absolute w-full top-[50%] mt-[265px] text-[11px] leading-[18px] flex gap-[7px] pt-[20px] pb-[50px] items-center justify-center opacity-0"
                ref={downloadRef}
                onClick={handleSlideForward}
              >
                <img src={iconDownload} />
                长按保存分享
              </div>
            </>
          )}
          <div
            className="absolute w-full h-full top-0 left-0 z-[1000]"
            style={{
              clipPath: "circle(1000px at 18.6% 34%)",
            }}
            ref={eventDot}
          >
            <div
              className="absolute w-full h-full top-0 left-0"
              style={{ backgroundColor: color }}
              ref={fadeOutBg}
            ></div>
          </div>
          <div className="absolute z-[1000] left-[18.6%] top-[34%] w-[60px] h-[60px] -translate-x-1/2 -translate-y-1/2">
            <div
              className="absolute flex justify-center items-center w-[60px] h-[60px] left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 group"
              ref={lockRef}
            >
              <img
                className="w-[15.8px] group-[.idle]:hidden"
                src={color === MappedHighlightColors.Yellow ? lockGray : lock}
              />
              <img
                className="lock-idle w-[30.0px] hidden group-[.idle]:block"
                src={
                  color === MappedHighlightColors.Yellow
                    ? lockGrayIdle
                    : lockIdle
                }
              />
            </div>
            <div
              className="absolute bg-white rounded-full w-[8.71px] h-[8.71px] left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
              ref={fadeOutIcon}
            ></div>
          </div>
        </>
      ) : (
        <div className="absolute bg-white rounded-full w-[8.71px] h-[8.71px] left-[18.6%] top-[34%] -translate-x-1/2 -translate-y-1/2"></div>
      )}
    </div>
  );
};

export default ExpandableDot;
