import gsap from "gsap";
import {
  forwardRef,
  SetStateAction,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Text } from "../../components";
import sound from "../../components/audio/data/bgm";
import { useEvents } from "../../context/Events";
import { useSound } from "../../context/Sound";
import { type RawEvent } from "../../types/event";
import { dotPositions } from "../expandable-dot/data/picGen";
import { DESSOLVE_DUR } from "../layer/data/durations";
import { mappedHighlightColors } from "../treeScene/data/highlights";

interface ColorStop {
  offset: number;
  stopColor: string;
  opacity: number;
}

const generateColorStops = (
  numStops: number,
  minSpacing: number,
): ColorStop[] => {
  const color = Math.round(Math.random());
  const stops: ColorStop[] = [];

  let lastOffset = 0;
  for (let i = 0; i < numStops; i++) {
    // Ensure that the new offset has at least `minSpacing` from the last one
    let newOffset =
      Math.random() * (100 - lastOffset - minSpacing) + lastOffset + minSpacing;
    newOffset = Math.min(newOffset, 100); // Ensure the offset is within 100%

    stops.push({
      offset: newOffset,
      stopColor: color ? "#140D16" : "#FFFFFF",
      opacity: color ? 0 : Math.random() * 1,
    });

    lastOffset = newOffset;
  }

  return stops;
};

const ColorStop = ({ stop }: { stop: ColorStop }) => {
  const stopRef = useRef<SVGStopElement>(null);

  return (
    <stop
      offset={`${stop.offset}%`}
      stopColor={stop.stopColor}
      stopOpacity={stop.opacity}
      ref={stopRef}
    >
      {/* <animate
        attributeName="stop-color"
        values="#FFFFFF; #140D16; #FFFFFF"
        dur={`${duration}s`}
        repeatCount="indefinite"
      ></animate> */}
    </stop>
  );
};

const ColorStops = ({ id }: { id: string }) => {
  const linearRef = useRef<SVGLinearGradientElement>(null);
  const numStops = Math.random() * 10;
  const minSpacing = 6;
  const stops = generateColorStops(numStops, minSpacing);

  // useEffect(() => {
  //   gsap.to(linearRef.current, {
  //     attr: { x1: "0%", x2: "0%", y1: "100%", y2: "100%" },
  //     repeat: -1,
  //     duration: 4,
  //     yoyo: true,
  //   });
  // }, []);

  return (
    <linearGradient
      id={id}
      gradientUnits="userSpaceOnUse"
      x1="0%"
      x2="0%"
      y1="100%"
      y2="100%"
      ref={linearRef}
    >
      {stops.map((a, i) => (
        <ColorStop
          key={`cs${i}`}
          stop={a}
        />
      ))}
    </linearGradient>
  );
};

const LinearGradient = ({ id }: { id: string }) => {
  const time = 2 + Math.random() * 3;
  return (
    <linearGradient
      id={id}
      x1="-100%"
      y1="100%"
      x2="800%"
      y2="0"
    >
      <stop
        offset="0"
        stopColor="#FFFFFF"
      >
        <animate
          attributeName="offset"
          values="0;0.2"
          dur={`${time}s`}
          repeatCount="indefinite"
        />
      </stop>
      <stop
        offset="0"
        stopColor="#140D16"
        stopOpacity="0.2"
      >
        <animate
          attributeName="offset"
          values="0;0.3"
          dur={`${time}s`}
          repeatCount="indefinite"
        />
      </stop>
      <stop
        offset="0"
        stopColor="#FFFFFF"
      >
        <animate
          attributeName="offset"
          values="0;0.4"
          dur={`${time}s`}
          repeatCount="indefinite"
        />
      </stop>
      <stop
        offset="0.1"
        stopColor="#140D16"
        stopOpacity="0.2"
      >
        <animate
          attributeName="offset"
          values="0.1;0.8"
          dur={`${time}s`}
          repeatCount="indefinite"
        />
      </stop>
      <stop
        offset="0"
        stopColor="#FFFFFF"
      >
        <animate
          attributeName="offset"
          values="0;1"
          dur={`${time}s`}
          repeatCount="indefinite"
        />
      </stop>
      <stop
        offset="0.1"
        stopColor="#140D16"
        stopOpacity="0.2"
      >
        <animate
          attributeName="offset"
          values="0.1;0.3"
          dur={`${time}s`}
          repeatCount="indefinite"
        />
      </stop>
      <stop
        offset="0.1"
        stopColor="#FFFFFF"
      >
        <animate
          attributeName="offset"
          values="0.1;0.5"
          dur={`${time}s`}
          repeatCount="indefinite"
        />
      </stop>
    </linearGradient>
  );
};

const Review = forwardRef<
  HTMLDivElement[],
  { start: boolean; setCompleted: (value: boolean) => void }
>(({ start, setCompleted }, ref) => {
  const { muted } = useSound();
  const divRefs = useRef<(HTMLDivElement | null)[]>([]);
  const staggerTime = 0.5;
  const { routes } = useEvents();
  const [rs, setRs] = useState<RawEvent[]>(routes);
  const [should1stStart, setShould1stStart] = useState<boolean>(false);
  const [shouldLastStart, setShouldLastStart] = useState<boolean>(false);

  const length: number = rs.length > 6 ? 6 : rs.length < 4 ? 4 : rs.length;
  //@ts-ignore
  const pos = dotPositions[length];

  useImperativeHandle(ref, () => divRefs.current as HTMLDivElement[], [
    divRefs,
  ]);

  useEffect(() => {
    setRs([...routes].reverse());
  }, [routes]);

  useEffect(() => {
    const tl = gsap.timeline({
      delay: DESSOLVE_DUR,
      paused: true,
      onComplete: () => {
        setCompleted(true);
        tl.kill();
      },
    });
    if (start) {
      const reviewPoints = gsap.utils.toArray(divRefs.current);
      tl.from(reviewPoints, {
        y: "2px",
        duration: 0.16,
        stagger: (index, target, list) => {
          const pointTl = gsap.timeline();
          const point = target.querySelector(".point");
          const panel = target.querySelector(".panel");
          const line = target.querySelector(".line");
          pointTl
            .fromTo(
              point,
              {
                scale: 0,
              },
              {
                scale: 1.4,
                duration: 0.2,
                delay: index * staggerTime,
              },
            )
            .to(point, {
              scale: 1,
              duration: 0.1,
            });
          if (!!panel) {
            pointTl.fromTo(
              panel,
              {
                opacity: 0,
              },
              {
                opacity: 1,
                duration: 0.2,
                onComplete: () => {
                  if (index === 0) {
                    setShould1stStart(true);
                  } else {
                    setShouldLastStart(true);
                  }
                },
              },
            );
          }
          if (!!line) {
            pointTl
              .add(() => {
                !muted && sound.play("sfxdash");
              })
              .fromTo(
                line,
                {
                  opacity: 0,
                },
                {
                  opacity: 1,
                  duration: 0.2,
                },
              );
          }
          return index * staggerTime;
        },
      });
      tl.play();
    }
    return () => {
      tl.kill();
    };
  }, [start]);

  return (
    <>
      {pos.map((p: any, i: number) => {
        const r =
          !rs[i] || (rs.length < pos.length && i === rs.length - 1)
            ? rs[rs.length - 1]
            : rs[i];

        return (
          <div
            className="absolute left-0 top-0 w-full h-full z-[4]"
            ref={(el) => (divRefs.current[i] = el)}
            key={`reviewid-${i}`}
          >
            <div
              className="point relative scale-[0] w-[39px] h-[39px] -translate-x-1/2 -translate-y-1/2 rounded-full shadow z-[4]"
              style={{
                background: r.color || mappedHighlightColors[0],
                boxShadow: `0 0 30px 20px ${r.color || mappedHighlightColors[0]}4D`,
                left: p.x + "px",
                top: p.y + "px",
              }}
            ></div>
            {!!p.left && !!p.top && (
              <div
                className="panel absolute opacity-0 bg-[#272727] text-white font-bold text-[25.5px] tracking-widest leading-trim-both rounded-[9px] pt-[10px] pl-[20px] pb-[15px] pr-[18px] z-[33]"
                style={{
                  left: p.left + "px",
                  top: p.top + "px",
                  width: p.width + "px",
                }}
              >
                {r.time && (
                  <p className="leading-[42px]">
                    <Text
                      scramb
                      //startDelay={i === 0 ? 2 : 2 + i * 0.6}
                      start={i === 0 ? should1stStart : shouldLastStart}
                    >
                      {r.time}
                    </Text>
                  </p>
                )}
                <p className="leading-[39px]">
                  <Text
                    scramb
                    //startDelay={i === 0 ? 2 : 2 + i * 0.6}
                    start={i === 0 ? should1stStart : shouldLastStart}
                  >
                    {r.title && r.title}
                  </Text>
                </p>
              </div>
            )}
            {i < pos.length - 1 && (
              <svg
                width={`${Math.abs(pos[i + 1].x - p.x) + 4}`}
                height={`${Math.abs(pos[i + 1].y - p.y) + 4}`}
                className={`line absolute z-[2] opacity-0 drop-shadow-[0_0px_1px_rgba(0,0,0,0.2)] origin-top-left ${pos[i + 1].x - p.x < 0 ? "-scale-x-[1]" : ""} ${pos[i + 1].y - p.y < 0 ? "-scale-y-[1]" : ""}`}
                style={{
                  left: p.x + "px",
                  top: p.y + "px",
                  width: p.width + "px",
                }}
              >
                <defs>
                  <filter id={`shodow${i}`}>
                    <feDropShadow
                      dx="0"
                      dy="0"
                      stdDeviation="2"
                      floodColor="white"
                      floodOpacity="1"
                    />
                  </filter>
                  <LinearGradient id={`grad${i}`} />
                </defs>
                <line
                  x1="1"
                  y1="1"
                  x2={`${Math.abs(pos[i + 1].x - p.x)}`}
                  y2={`${Math.abs(pos[i + 1].y - p.y)}`}
                  strokeWidth="2"
                  filter={`url(#shodow${i})`}
                  stroke={`url(#grad${i})`}
                ></line>
              </svg>
            )}
          </div>
        );
      })}
    </>
  );
});

export default Review;
