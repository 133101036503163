import { createContext, ReactNode, useContext, useState } from "react";

type TimelineContextTypes = {
  introEnded: boolean;
  updateIntroEnded: Function;
  skip: boolean;
  updateSkip: Function;
  isHaloComplete: boolean;
  updateHaloComplete: Function;
};

export const TimelineContext = createContext<TimelineContextTypes>({
  introEnded: false,
  updateIntroEnded: () => {},
  skip: false,
  updateSkip: () => {},
  isHaloComplete: false,
  updateHaloComplete: () => {},
});

export const TimelineProvider = ({ children }: { children: ReactNode }) => {
  const [introEnded, setIntroEnded] = useState(false);
  const [skip, setSkip] = useState(false);
  const [isHaloComplete, setHaloComplete] = useState(false);

  return (
    <TimelineContext.Provider
      value={{
        skip,
        introEnded,
        updateIntroEnded: setIntroEnded,
        updateSkip: setSkip,
        isHaloComplete,
        updateHaloComplete: setHaloComplete,
      }}
    >
      {children}
    </TimelineContext.Provider>
  );
};

export const useTimeline = () => {
  return useContext(TimelineContext);
};
