import { Html } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import { useCallback, useRef, useState } from "react";
import * as THREE from "three";
import { PointSimple } from "..";
import { useEvents } from "../../context/Events";
import { useSound } from "../../context/Sound";
import { useTimeline } from "../../context/Timeline";
import { DisplayEvent } from "../../types/event";
import sound from "../audio/data/bgm";
import {
  HIGHLIGHT_GROUP_SCALE,
  HIGHLIGHT_POSITION_SCALE,
  HIGHLIGHT_SHOW_DELTA,
  HIGHLIGHT_Y_OFFSET,
  PARTICLE_FORMATION_DUR,
  SKIP_DUR,
} from "./data/constants";
import { HighlightLevelShape } from "./data/highlights";

export const Annotation = ({
  data,
  position,
  level,
  fadeInDelay,
  hidden,
  activeLevel,
}: {
  data: DisplayEvent;
  position: { x: number; y: number; z: number };
  level: number;
  fadeInDelay: number;
  hidden: boolean;
  activeLevel: number;
}) => {
  const { introEnded } = useTimeline();
  const { muted } = useSound();
  const { onEventClick } = useEvents();

  const [scaleFactor, setScaleFactor] = useState(0);
  const [opacity, setOpacity] = useState(0);
  const ref = useRef<THREE.Group>(null);

  const camera = useThree((state) => state.camera) as THREE.PerspectiveCamera;

  const calculateScale = useCallback(
    (object: THREE.Group, hidden: boolean): number => {
      if (hidden) {
        return 0;
      }
      const cameraPosition = new THREE.Vector3();
      camera.getWorldPosition(cameraPosition);

      const worldPosition = new THREE.Vector3();
      object.getWorldPosition(worldPosition);

      const cameraPositionNormalized = cameraPosition.clone().normalize();
      // 計算空間中心（始終面向鏡頭的）垂直平面與點的距離
      const dot = cameraPositionNormalized.dot(worldPosition.normalize());
      // 如果在平面背後，不顯示
      if (dot < 0) return 0;

      // 限制最小值為0.7，最大值為1
      // 0.2是人為補正值
      return Math.max(0.7, Math.min(dot + 0.2, 1));
    },
    [],
  );

  useFrame(({ clock }) => {
    const elapsed = clock.getElapsedTime();
    const fadeInDuration = 0.6;
    if (ref.current) {
      if (elapsed > fadeInDelay + fadeInDuration) {
        const maxScale = calculateScale(ref.current, hidden);
        const scale = Math.min(
          maxScale,
          (elapsed - fadeInDelay) / fadeInDuration,
        );
        setScaleFactor(scale);
        setOpacity(Math.ceil(scale));
      } else {
        setOpacity(0);
        setScaleFactor(calculateScale(ref.current, hidden) * 0.95);
      }
    }
  });

  const onClick = (target: DisplayEvent) => {
    const { position } = target;
    const scale = HIGHLIGHT_POSITION_SCALE * HIGHLIGHT_GROUP_SCALE;
    const scaledX = position.x * scale;
    const scaledY = position.y * scale + HIGHLIGHT_Y_OFFSET;
    const scaledZ = position.z * scale;

    onEventClick({
      ...target,
      position: { x: scaledX, y: scaledY, z: scaledZ },
    });
    !muted && sound.play("sfxclick");
    !muted && sound.play("sfxdash");
    !muted && sound.play("sfxexplore");
  };

  if (!data.title) {
    return null;
  }

  return (
    <group
      ref={ref}
      position={new THREE.Vector3(position.x, position.y, position.z)}
    >
      <group position={[0.32, -0.1, 0]}>
        <Html zIndexRange={level !== activeLevel ? [100, 0] : [16777271, 0]}>
          <PointSimple
            data={data}
            style={{
              transform: `scale(${scaleFactor})`,
              // https://cubic-bezier.com/#0,.95,.49,1
              transition: "all 0.5s cubic-bezier(0,.95,.49,1)",
            }}
            // 只顯示在 activeLevel 以及 activeLevel + 1 的層級，就是 ... 只有一層
            opacity={
              level < activeLevel || level > activeLevel + 1 ? 0 : opacity
            }
            level={level}
            activeLevel={activeLevel}
            onClick={() => level === activeLevel && introEnded && onClick(data)}
          />
        </Html>
      </group>
    </group>
  );
};

const Annotations = ({
  highlights,
  hidden,
  activeLevel,
}: {
  highlights: HighlightLevelShape[];
  hidden: boolean;
  activeLevel: number;
}) => {
  const { skip } = useTimeline();

  let itemCount = 0;
  let isLevel0Shown = false;

  const all = highlights.map(({ groups }, levelIndex) => {
    if (levelIndex > 0) {
      isLevel0Shown = true;
    }
    if (levelIndex < activeLevel || levelIndex > activeLevel + 1) {
      return null;
    }
    return groups.map(({ items }, groupIndex) => {
      return items.map((item, itemIndex) => {
        let fadeInDelay = skip ? SKIP_DUR + 0.5 : PARTICLE_FORMATION_DUR - 1;

        // Level 0 出現的間隔為 HIGHLIGHT_SHOW_DELTA
        // 後面的 ... 是 0.01
        const delta = levelIndex === 0 ? HIGHLIGHT_SHOW_DELTA : 0.01;
        fadeInDelay +=
          itemCount * delta +
          1 +
          // 12 個首級出現後再出現 ...
          (isLevel0Shown ? 12 * HIGHLIGHT_SHOW_DELTA : 0);

        itemCount++;

        const scale = HIGHLIGHT_POSITION_SCALE;
        const scaledPosition = {
          x: item.position.x * scale,
          y: item.position.y * scale,
          z: item.position.z * scale,
        };

        return (
          <Annotation
            data={item}
            key={`${levelIndex}-${item.id}`}
            position={scaledPosition}
            level={levelIndex}
            fadeInDelay={fadeInDelay}
            hidden={hidden}
            activeLevel={activeLevel}
          />
        );
      });
    });
  });
  return <group>{all}</group>;
};

export default Annotations;
