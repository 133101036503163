import { DisplayEvent } from "../../types/event";

const PointSimple = ({
  data,
  level,
  opacity,
  style,
  activeLevel,
  onClick,
}: {
  data: DisplayEvent;
  level: number;
  opacity: number;
  style: Object;
  activeLevel: number;
  onClick: () => void;
}) => {
  const { title } = data;
  const sharedClasses =
    "flex bottom-[8px] left-[8px] rounded-[3px] font-bold bg-[#3D2D5C] text-white origin-bottom-left transition duration-300";
  return (
    <div
      className="absolute bottom-0 left-0 font-bold pointer-events-none[leading-trim:both] [text-edge:cap] origin-bottom-left"
      style={{
        ...style,
        opacity,
      }}
    >
      {level === activeLevel ? (
        <div
          className={`absolute bottom-[-10px] left-[-10px] pb-[20px] pl-[20px]`}
          onClick={onClick}
        >
          <div
            className={`${sharedClasses} ${title?.length > 11 ? "w-[154px] pr-[7px]" : "pr-[11px] whitespace-nowrap"} pt-[6px] pb-[8px] pl-[11px]`}
          >
            {title && <h3 className="text-[12px]">{title}</h3>}
          </div>
        </div>
      ) : (
        <div
          className={`${sharedClasses} absolute w-[33px] h-[13px] pt-0 pb-0 pl-[11px] pr-[11px]`}
        >
          <p
            className="text-[12px]"
            style={{ lineHeight: 0.2, letterSpacing: "0.07em" }}
          >
            ...
          </p>
        </div>
      )}
    </div>
  );
};

export default PointSimple;
