import { useEffect, useState } from "react";
import {
  BackgroundHalos,
  Layer,
  Loading,
  TreeScene,
  Ui,
} from "../../components";
import { useEvents } from "../../context/Events";
import { useSound } from "../../context/Sound";
import { useTimeline } from "../../context/Timeline";
import { useTree } from "../../context/Tree";
import { getEventList } from "../../data/api";
import { type RawEvent } from "../../types/event";
import { trackUserSource } from "../../utils/analysis";
import { usePreload } from "../../utils/preload";

export const Index = () => {
  const { setScene } = useSound();
  const { isHaloComplete } = useTimeline();
  const { resetData } = useEvents();
  const { isDetailShow } = useTree();
  const [eventList, setEventList] = useState<RawEvent[][]>([]);

  const [loaded, setLoaded] = useState<boolean>(false);
  const { progress, loading } = usePreload();

  useEffect(() => {
    trackUserSource();
  }, []);
  useEffect(() => {
    (async () => {
      const { data } = await getEventList();
      setEventList(data.data);
    })();

    // setTimeout(async () => {
    //   setLoaded(true);
    // }, 3000);
  }, []);

  useEffect(() => {
    if (!isDetailShow) {
      resetData();
      setScene(isHaloComplete ? "home" : "intro");
    } else {
      setScene("detail");
    }
  }, [isDetailShow, isHaloComplete]);

  if (loading) {
    // if (!loaded) {
    return <Loading progress={progress} />;
  }
  return (
    <>
      <div className="relative w-full h-full">
        <BackgroundHalos />
        <TreeScene eventList={eventList} />
        <div className="absolute bottom-0 w-full h-[31.3vh] z-[3] pointer-events-none bg-[linear-gradient(180deg,rgba(0,0,0,0)_0%,rgba(0,0,0,0.5)_95.81%)]"></div>
        <Ui />
        {isDetailShow && <Layer />}
      </div>
    </>
  );
};

export default TreeScene;
