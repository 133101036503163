import { useRef } from "react";
import { useEvents } from "../../context/Events";
import { useSound } from "../../context/Sound";

const Easter = () => {
  const { easterMatched } = useEvents();
  const layerContainer = useRef<HTMLDivElement>(null);

  if (!easterMatched) {
    return null;
  }

  return (
    <>
      {easterMatched && (
        <div
          className="absolute w-full h-screen top-0 left-0 z-[999] opacity-1 bg-[rgba(20,13,22,0.7)]"
          ref={layerContainer}
        >
          <div
            className={`absolute w-full h-full top-0 left-0 z-[999] bg-${easterMatched.animation}`}
          />
        </div>
      )}
    </>
  );
};

export default Easter;
