import axios from "axios";

export const api = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      // ? "https://stree.stinkstudiosworks.cn/tencent-tree/"
      ?  "https://tencenttree.com/tencent-tree/"
      : window.location.protocol + "//" + window.location.host + "/tencent-tree/",
});

export const getEventList = async () => api.get("/api/tree/list");

//ids: id1,id2,id3
export const getEventDetail = async (ids: string) =>
  api.get("/api/tree/list/by/eventId", { params: { eventId: ids } });
