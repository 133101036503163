import { useEffect, useRef, useState } from "react";
import { useEvents } from "../../context/Events";
import gsap from "gsap";
import { useTimeline } from "../../context/Timeline";
import { INITIAL_STEP } from "../treeScene/data/constants";

const BackgroundHalos = () => {
  const { skip, updateHaloComplete } = useTimeline();

  const [complete, setComplete] = useState<boolean>(false);

  const bgHaloRef = useRef<HTMLDivElement>(null);
  const bgHalo1 = useRef<HTMLDivElement>(null);
  const bgHalo2 = useRef<HTMLDivElement>(null);
  const bgHalo3 = useRef<HTMLDivElement>(null);
  const globalTimeline = useRef<GSAPTimeline>(gsap.timeline());
  const completeTimeline = useRef<GSAPTimeline>(gsap.timeline());

  useEffect(() => {
    if (skip && !complete && bgHaloRef.current) {
      // Show bgHalo on skip
      // skip 的逻辑有bug todo
      globalTimeline.current.kill();
      const timeline = gsap.timeline({
        onComplete: () => {
          timeline.kill();
        },
      });
      timeline.to(bgHaloRef.current, {
        opacity: 0.7,
        duration: 0.36,
        delay: 1.2,
        onComplete: () => {
          setComplete(true);
        },
      });
    }
  }, [complete, skip, bgHaloRef.current]);

  useEffect(() => {
    if (bgHaloRef.current) {
      // Show bgHalo on start
      globalTimeline.current
        .fromTo(
          bgHaloRef.current,
          { opacity: 0 },
          { opacity: 0.35, duration: 1.5 },
        )
        .to(bgHaloRef.current, { opacity: 0.7, duration: 0.36 }, "+=9")
        .add(() => {
          setComplete(true);
        });
    }
    return () => {
      globalTimeline.current && globalTimeline.current.kill();
    };
  }, []);

  useEffect(() => {
    if (complete) {
      // show other halos
      completeTimeline.current
        .add(() => {
          const tl1 = gsap
            .timeline({
              onComplete: () => {
                tl1.kill();
              },
            })
            .fromTo(
              bgHalo1.current,
              { opacity: 0, scale: 0.8 },
              { opacity: 0.2, scale: 1, duration: 1, delay: 0.08 },
            )
            .to(bgHalo1.current, { x: -15, duration: 4.68 }, "-=1")
            .to(bgHalo1.current, { opacity: 0.1, duration: 1.64 }, "-=1.48");
          const tl2 = gsap
            .timeline({
              onComplete: () => {
                tl2.kill();
              },
            })
            .fromTo(
              bgHalo2.current,
              { opacity: 0, scale: 0.6 },
              { opacity: 0.2, scale: 1, duration: 2, delay: 0.28 },
            )
            .to(bgHalo2.current, { x: -8, y: -15, duration: 4.28 }, "-=1")
            .to(bgHalo2.current, { opacity: 0.1, duration: 4.28 }, "-=0.8");
          const tl3 = gsap
            .timeline({
              onComplete: () => {
                tl3.kill();
              },
            })
            .fromTo(
              bgHalo3.current,
              { opacity: 0, scale: 0.75 },
              { opacity: 0.2, scale: 1, duration: 2, delay: 0.28 },
            )
            .to(bgHalo3.current, { x: 5, y: -7, duration: 4.28 }, "-=1")
            .to(bgHalo3.current, { opacity: 0.1, duration: 4.28 }, "-=0.8");
        })
        .to(bgHaloRef.current, { opacity: 0.45, duration: 0.36 })
        .fromTo(
          bgHalo2.current,
          { opacity: 0, scale: 0.7 },
          { opacity: 0.2, scale: 1, duration: 0.3 },
          "-=0.1",
        )
        .add(() => {
          // All halos are complete
          updateHaloComplete(true);
        });
    }
    return () => {
      completeTimeline.current && completeTimeline.current.kill();
    };
  }, [complete]);

  return (
    <>
      <div
        className="absolute pointer-events-none w-full h-full top-[0] left-[0] z-[1] bg-[radial-gradient(50%_50%_at_50%_50%,rgba(80,55,88,1)_0%,rgba(18,13,24,0.00)_100%)] bg-[length:145.78vw_145.78vw] bg-[position:center_43.975%] mix-blend-lighten"
        ref={bgHaloRef}
      />
      <div
        className="absolute pointer-events-none w-[44.1vw] h-[44.1vw] rounded-full bottom-[67.469%] -right-[1.47vw] z-[1] bg-[#2E2532] opacity-0 mix-blend-lighten"
        ref={bgHalo1}
      />
      <div
        className="absolute pointer-events-none w-[37.91vw] h-[37.91vw] rounded-full top-[46.452%] left-[3.346vw] z-[1] bg-[#2E2532] opacity-0 mix-blend-lighten"
        ref={bgHalo2}
      />
      <div
        className="absolute pointer-events-none w-[19.71vw] h-[19.71vw] rounded-full top-[60.374%] left-[1.74vw] z-[1] bg-[#2E2532] opacity-0 mix-blend-lighten"
        ref={bgHalo3}
      />
    </>
  );
};

export default BackgroundHalos;
