import { useTexture } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import gsap from "gsap";
import { useEffect, useMemo, useRef, useState } from "react";
import * as THREE from "three";
import { useEvents } from "../../context/Events";
import { useTimeline } from "../../context/Timeline";
import Annotations from "./Annotation";
import {
  HIGHLIGHT_POSITION_SCALE,
  HIGHLIGHT_SCALE,
  HIGHLIGHT_SCALE_UP_DUR,
  HIGHLIGHT_SHOW_DELTA,
  INITIAL_STEP,
  PARTICLE_FORMATION_DUR,
  SKIP_DUR,
} from "./data/constants";
import { HighlightColors, HighlightLevelShape } from "./data/highlights";
import {
  highlightGlowMaterial,
  highlightSphereMaterial,
} from "./helpers/shaders";
import { matrixToScreen } from "./helpers/utils";

type RefConfig = {
  name: string;
  ref: React.RefObject<THREE.InstancedMesh>;
  material: THREE.ShaderMaterial;
  count: number;
  geometry: THREE.BufferGeometry;
};

const sphereGeometry = new THREE.SphereGeometry();
const glowGeometry = new THREE.PlaneGeometry();

const Highlights = ({
  highlights,
  activeLevel,
}: {
  highlights: HighlightLevelShape[];
  activeLevel: number;
}) => {
  const [levelOpacities, setLevelOpacities] = useState({
    0: 1,
    1: 1,
    2: 1,
    3: 1,
  });
  const [showAnnotations, setShowAnnotations] = useState(true);
  const { step } = useEvents();
  const { skip } = useTimeline();

  // Level 0
  const lv0BlueRef = useRef<THREE.InstancedMesh>(null);
  const lv0BlueGlowRef = useRef<THREE.InstancedMesh>(null);

  const lv0GreenRef = useRef<THREE.InstancedMesh>(null);
  const lv0GreenGlowRef = useRef<THREE.InstancedMesh>(null);

  const lv0PurpleRef = useRef<THREE.InstancedMesh>(null);
  const lv0PurpleGlowRef = useRef<THREE.InstancedMesh>(null);

  const lv0YellowRef = useRef<THREE.InstancedMesh>(null);
  const lv0YellowGlowRef = useRef<THREE.InstancedMesh>(null);

  // level 1
  const lv1BlueRef = useRef<THREE.InstancedMesh>(null);
  const lv1BlueGlowRef = useRef<THREE.InstancedMesh>(null);

  const lv1GreenRef = useRef<THREE.InstancedMesh>(null);
  const lv1GreenGlowRef = useRef<THREE.InstancedMesh>(null);

  const lv1PurpleRef = useRef<THREE.InstancedMesh>(null);
  const lv1PurpleGlowRef = useRef<THREE.InstancedMesh>(null);

  const lv1YellowRef = useRef<THREE.InstancedMesh>(null);
  const lv1YellowGlowRef = useRef<THREE.InstancedMesh>(null);

  // level 2
  const lv2BlueRef = useRef<THREE.InstancedMesh>(null);
  const lv2BlueGlowRef = useRef<THREE.InstancedMesh>(null);

  const lv2GreenRef = useRef<THREE.InstancedMesh>(null);
  const lv2GreenGlowRef = useRef<THREE.InstancedMesh>(null);

  const lv2PurpleRef = useRef<THREE.InstancedMesh>(null);
  const lv2PurpleGlowRef = useRef<THREE.InstancedMesh>(null);

  const lv2YellowRef = useRef<THREE.InstancedMesh>(null);
  const lv2YellowGlowRef = useRef<THREE.InstancedMesh>(null);

  // level 3
  const lv3BlueRef = useRef<THREE.InstancedMesh>(null);
  const lv3BlueGlowRef = useRef<THREE.InstancedMesh>(null);

  const lv3GreenRef = useRef<THREE.InstancedMesh>(null);
  const lv3GreenGlowRef = useRef<THREE.InstancedMesh>(null);

  const lv3PurpleRef = useRef<THREE.InstancedMesh>(null);
  const lv3PurpleGlowRef = useRef<THREE.InstancedMesh>(null);

  const lv3YellowRef = useRef<THREE.InstancedMesh>(null);
  const lv3YellowGlowRef = useRef<THREE.InstancedMesh>(null);

  const lv0SphereMaterialBlue = useRef<THREE.ShaderMaterial>(
    highlightSphereMaterial(HighlightColors.Blue, 0),
  );
  const lv1SphereMaterialBlue = useRef<THREE.ShaderMaterial>(
    highlightSphereMaterial(HighlightColors.Blue, 1),
  );
  const lv2SphereMaterialBlue = useRef<THREE.ShaderMaterial>(
    highlightSphereMaterial(HighlightColors.Blue, 2),
  );
  const lv3SphereMaterialBlue = useRef<THREE.ShaderMaterial>(
    highlightSphereMaterial(HighlightColors.Blue, 3),
  );

  const lv0SphereMaterialGreen = useRef<THREE.ShaderMaterial>(
    highlightSphereMaterial(HighlightColors.Green, 0),
  );
  const lv1SphereMaterialGreen = useRef<THREE.ShaderMaterial>(
    highlightSphereMaterial(HighlightColors.Green, 1),
  );
  const lv2SphereMaterialGreen = useRef<THREE.ShaderMaterial>(
    highlightSphereMaterial(HighlightColors.Green, 2),
  );
  const lv3SphereMaterialGreen = useRef<THREE.ShaderMaterial>(
    highlightSphereMaterial(HighlightColors.Green, 3),
  );

  const lv0SphereMaterialPurple = useRef<THREE.ShaderMaterial>(
    highlightSphereMaterial(HighlightColors.Purple, 0),
  );
  const lv1SphereMaterialPurple = useRef<THREE.ShaderMaterial>(
    highlightSphereMaterial(HighlightColors.Purple, 1),
  );
  const lv2SphereMaterialPurple = useRef<THREE.ShaderMaterial>(
    highlightSphereMaterial(HighlightColors.Purple, 2),
  );
  const lv3SphereMaterialPurple = useRef<THREE.ShaderMaterial>(
    highlightSphereMaterial(HighlightColors.Purple, 3),
  );

  const lv0SphereMaterialYellow = useRef<THREE.ShaderMaterial>(
    highlightSphereMaterial(HighlightColors.Yellow, 0),
  );
  const lv1SphereMaterialYellow = useRef<THREE.ShaderMaterial>(
    highlightSphereMaterial(HighlightColors.Yellow, 1),
  );
  const lv2SphereMaterialYellow = useRef<THREE.ShaderMaterial>(
    highlightSphereMaterial(HighlightColors.Yellow, 2),
  );
  const lv3SphereMaterialYellow = useRef<THREE.ShaderMaterial>(
    highlightSphereMaterial(HighlightColors.Yellow, 3),
  );

  const sphereMaterials = [
    [
      lv0SphereMaterialBlue,
      lv0SphereMaterialGreen,
      lv0SphereMaterialPurple,
      lv0SphereMaterialYellow,
    ],
    [
      lv1SphereMaterialBlue,
      lv1SphereMaterialGreen,
      lv1SphereMaterialPurple,
      lv1SphereMaterialYellow,
    ],
    [
      lv2SphereMaterialBlue,
      lv2SphereMaterialGreen,
      lv2SphereMaterialPurple,
      lv2SphereMaterialYellow,
    ],
    [
      lv3SphereMaterialBlue,
      lv3SphereMaterialGreen,
      lv3SphereMaterialPurple,
      lv3SphereMaterialYellow,
    ],
  ];

  const textureBlue = useTexture("/textures/highlight-bloom-blue.png");
  const textureGreen = useTexture("/textures/highlight-bloom-green.png");
  const texturePurple = useTexture("/textures/highlight-bloom-purple.png");
  const textureYellow = useTexture("/textures/highlight-bloom-yellow.png");

  const lv0GlowMaterialBlue = useRef<THREE.ShaderMaterial>(
    highlightGlowMaterial(textureBlue, 0),
  );
  const lv1GlowMaterialBlue = useRef<THREE.ShaderMaterial>(
    highlightGlowMaterial(textureBlue, 1),
  );
  const lv2GlowMaterialBlue = useRef<THREE.ShaderMaterial>(
    highlightGlowMaterial(textureBlue, 2),
  );
  const lv3GlowMaterialBlue = useRef<THREE.ShaderMaterial>(
    highlightGlowMaterial(textureBlue, 3),
  );

  const lv0GlowMaterialGreen = useRef<THREE.ShaderMaterial>(
    highlightGlowMaterial(textureGreen, 0),
  );
  const lv1GlowMaterialGreen = useRef<THREE.ShaderMaterial>(
    highlightGlowMaterial(textureGreen, 1),
  );
  const lv2GlowMaterialGreen = useRef<THREE.ShaderMaterial>(
    highlightGlowMaterial(textureGreen, 2),
  );
  const lv3GlowMaterialGreen = useRef<THREE.ShaderMaterial>(
    highlightGlowMaterial(textureGreen, 3),
  );

  const lv0GlowMaterialPurple = useRef<THREE.ShaderMaterial>(
    highlightGlowMaterial(texturePurple, 0),
  );
  const lv1GlowMaterialPurple = useRef<THREE.ShaderMaterial>(
    highlightGlowMaterial(texturePurple, 1),
  );
  const lv2GlowMaterialPurple = useRef<THREE.ShaderMaterial>(
    highlightGlowMaterial(texturePurple, 2),
  );
  const lv3GlowMaterialPurple = useRef<THREE.ShaderMaterial>(
    highlightGlowMaterial(texturePurple, 3),
  );

  const lv0GlowMaterialYellow = useRef<THREE.ShaderMaterial>(
    highlightGlowMaterial(textureYellow, 0),
  );
  const lv1GlowMaterialYellow = useRef<THREE.ShaderMaterial>(
    highlightGlowMaterial(textureYellow, 1),
  );
  const lv2GlowMaterialYellow = useRef<THREE.ShaderMaterial>(
    highlightGlowMaterial(textureYellow, 2),
  );
  const lv3GlowMaterialYellow = useRef<THREE.ShaderMaterial>(
    highlightGlowMaterial(textureYellow, 3),
  );

  const glowMaterials = [
    [
      lv0GlowMaterialBlue,
      lv0GlowMaterialGreen,
      lv0GlowMaterialPurple,
      lv0GlowMaterialYellow,
    ],
    [
      lv1GlowMaterialBlue,
      lv1GlowMaterialGreen,
      lv1GlowMaterialPurple,
      lv1GlowMaterialYellow,
    ],
    [
      lv2GlowMaterialBlue,
      lv2GlowMaterialGreen,
      lv2GlowMaterialPurple,
      lv2GlowMaterialYellow,
    ],
    [
      lv3GlowMaterialBlue,
      lv3GlowMaterialGreen,
      lv3GlowMaterialPurple,
      lv3GlowMaterialYellow,
    ],
  ];

  const camera = useThree().camera as THREE.PerspectiveCamera;

  const allRefs: RefConfig[][][] = useMemo(() => {
    const refs = [
      [
        [lv0BlueRef, lv0BlueGlowRef],
        [lv0GreenRef, lv0GreenGlowRef],
        [lv0PurpleRef, lv0PurpleGlowRef],
        [lv0YellowRef, lv0YellowGlowRef],
      ],
      [
        [lv1BlueRef, lv1BlueGlowRef],
        [lv1GreenRef, lv1GreenGlowRef],
        [lv1PurpleRef, lv1PurpleGlowRef],
        [lv1YellowRef, lv1YellowGlowRef],
      ],
      [
        [lv2BlueRef, lv2BlueGlowRef],
        [lv2GreenRef, lv2GreenGlowRef],
        [lv2PurpleRef, lv2PurpleGlowRef],
        [lv2YellowRef, lv2YellowGlowRef],
      ],
      [
        [lv3BlueRef, lv3BlueGlowRef],
        [lv3GreenRef, lv3GreenGlowRef],
        [lv3PurpleRef, lv3PurpleGlowRef],
        [lv3YellowRef, lv3YellowGlowRef],
      ],
    ];

    return highlights.map(({ groups }, levelIndex) => {
      return groups.map(({ items }, groupIndex) => {
        return [
          {
            name: "sphere",
            ref: refs[levelIndex][groupIndex][0], // Sphere
            material: sphereMaterials[levelIndex][groupIndex].current,
            count: items.length,
            geometry: sphereGeometry,
          },
          {
            name: "glow",
            ref: refs[levelIndex][groupIndex][1], // Glow
            material: glowMaterials[levelIndex][groupIndex].current,
            count: items.length,
            geometry: glowGeometry,
          },
        ];
      });
    });
  }, []);

  useEffect(() => {
    let totalIndex = 0;
    let isLevel0Shown = false;
    allRefs.forEach((levelRefs, levelIndex) => {
      if (levelIndex > 0) {
        isLevel0Shown = true;
      }
      levelRefs.forEach((configs, colorIndex) => {
        const [sphere, glow] = configs;
        const { items } = highlights[levelIndex].groups[colorIndex];

        const initParticles = (config: RefConfig) => {
          const { ref } = config;
          if (ref.current) {
            items.forEach(({ id, position }, particleIndex) => {
              const matrix = new THREE.Matrix4();
              matrix.setPosition(
                position.x * HIGHLIGHT_POSITION_SCALE,
                position.y * HIGHLIGHT_POSITION_SCALE,
                position.z * HIGHLIGHT_POSITION_SCALE,
              );
              ref.current?.setMatrixAt(particleIndex, matrix);

              // Add custom data to each instance
              if (!ref.current!.userData.instances) {
                ref.current!.userData.instances = {};
              }

              const startDelay = 1;
              const startTime = skip ? SKIP_DUR : PARTICLE_FORMATION_DUR - 1;

              // Level 0 出現的間隔為 HIGHLIGHT_SHOW_DELTA
              // 後面的 ... 是 0.01
              const delta = levelIndex === 0 ? HIGHLIGHT_SHOW_DELTA : 0.01;
              let scaleDelay = totalIndex * delta + startTime + startDelay;
              if (isLevel0Shown) {
                scaleDelay += 12 * HIGHLIGHT_SHOW_DELTA;
              }
              ref.current!.userData.instances[particleIndex] = {
                id,
                scaleDelay,
              };
              totalIndex++;
            });
            ref.current.instanceMatrix.needsUpdate = true;
          }
        };

        initParticles(sphere);
        initParticles(glow);
      });
    });
  }, [skip]);

  useFrame(({ clock }) => {
    const elapsed = clock.getElapsedTime();

    allRefs.forEach((levelRefs, levelIndex) => {
      levelRefs.forEach((configs, colorIndex) => {
        const [sphere, glow] = configs;
        const { items } = highlights[levelIndex].groups[colorIndex];

        const updateParticles = (config: RefConfig) => {
          const { ref, name, material } = config;
          if (ref.current && ref.current.userData.instances) {
            items.forEach(({ size, position }, particleIndex) => {
              const { instances } = ref.current!.userData;
              const { scaleDelay, id } = instances[particleIndex];

              let scaleProgress = 0;
              if (elapsed > scaleDelay) {
                scaleProgress = Math.min(
                  (elapsed - scaleDelay) / HIGHLIGHT_SCALE_UP_DUR,
                  1,
                );
              }

              const matrix = new THREE.Matrix4();
              const scale =
                size *
                HIGHLIGHT_SCALE *
                scaleProgress *
                (name === "glow" ? 4 : 1);
              matrix.makeScale(scale, scale, scale);
              matrix.setPosition(
                position.x * HIGHLIGHT_POSITION_SCALE,
                position.y * HIGHLIGHT_POSITION_SCALE,
                position.z * HIGHLIGHT_POSITION_SCALE,
              );

              if (name === "glow") {
                ref.current!.setMatrixAt(
                  particleIndex,
                  matrixToScreen(matrix, camera),
                );
              } else {
                ref.current?.setMatrixAt(particleIndex, matrix);
              }
            });
            material.uniforms.uPlaneNormal.value.copy(new THREE.Vector3()); // Update plane normal uniform
            material.uniforms.uCameraPosition.value.copy(camera.position);
            // @ts-ignore
            material.uniforms.uMaxOpacity.value = levelOpacities[levelIndex];

            ref.current.instanceMatrix.needsUpdate = true;
          }
        };
        updateParticles(sphere);
        updateParticles(glow);
      });
    });
  });

  const updateOpacity = (activeLevelValue: number) => {
    Object.entries(levelOpacities).forEach(([level, opacity]) => {
      gsap.to(
        { value: opacity },
        {
          value: Number(activeLevelValue <= Number(level)),
          duration: 1,
          onUpdate: function () {
            setLevelOpacities((prev) => ({
              ...prev,
              [level]: this.targets()[0].value,
            }));
          },
        },
      );
    });
  };

  useEffect(() => {
    if (step === INITIAL_STEP) {
      updateOpacity(activeLevel);
    }
  }, [activeLevel]);

  useEffect(() => {
    if (step > INITIAL_STEP) {
      updateOpacity(100);
      setShowAnnotations(false);
    } else {
      updateOpacity(activeLevel);
      setTimeout(() => {
        setShowAnnotations(true);
      }, 200);
    }
  }, [step]);

  return (
    <group>
      <group>
        {allRefs.map((levelRefs, levelIndex) => {
          return levelRefs.map((configs, colorIndex) => {
            return configs.map(({ name, ref, material, count }) => {
              const isActive = activeLevel === levelIndex;
              material.depthWrite = name === "sphere" && isActive;
              const geometry =
                name === "sphere" ? <sphereGeometry /> : <planeGeometry />;
              return (
                <instancedMesh
                  key={`${levelIndex}-${colorIndex}-${name}`}
                  ref={ref}
                  args={[undefined, material, count]}
                  frustumCulled={false}
                >
                  {geometry}
                </instancedMesh>
              );
            });
          });
        })}
      </group>
      {showAnnotations && (
        <Annotations
          highlights={highlights}
          hidden={step > INITIAL_STEP}
          activeLevel={activeLevel}
        />
      )}
    </group>
  );
};

export default Highlights;
