import { random } from "lodash";

// export const POSITIONS = [
//   { x: 229, y: 54 },
//   { x: 433, y: 129 },
//   { x: -71, y: -93 },
//   { x: -216, y: 123 },
// ];

// const offsets = [
//   { x: 156, y: 202 },
//   { x: 357, y: 128 },
//   { x: -164, y: 325 },
//   { x: -290, y: 137 },
// ];

const offsets = [
  { x: 149, y: 193 },
  { x: 305, y: 171 },
  { x: 225, y: 85 },
  { x: 45, y: 300 },
];

export const randomOffsets = () =>
  offsets.map((offset) => ({
    x: random(offset.x - 5, offset.x + 5, false),
    y: random(offset.y - 5, offset.y + 5, false),
    z: -50,
  }));

// export const BASE_POSITION = {
//   x: window.innerWidth * 0.186,
//   y: window.innerHeight * 0.34,
// };
