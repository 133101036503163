import { Canvas } from "@react-three/fiber";
import { useEvents } from "../../context/Events";
import { type RawEvent } from "../../types/event";
import { Interaction } from "./Interaction";
import { AdditionalBackgroundParticles, Particles } from "./Particles";
import { Squares } from "./Squares";

const TreeScene = ({ eventList }: { eventList: RawEvent[][] }) => {
  const { step } = useEvents();
  return (
    <Canvas
      style={{ width: window.innerWidth, height: window.innerHeight }}
      className="relative z-[2]"
      camera={{ fov: 50, near: 0.1, far: 8, position: [0, 0, 8] }}
    >
      {step > -1 && <AdditionalBackgroundParticles />}
      <Particles />
      {/* <Squares /> */}
      <Interaction eventList={eventList} />
    </Canvas>
  );
};

export default TreeScene;
