export const SCALE_DUR = 2;
export const ANCHOR_Y_DUR = 6.5;
export const PARTICLE_FORMATION_DUR = ANCHOR_Y_DUR + 4; //10.5
export const PARTICLE_Y_OFFSET = -1;
export const SKIP_DUR = 2.5;
export const HIGHLIGHT_GROUP_SCALE = 0.002;
export const HIGHLIGHT_Y_OFFSET = -0.7;
export const HIGHLIGHT_SCALE_UP_DUR = 0.5;
export const HIGHLIGHT_SCALE = 1.5;
export const HIGHLIGHT_SHOW_DELTA = 0.1;
export const EMISSIVE_INTENSITY = 1;
export const HIGHLIGHT_ANIMATION_END_AT =
  HIGHLIGHT_SCALE_UP_DUR + PARTICLE_FORMATION_DUR + 1.1; //11.6
export const HIGHLIGHT_POSITION_SCALE = 4.5;
export const INIT_CAMERA_DISTANCE = 5.43;
export const CAMERA_DISTANCE_LEVELS = [INIT_CAMERA_DISTANCE, 4, 3, 2];
export const INITIAL_STEP = -1;
export const NEXT_STEP_DOT_DELAY = 3000; // ms
