import { createContext, ReactNode, useContext, useState } from "react";
import * as THREE from "three";

type TreeContextTypes = {
  shouldResetCamera: boolean;
  updateShouldResetCamera: Function;
  isDetailShow: boolean;
  toggleDetail: Function;
  boundingBox: THREE.Box3 | null;
  updateBoundingBox: (box: THREE.Box3 | null) => void;
};

export const TreeContext = createContext<TreeContextTypes>({
  shouldResetCamera: false,
  updateShouldResetCamera: () => {},
  isDetailShow: false,
  toggleDetail: () => {},
  boundingBox: null,
  updateBoundingBox: () => {},
});

export const TreeProvider = ({ children }: { children: ReactNode }) => {
  const [shouldResetCamera, setShouldResetCamera] = useState(false);
  const [isDetailShow, setIsDetailShow] = useState(false);
  const [boundingBox, setBoundingBox] = useState<THREE.Box3 | null>(null);

  return (
    <TreeContext.Provider
      value={{
        shouldResetCamera,
        updateShouldResetCamera: setShouldResetCamera,
        isDetailShow,
        toggleDetail: setIsDetailShow,
        boundingBox,
        updateBoundingBox: setBoundingBox,
      }}
    >
      {children}
    </TreeContext.Provider>
  );
};

export const useTree = () => {
  return useContext(TreeContext);
};
