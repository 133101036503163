import { CameraControls } from "@react-three/drei";
import { useThree } from "@react-three/fiber";
import gsap from "gsap";
import { CustomEase } from "gsap/CustomEase";
import { useEffect, useRef, useState } from "react";
import * as THREE from "three";
import { useEvents } from "../../context/Events";
import { useSound } from "../../context/Sound";
import { useTimeline } from "../../context/Timeline";
import { useTree } from "../../context/Tree";
import sound from "../audio/data/bgm";
import {
  HIGHLIGHT_GROUP_SCALE,
  HIGHLIGHT_SCALE,
  NEXT_STEP_DOT_DELAY,
} from "./data/constants";
import { checkpoints } from "./data/introCamCheckpoints";

const endLength = 2;
const NORMAL_SMOOTH_TIME = 0.3;

const CameraCtrl = () => {
  const { muted } = useSound();
  const { skip, updateIntroEnded } = useTimeline();
  const {
    shouldResetCamera,
    updateShouldResetCamera,
    isDetailShow,
    boundingBox,
  } = useTree();
  const { step, targetEvent, updateShouldShowNextEvent, updateIsLast } =
    useEvents();

  const [introStarted, setIntroStarted] = useState<boolean>(false);
  const [isFocusing, setIsFocusing] = useState<boolean>(false);
  const cameraControlsRef = useRef<any>();
  const timeline = useRef<GSAPTimeline>(gsap.timeline());

  // const scene = useThree().scene;

  const setBoundary = (boundary?: THREE.Box3) => {
    if (!boundary) {
      const box = new THREE.Box3(
        new THREE.Vector3(-1.0, -0.3, -1.0),
        new THREE.Vector3(1.0, 1.2, 1.0),
      );
      // const helper = new THREE.Box3Helper(box, 0xffff00);
      // scene.add(helper);
      return cameraControlsRef.current.setBoundary(box);
    }
    cameraControlsRef.current.setBoundary(boundary);

    // cameraControlsRef.current.setBoundary(
    //   boundary
    //     ? new THREE.Box3(
    //         new THREE.Vector3(-6.0, -6, -6.0),
    //         new THREE.Vector3(6.0, 6, 6.0),
    //       )
    //     : new THREE.Box3(
    //         new THREE.Vector3(-1.0, -0.5, -1.0),
    //         new THREE.Vector3(1.0, 0.5, 1.0),
    //       ),
    // );
  };

  const setOrbitPointToCenter = () => {
    cameraControlsRef.current.setOrbitPoint(0, 0, 0);
  };

  const onIntroEnd = () => {
    cameraControlsRef.current.saveState();
    updateIntroEnded(true);
  };

  const onControlEnd = () => {
    // cameraControlsRef.current.setOrbitPoint(0, 0, 0);
    !muted && !isDetailShow && sound.play("sfxdash");
    !isDetailShow && cameraControlsRef.current.saveState();

    // console.log(
    //   "Camera control ended",
    //   cameraControlsRef.current.getPosition(),
    //   cameraControlsRef.current.getTarget(),
    // );
  };

  useEffect(() => {
    if (shouldResetCamera) {
      cameraControlsRef.current.smoothTime = NORMAL_SMOOTH_TIME;
      const finalCheckpoint = checkpoints[checkpoints.length - 1];
      const { position, target } = finalCheckpoint;

      cameraControlsRef.current.setLookAt(
        position[0],
        position[1],
        position[2],
        target[0],
        target[1],
        target[2],
        true,
      );
      updateShouldResetCamera(false);
    }
  }, [shouldResetCamera]);

  const onCameraMove = ({
    step,
    duration,
    ease,
    isSkipping = false,
    onComplete,
  }: {
    step: number;
    duration?: number;
    ease?: string;
    isSkipping?: boolean;
    onComplete: Function;
  }) => {
    cameraControlsRef.current.saveState();
    const prevCp = checkpoints[step - 1];
    const nextCp = checkpoints[step];
    const curCp = isSkipping
      ? cameraControlsRef.current.getPosition()
      : { x: prevCp.position[0], y: prevCp.position[1], z: prevCp.position[2] };
    const curt = isSkipping
      ? cameraControlsRef.current.getTarget()
      : { x: prevCp.target[0], y: prevCp.target[1], z: prevCp.target[2] };
    const isEnd = checkpoints[step].isEnd;
    const isLast = step === checkpoints.length - 1;
    //cameraControlsRef.current.enable = false;
    const { position: endPos, target: endTarget } = nextCp;
    const customEase = ease ? ease : nextCp.ease;

    const pos = { x: curCp.x, y: curCp.y, z: curCp.z };
    const tgt = { x: curt.x, y: curt.y, z: curt.z };
    duration = duration || nextCp.duration;

    if (isEnd || isSkipping) {
      const progress = timeline.current.progress();
      timeline.current.progress(progress).clear();
    }

    timeline.current.to(pos, {
      x: endPos[0],
      y: endPos[1],
      z: endPos[2],
      duration: duration, // Control the duration of the transition
      ease: customEase,
      onUpdate: () => {
        cameraControlsRef.current.setLookAt(
          pos.x,
          pos.y,
          pos.z,
          tgt.x,
          tgt.y,
          tgt.z,
          false, // No internal interpolation
        );
      },
    });

    timeline.current.to(
      tgt,
      {
        x: endTarget[0],
        y: endTarget[1],
        z: endTarget[2],
        duration: duration, // Control the duration of the transition
        ease: customEase,
        onUpdate: () => {
          cameraControlsRef.current.setLookAt(
            pos.x,
            pos.y,
            pos.z,
            tgt.x,
            tgt.y,
            tgt.z,
            false,
          );
          cameraControlsRef.current.saveState();
        },
        onComplete: () => {
          //cameraControlsRef.current.enable = true;
          onComplete && onComplete();
          isLast && timeline.current.kill();
          step === 4 && onIntroEnd();
        },
      },
      "-=" + duration,
    );

    timeline.current.play();
  };

  const moveToEnd = (
    duration?: number,
    ease?: string,
    isSkipping?: boolean,
  ) => {
    for (let i = endLength; i <= checkpoints.length - 1; i++) {
      const isEnd = checkpoints[i].isEnd;
      const options: any = {
        step: i,
        duration: isEnd && duration,
        ease: ease,
        isSkipping: isEnd ? isSkipping : false,
      };
      onCameraMove(options);
    }
  };

  useEffect(() => {
    if (cameraControlsRef.current) {
      if (!introStarted) {
        // Set initial position and target
        const { position, target } = checkpoints[0];
        cameraControlsRef.current.setLookAt(
          position[0],
          position[1],
          position[2],
          target[0],
          target[1],
          target[2],
          false,
        );
      }

      if (skip) {
        requestAnimationFrame(() => {
          moveToEnd(0.5, "power3.inOut", true);
        });
      } else {
        for (let i = 1; i <= endLength; i++) {
          if (checkpoints[i].isEnd) return;
          onCameraMove({
            step: i,
            onComplete: () => {
              checkpoints[i + 1].isEnd && moveToEnd();
            },
          });
        }
      }
      setIntroStarted(true);
    }
  }, [skip]);

  const fitToRect = (
    plane: THREE.Mesh,
    width: number,
    height: number,
    pathDesc: string,
  ) => {
    const _centerPosition = new THREE.Vector3();
    const _normal = new THREE.Vector3();
    const _cameraPosition = new THREE.Vector3();

    plane.updateMatrixWorld();
    const rectCenterPosition = _centerPosition.copy(plane.position);
    const rectNormal = _normal.set(0, 0, 1).applyQuaternion(plane.quaternion);
    const distance = cameraControlsRef.current.getDistanceToFitBox(
      width,
      height,
      0,
    );

    const cameraPosition = _cameraPosition
      .copy(rectNormal)
      .multiplyScalar(distance)
      .add(rectCenterPosition);

    cameraControlsRef.current.setLookAt(
      cameraPosition.x,
      cameraPosition.y,
      cameraPosition.z,
      rectCenterPosition.x,
      rectCenterPosition.y,
      rectCenterPosition.z,
      true,
    );

    setTimeout(() => {
      setIsFocusing(false);
      updateShouldShowNextEvent(true);
    }, NEXT_STEP_DOT_DELAY);
    setTimeout(() => {
      if (pathDesc.length > 0) {
        updateIsLast(true);
      }
    }, 500);
  };

  const zoomToTarget = (
    position: { x: number; y: number; z: number },
    size: number,
    pathDesc: string,
  ) => {
    setIsFocusing(true);
    const targetSize = size * HIGHLIGHT_SCALE * HIGHLIGHT_GROUP_SCALE * 2;

    const { x, y, z } = position;

    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const planeWidth = targetSize / (66 / 390);
    const planeHeight = planeWidth * (windowHeight / windowWidth);
    const planeXOffset = (planeWidth * 122) / 390;
    const planeYOffset = (planeHeight * -135) / 844;

    const planeGeometry = new THREE.PlaneGeometry(planeWidth, planeHeight);
    const planeMaterial = new THREE.MeshBasicMaterial({
      color: 0x000000,
      side: THREE.DoubleSide,
    });
    const plane = new THREE.Mesh(planeGeometry, planeMaterial);

    const cameraQuaternion = cameraControlsRef.current.camera.quaternion;
    plane.position.set(x, y, z);
    plane.quaternion.copy(cameraQuaternion);
    plane.translateX(planeXOffset);
    plane.translateY(planeYOffset);

    fitToRect(plane, planeWidth, planeWidth, pathDesc);
  };

  useEffect(() => {
    cameraControlsRef.current.smoothTime = NORMAL_SMOOTH_TIME;
    if (targetEvent) {
      zoomToTarget(
        targetEvent.position,
        targetEvent.size,
        targetEvent.pathDesc ?? "",
      );
    }
  }, [targetEvent]);

  useEffect(() => {
    if (isDetailShow) {
      if (boundingBox) {
        setBoundary(boundingBox);
      }
      cameraControlsRef.current.truckSpeed = 6;
    } else {
      setBoundary();
      cameraControlsRef.current.reset(true);
      cameraControlsRef.current.truckSpeed = 2;
    }
  }, [isDetailShow, boundingBox]);

  useEffect(() => {
    gsap.registerPlugin(CustomEase);

    if (cameraControlsRef.current) {
      // setBoundary() 是限制二指移動的範圍
      setBoundary();

      // setOrbitPointToCenter() 是設定旋轉中心始終在[0, 0, 0]
      // 需要在每次平移後調用，否則旋轉中心會跟著平移，見 line 48
      // setOrbitPointToCenter();

      // ** 二者似乎不能共用，互相衝突，有一個會失效，boundary 會比較容易失效
    }
  }, []);

  const gestures = {
    NONE: 0,
    ROTATE: 1,
    TRUCK: 2,
    OFFSET: 4,
    DOLLY: 8,
    ZOOM: 16,
    TOUCH_ROTATE: 32,
    TOUCH_TRUCK: 64,
    TOUCH_OFFSET: 128,
    TOUCH_DOLLY: 256,
    TOUCH_ZOOM: 512,
    TOUCH_DOLLY_TRUCK: 1024,
    TOUCH_DOLLY_OFFSET: 2048,
    TOUCH_DOLLY_ROTATE: 4096,
    TOUCH_ZOOM_TRUCK: 8192,
    TOUCH_ZOOM_OFFSET: 16384,
    TOUCH_ZOOM_ROTATE: 32768,
  };

  const { NONE, TOUCH_TRUCK, TOUCH_DOLLY_TRUCK, TOUCH_ROTATE } = gestures;

  return (
    <>
      <CameraControls
        ref={cameraControlsRef}
        minPolarAngle={(Math.PI / 2) * 0.9}
        maxPolarAngle={Math.PI * 0.55}
        minDistance={1}
        maxDistance={6}
        touches={{
          //@ts-ignore
          one: isFocusing ? NONE : isDetailShow ? TOUCH_TRUCK : TOUCH_ROTATE,
          //@ts-ignore
          two: isFocusing
            ? NONE
            : isDetailShow
              ? TOUCH_TRUCK
              : TOUCH_DOLLY_TRUCK,
          //@ts-ignore
          three: NONE,
        }}
        onEnd={onControlEnd}
        smoothTime={2.6}
      />
    </>
  );
};

export default CameraCtrl;
