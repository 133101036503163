import { Html } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import { sample, shuffle } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import * as THREE from "three";
import { Direction, useEvents } from "../../context/Events";
import { useSound } from "../../context/Sound";
import { useTree } from "../../context/Tree";
import { DisplayEvent, RawEvent } from "../../types/event";
import { trackViewVein } from "../../utils/analysis";
import sound from "../audio/data/bgm";
import Dot from "../dot";
import { randomOffsets } from "../layer/data/positions";
import { ENLARGE_TRANSITION } from "../layer/data/transitions";
import { mappedHighlightColors } from "./data/highlights";
import { Line } from "./Line";

export const EventDetails = ({ activeLevel }: { activeLevel: number }) => {
  const {
    step,
    routes,
    targetEvent,
    onShowEaster,
    onEventClick,
    updateShouldShowNextEvent,
    shouldShowNextEvents,
    clickHistory,
    direction,
    updateTargetEvent,
    isLast,
  } = useEvents();

  const { muted } = useSound();

  const { updateBoundingBox } = useTree();

  const otherColors = [...mappedHighlightColors].filter(
    (color) => color !== routes[step - 1]?.color,
  );
  const [nextContent, setNextContent] = useState<RawEvent[]>([]);
  const [activeEvents, setActiveEvents] = useState<DisplayEvent[][]>([
    [
      {
        ...routes[step - 1],
        color: targetEvent!.color,
        position: targetEvent!.position,
        size: targetEvent!.size,
      },
    ],
  ]);

  const eventsRef = useRef(new Map());

  const handleDotClick = (event: DisplayEvent) => {
    if (clickHistory.find((item) => item.id === event.id)) {
      onShowEaster(event);
      return;
    }
    if (clickHistory.length == 1) {
      //点击了脉络的第二个 完成了一次关系链接
      trackViewVein();
    }
    onEventClick(event);
    !muted && sound.play("sfxclick");
    !muted && sound.play("sfxdash");
    !muted && sound.play("sfxexplore");
  };

  useEffect(() => {
    if (step > 0 && routes[step - 1]) {
      setNextContent(routes[step - 1].child);
    }
  }, [step, routes]);

  const camera = useThree().camera as THREE.PerspectiveCamera;
  const canvas = useThree().gl.domElement;
  const pixelRatio = window.devicePixelRatio || 1;

  const calculateChildrenPosition = useCallback(
    (
      prevPosition: { x: number; y: number; z: number },
      offset: { x: number; y: number; z: number },
    ) => {
      const vec3 = new THREE.Vector3(
        prevPosition.x,
        prevPosition.y,
        prevPosition.z,
      );
      const forward = vec3.clone().sub(camera.position).normalize();

      const distance = 0.5;
      const pushedAwayPosition = vec3
        .clone()
        .add(forward.multiplyScalar(distance));

      const position = pushedAwayPosition.clone().project(camera);
      const mitigation = 0.9;

      position.x += ((offset.x * mitigation) / (canvas.width / pixelRatio)) * 2;
      position.y +=
        ((offset.y * mitigation) / (canvas.height / pixelRatio)) * 2;

      position.unproject(camera);
      return position;
    },
    [camera, canvas.width, canvas.height, pixelRatio],
  );

  useEffect(() => {
    if (
      nextContent?.length > 0 &&
      shouldShowNextEvents &&
      direction === Direction.FORWARD
    ) {
      const childEvents: DisplayEvent[] = [];
      const offsets = shuffle(randomOffsets());
      nextContent.forEach((content: RawEvent, index: number) => {
        const targetPosition = targetEvent!.position;
        childEvents.push({
          ...content,
          color: sample(otherColors)!,
          position: calculateChildrenPosition(targetPosition, offsets[index]),
          size: targetEvent!.size,
        });
      });
      setActiveEvents((prevEvents) => [...prevEvents, childEvents]);
      setNextContent([]);
      updateShouldShowNextEvent(false);
    }
  }, [nextContent, shouldShowNextEvents]);

  useEffect(() => {
    if (direction === Direction.BACKWARD) {
      const lastEvent = activeEvents[activeEvents.length - 1][0];

      if (
        (lastEvent.pathDesc && lastEvent.pathDesc === "") ||
        activeEvents.length - 2 === clickHistory.length
      ) {
        setActiveEvents((prevEvents) => {
          return prevEvents.slice(0, prevEvents.length - 1);
        });
      }
      updateTargetEvent(clickHistory[clickHistory.length - 1]);
    }
  }, [direction, clickHistory]);

  // console.log({
  //   nextContent,
  //   activeEvents,
  //   step,
  //   routes,
  //   targetEvent,
  //   shouldShowNextEvents,
  //   clickHistory,
  //   isLast,
  // });

  const levelScale = [0.05, 0.029, 0.028, 0.018];
  const scale = levelScale[activeLevel];

  const lines: any = [];
  const displayEvents: any = [];

  useFrame(() => {
    eventsRef.current.forEach((item) => {
      if (item) {
        item.quaternion.copy(camera.quaternion);
      }
    });
  });

  // const scene = useThree().scene;
  useEffect(() => {
    if (activeEvents.length > 1) {
      const lastTwoLevels = activeEvents.slice(-2);
      const allPositions = lastTwoLevels.flatMap((levelEvents) =>
        levelEvents.map(
          (event) =>
            new THREE.Vector3(
              event.position.x,
              event.position.y,
              event.position.z,
            ),
        ),
      );
      const boundingBox = new THREE.Box3().setFromPoints(allPositions);

      boundingBox.expandByScalar(
        boundingBox.getSize(new THREE.Vector3()).length() / 2.5,
      );
      boundingBox.translate(new THREE.Vector3(0, 0.08, 0));

      updateBoundingBox(boundingBox);
    }
  }, [activeEvents]);

  activeEvents.forEach((stepEvents, stepIndex) => {
    stepEvents.forEach((event, index) => {
      const shouldHide =
        stepIndex < step - 1 ||
        (stepIndex === step - 1 &&
          event.id !== clickHistory[clickHistory.length - 2]?.id &&
          event.id !== clickHistory[clickHistory.length - 1]?.id);

      if (isLast && event.id !== clickHistory[clickHistory.length - 1]?.id) {
        return null;
      }
      const { x, y, z } = event.position;

      const dot = (
        <group
          position={[x, y, z]}
          scale={[scale, scale, scale]}
          ref={(el) => {
            eventsRef.current.set(event.id, el);
          }}
          key={event.id}
        >
          <Html>
            <div
              className={`dot-container absolute ${ENLARGE_TRANSITION} origin-top-left`}
              id={event.id}
              onClick={() => handleDotClick(event)}
              style={{ opacity: shouldHide ? 0 : 1 }}
            >
              <Dot
                color={event.color!}
                small={event.id !== targetEvent?.id}
                content={event}
              />
            </div>
          </Html>
        </group>
      );

      displayEvents.push(dot);

      if (stepIndex > 0) {
        const previousEvent = clickHistory[stepIndex - 1];
        if (!previousEvent || shouldHide) {
          lines.push(null);
          return;
        }

        lines.push(
          <Line
            event={event}
            previousEvent={previousEvent}
            key={`line-${event.id}`}
            bold={!!clickHistory.find((item) => item.id === event.id)}
          />,
        );
      }
    });
  });

  return (
    <>
      {lines}
      {displayEvents}
    </>
  );
};
