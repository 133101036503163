const searchParams = new URLSearchParams(
  decodeURIComponent(window.location.search),
);
const utm_source = searchParams.get("utm_source") || "directInput";
const utm_medium = searchParams.get("utm_medium");
const platform = () => {
  if (utm_source === "app" && utm_medium == null) {
    return "元宝小程序";
  } else if (utm_source == "mini") {
    return '"小程序"';
  } else {
    return "H5";
  }
};
// console.log(utm_source, utm_medium, platform());
//查看脉络
export const trackViewVein = () => {
  if (window._czc) {
    window._czc.push(["_trackEvent", "脉络", "查看", platform()]);
  }
};

//查看关系跳数
export const trackJumpCount = (count: Number) => {
  if (window._czc) {
    window._czc.push(["_trackEvent", "关系跳数", "计数", platform(), count]);
  }
};

//探索条数
export const trackExploreVein = (titles: string) => {
  if (window._czc) {
    window._czc.push(["_trackEvent", "脉络探索", platform(), titles]);
  }
};

//分享图生成次数/人数
export const trackShareImg = () => {
  if (window._czc) {
    window._czc.push(["_trackEvent", "分享图", "生成", platform()]);
  }
};

//只玩一次的次数/人数
export const trackJumpOnce = () => {
  if (window._czc) {
    window._czc.push(["_trackEvent", "查看", "一次", platform()]);
  }
};

//用户来源上报
export const trackUserSource = () => {
  if (window._czc) {
    if (utm_medium) {
      window._czc.push([
        "_trackEvent",
        "用户来源",
        "上报",
        utm_source + "_" + utm_medium,
      ]);
    } else {
      window._czc.push(["_trackEvent", "用户来源", "上报", utm_source]);
    }
  }
};
