import { Fragment, useEffect, useRef, useState } from "react";

const Steps = ({ step = 0 }: { step: number }) => {
  const [stepArray, setStepArray] = useState(new Array(step - 1).fill(1));
  const [lastStep, setLastStep] = useState<number>(step - 1);
  const prevStepCount = useRef(stepArray.length);

  useEffect(() => {
    step > 1 && setStepArray(new Array(step - 1).fill(1));
  }, [step]);

  useEffect(() => {
    if (stepArray.length > prevStepCount.current) {
      setLastStep(stepArray.length);
      prevStepCount.current = stepArray.length;
    }
  }, [stepArray, prevStepCount]);

  return (
    <div className="absolute right-[20px] bottom-[58px] flex flex-col-reverse items-center gap-[1.5px] z-[101]">
      <div className="relative w-[6px] h-[6px] rounded-full bg-[#fff] bottom-0"></div>
      {stepArray.length > 0 &&
        stepArray.map((_, index) => (
          <Fragment key={index}>
            <div
              className={`relative w-[0.8px] h-[20px] bg-[#fff] rounded-[0.4px] ${
                lastStep === index
                  ? "opacity-100"
                  : "opacity-100 animate-fade-in"
              }`}
            ></div>
            <div
              className={`relative w-[6px] h-[6px] rounded-full bg-[#fff] ${
                lastStep === index
                  ? "opacity-100"
                  : "opacity-100 animate-fade-in"
              }`}
            ></div>
          </Fragment>
        ))}
    </div>
  );
};

export default Steps;
