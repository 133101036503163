import gsap from "gsap";
import { useEffect, useState } from "react";

type Percentage = {
  val: number;
};

const Progress = ({
  startDelay = 0,
  duration = 1,
  onComplete,
}: {
  startDelay?: number;
  duration?: number;
  onComplete?: () => void;
}) => {
  const [percentage, setPercentage] = useState<number>(0);

  useEffect(() => {
    const per: Percentage = { val: percentage };
    const timeline = gsap.timeline({
      onUpdate: () => {
        setPercentage(+Math.round(per.val));
      },
    });
    timeline.to(per, {
      val: 100,
      duration: duration,
      delay: startDelay,
      ease: "none",
      onComplete: () => onComplete && onComplete(),
    });
  }, []);

  return (
    <div className="relative w-[106px] left-1/2 -translate-x-1/2">
      <div
        className="flex w-full h-[6px] bg-[#262626] rounded-full overflow-hidden"
        role="progressbar"
      >
        <div
          className="flex flex-col justify-center rounded-full overflow-hidden bg-[#fff] text-xs text-white text-center whitespace-nowrap transition duration-500"
          style={{ width: percentage + "%" }}
        ></div>
      </div>
    </div>
  );
};

export default Progress;
