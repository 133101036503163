import { useFrame } from "@react-three/fiber";
import { useEffect, useRef, useState } from "react";
import * as THREE from "three";
import { DisplayEvent } from "../../types/event";
import { animatedGradientTubeMaterial } from "./helpers/shaders";

const DASHED_THICKNESS = 0.0008;
const BOLD_THICKNESS = 0.0008;
const GROW_SPEED = 0.02;
const SHRINK_SPEED = 0.02;

export const Line = ({
  event,
  previousEvent,
  bold,
}: {
  event: DisplayEvent;
  previousEvent: DisplayEvent;
  bold: boolean;
}) => {
  const pointsRef = useRef<THREE.Vector3[]>([
    new THREE.Vector3(
      previousEvent.position.x,
      previousEvent.position.y,
      previousEvent.position.z,
    ),
    new THREE.Vector3(event.position.x, event.position.y, event.position.z),
  ]);
  const [dashedGeometry, setDashedGeometry] =
    useState<THREE.TubeGeometry | null>(null);
  const [dashedProgress, setDashedProgress] = useState<number>(0);
  const [solidGeometry, setSolidGeometry] = useState<THREE.TubeGeometry | null>(
    null,
  );
  const [solidProgress, setSolidProgress] = useState<number>(0);
  const [boldDirection, setBoldDirection] = useState<number | undefined>(
    undefined,
  );

  const dashedMaterialRef = useRef<THREE.ShaderMaterial>(
    animatedGradientTubeMaterial,
  );

  useFrame(({ clock }) => {
    const elapsed = clock.getElapsedTime();

    const update = () => {
      if (dashedProgress < 1) {
        setDashedProgress((prev) => Math.min(prev + GROW_SPEED, 1));
        const currentEndPoint = new THREE.Vector3(
          pointsRef.current[0].x +
            (pointsRef.current[1].x - pointsRef.current[0].x) * dashedProgress,
          pointsRef.current[0].y +
            (pointsRef.current[1].y - pointsRef.current[0].y) * dashedProgress,
          pointsRef.current[0].z +
            (pointsRef.current[1].z - pointsRef.current[0].z) * dashedProgress,
        );
        const newTubeGeometry = new THREE.TubeGeometry(
          new THREE.CatmullRomCurve3([pointsRef.current[0], currentEndPoint]),
          1,
          DASHED_THICKNESS,
          5,
          false,
        );
        setDashedGeometry(newTubeGeometry);
      }

      if (solidProgress < 1 && boldDirection === 1) {
        setSolidProgress((prev) => Math.min(prev + GROW_SPEED, 1));
        const currentEndPoint = new THREE.Vector3(
          pointsRef.current[0].x +
            (pointsRef.current[1].x - pointsRef.current[0].x) * solidProgress,
          pointsRef.current[0].y +
            (pointsRef.current[1].y - pointsRef.current[0].y) * solidProgress,
          pointsRef.current[0].z +
            (pointsRef.current[1].z - pointsRef.current[0].z) * solidProgress,
        );
        const newTubeGeometry = new THREE.TubeGeometry(
          new THREE.CatmullRomCurve3([pointsRef.current[0], currentEndPoint]),
          1,
          BOLD_THICKNESS,
          5,
          false,
        );
        setSolidGeometry(newTubeGeometry);
      }

      if (boldDirection === -1 && solidProgress >= 0) {
        setSolidProgress((prev) => Math.min(prev - SHRINK_SPEED, 1));
        const currentEndPoint = new THREE.Vector3(
          pointsRef.current[0].x +
            (pointsRef.current[1].x - pointsRef.current[0].x) * solidProgress,
          pointsRef.current[0].y +
            (pointsRef.current[1].y - pointsRef.current[0].y) * solidProgress,
          pointsRef.current[0].z +
            (pointsRef.current[1].z - pointsRef.current[0].z) * solidProgress,
        );
        const newTubeGeometry = new THREE.TubeGeometry(
          new THREE.CatmullRomCurve3([pointsRef.current[0], currentEndPoint]),
          1,
          BOLD_THICKNESS,
          5,
          false,
        );
        setSolidGeometry(newTubeGeometry);
      }

      dashedMaterialRef.current.uniforms.uTime.value = elapsed;
    };
    update();
  });

  useEffect(() => {
    return () => {
      if (dashedGeometry) {
        dashedGeometry.dispose();
      }
    };
  }, [dashedGeometry]);

  useEffect(() => {
    return () => {
      if (solidGeometry) {
        solidGeometry.dispose();
      }
    };
  }, [solidGeometry]);

  useEffect(() => {
    if (bold) {
      setBoldDirection(1);
    } else {
      setBoldDirection(-1);
    }
  }, [bold]);

  return (
    <group>
      <mesh
        frustumCulled={false}
        geometry={dashedGeometry ?? undefined}
        material={dashedMaterialRef.current}
      />
      {boldDirection && (
        <mesh
          frustumCulled={false}
          geometry={solidGeometry ?? undefined}
        >
          <meshBasicMaterial color="white" />
        </mesh>
      )}
    </group>
  );
};
